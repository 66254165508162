import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    canBoVC,
    tienThuongCoBan,
    tienThuongXuatXac,
    tongThuongCuoiNam,
    nam,
    thuongDBCaNhan,
    thuongDBNhom,
    ghiChu,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [canBoVC.name]: Yup.string().required(canBoVC.errorMsg),
    // [tienThuongCoBan.name]: Yup.string().required(tienThuongCoBan.errorMsg),
    [thuongDBCaNhan.name]: Yup.string().required(thuongDBCaNhan.errorMsg),
    [thuongDBNhom.name]: Yup.string().required(thuongDBNhom.errorMsg),
    // [nam.name]: Yup.string().required(nam.errorMsg),
  }),
];

export default validations;
