/* eslint-disable react/prop-types */
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import avatar from "assets/images/team-2.jpg";
// Data
import DataTable from "../ky-luat/data/index";
import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import ActionCell from "../ky-luat/components/ActionCell";
import { formatDate } from "utils";
// import { BookingService } from "services/bookingService";
import SoftBadge from "components/SoftBadge";
import dataTableData from "../ky-luat/data/dataTableData";
import FirstColumn from "examples/FirstColumn";
import { DiscipLineService } from "services/disciplineServices";

function QuanLyKyLuat() {
  const [dataTable, setDataTable] = useState({
    columns: [
      {
        Header: "Mức vi phạm",
        accessor: "muc-vi-pham",
        width: "40%",
        // sorted: true,
        Cell: ({ value }) => (
          <Box display="flex" gap={1} alignItems="center">
            {/* <FirstColumn value={value} /> */}
            {value}
          </Box>
        ),
      },
      {
        Header: "lần 1",
        accessor: "lan-1",
        width: "10%",
        // sorted: true,
        align: "right",
      },
      {
        Header: "lần 2",
        accessor: "lan-2",
        width: "10%",
        // sorted: true,
        align: "right",
      },
      {
        Header: "lần 3",
        accessor: "lan-3",
        width: "10%",
        // sorted: true,
        align: "right",
      },
      {
        Header: "lần 4",
        accessor: "lan-4",
        width: "10%",
        // sorted: true,
        align: "right",
      },

      { Header: "thao tác", width: "20%", accessor: "action", sorted: false, align: "center" },
    ],
    rows: [],
  });

  const [query, setQuery] = useState({ sortBy: "createdAt", sortType: "desc" });
  const [paginationData, setPaginationData] = useState({ page: 1 });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const {
        data: { items, paginate },
      } = await DiscipLineService.getAllLv({
        ...query,
        limit: paginationData.size || 10,
        page: paginationData.page,
      });
      setPaginationData((prev) => ({ ...prev, count: paginate.count, size: paginate.size }));
      setDataTable((prev) => ({
        ...prev,
        rows: items.map((item) => ({
          id: item._id,
          "muc-vi-pham": item.name || "--",
          "lan-1": new Intl.NumberFormat().format(Number(item.violation_1).toFixed(2)) || "--",
          "lan-2": new Intl.NumberFormat().format(Number(item.violation_2).toFixed(2)) || "--",
          "lan-3": new Intl.NumberFormat().format(Number(item.violation_3).toFixed(2)) || "--",
          "lan-4": new Intl.NumberFormat().format(Number(item.violation_4).toFixed(2)) || "--",
          action: <ActionCell item={item} setDataTable={setDataTable} />,
        })),
      }));
      setLoading(false);
    })();
  }, [query, paginationData.page]);

  // const getColorStatus = (status) => {
  //   switch (status) {
  //     case "Đã huỷ":
  //       return "error";
  //     case "Chưa điều phối":
  //       return "secondary";
  //     case "Đã điều phối":
  //       return "info";
  //     case "Đã hoàn thành":
  //       return "success";
  //   }
  //   // "primary",
  //   // "secondary",
  //   // "info",
  //   // "success",
  //   // "warning",
  //   // "error",
  //   // "light",
  //   // "dark",
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <DataTable
            loading={loading}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            query={query}
            setQuery={setQuery}
            table={dataTable}
            canSearch
          />
          {Object.keys(dataTable.rows)?.length === 0 && !loading && (
            <SoftBox my={7}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                Không có dữ liệu
              </Grid>
            </SoftBox>
          )}

          {Object.keys(dataTable.rows)?.length <= 2 && <SoftBox my={5}></SoftBox>}
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

export default QuanLyKyLuat;
