/* eslint-disable react/prop-types */
import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { Grid } from "@mui/material";
import FileAttachment from "components/AttachmentFile";
import { useParams } from "react-router-dom";
import FormField from "../FormField";
import { formatCurrency } from "utils";

function ChiTietDiemCoBanCuoiNam({ formData }) {
  const { getData } = formData;
  const { id } = useParams();

  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");
  const containsCreate = currentPath.includes("create");

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          Chi tiết
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Họ và tên"
              name="name"
              value={getData?.user?.personalInformation?.fullName || ""}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Năm"
              name="year"
              value={getData?.year || ""}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type="text"
              label="Điểm cơ bản"
              name="basicScore"
              value={new Intl.NumberFormat().format(Number(getData?.basicScore).toFixed(2)) || "0"}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type="text"
              label="Điểm xuất sắc"
              name="excellentScore"
              value={
                new Intl.NumberFormat().format(Number(getData?.excellentScore).toFixed(2)) || "0"
              }
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type="text"
              label="Tổng điểm xuất sắc"
              name="totalExcellentScore"
              value={
                new Intl.NumberFormat().format(Number(getData?.totalExcellentScore).toFixed(2)) ||
                "0"
              }
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Hệ số nhóm"
              name="groupCoefficient"
              value={
                new Intl.NumberFormat().format(Number(getData?.groupCoefficient).toFixed(2)) || "0"
              }
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Loại"
              name="title"
              value={getData?.title || "0"}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Mức tiền thưởng"
              name="bonusRewardLevel"
              value={formatCurrency.format(getData?.bonusRewardLevel) || "0"}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Tiền thưởng"
              name="bonustReward"
              value={formatCurrency.format(getData?.bonustReward) || "0"}
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FileAttachment
              id={id}
              containsEdit={containsEdit}
              containsCreate={containsCreate}
              file={getData.files || []}
              setFile={() => {}}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
ChiTietDiemCoBanCuoiNam.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChiTietDiemCoBanCuoiNam;
