import AxiosInstance from "./api";

export const OfficialsService = {
  getPaginate: async (payload) => {
    const query = new URLSearchParams(payload);
    return await AxiosInstance.get("/admin/officials", {
      params: query,
    });
  },
  getById: async (id) => {
    return await AxiosInstance.get(`/admin/officials/${id}`);
  },
  getAll: async (payload) => {
    return await AxiosInstance.get("/admin/officials/all");
  },
  exportCSV: async (params) => {
    return await AxiosInstance.get("/admin/officials/csv", { params: params });
  },
  update: async (id, payload) => {
    return await AxiosInstance.patch(`/admin/officials/${id}`, payload);
  },

  create: async (payload) => {
    return await AxiosInstance.post("/admin/officials", payload);
  },
  importExcel: async (payload) => {
    return await AxiosInstance.post("/admin/officials/csv", payload);
  },

  delete: async (id) => {
    return await AxiosInstance.delete(`/admin/officials/${id}`);
  },
};
