/* eslint-disable react/prop-types */
import Card from "@mui/material/Card";

import SoftBox from "components/SoftBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { TaskServices } from "services/taskServices";
import ActionCell from "./components/ActionCell";
import DataTable from "./data/index";

function QuanLyNhiemVu() {
  const [dataTable, setDataTable] = useState({
    columns: [
      { Header: "Tên nhiệm vụ", accessor: "ten-nv", sorted: false },
      { Header: "ghi chú", accessor: "ghi-chu", sorted: false },
      { Header: "thao tác", accessor: "action", sorted: false, align: "center" },
    ],
    rows: [],
  });
  const [query, setQuery] = useState({ sortBy: "createdAt", sortType: "desc" });
  const [paginationData, setPaginationData] = useState({ page: 1 });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const {
        data: { items, paginate },
      } = await TaskServices.getAll({
        ...query,
        limit: paginationData.size || 10,
        page: paginationData.page,
      });
      setPaginationData((prev) => ({ ...prev, count: paginate.count, size: paginate.size }));
      setDataTable((prev) => ({
        ...prev,
        rows: items.map((item) => ({
          id: item._id,
          "ten-nv": item.name,
          "ghi-chu": item?.note || "--",
          action: <ActionCell item={item} setDataTable={setDataTable} />,
        })),
      }));
      setLoading(false);
    })();
  }, [query, paginationData.page]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <DataTable
            loading={loading}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            query={query}
            setQuery={setQuery}
            table={dataTable}
            canSearch
          />
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

export default QuanLyNhiemVu;
