import AxiosInstance from "./api";

export const ParticipantService = {
  create: async (payload) => {
    return await AxiosInstance.post("/user/activity-participants-list", payload);
  },
  getAll: async (params) => {
    return await AxiosInstance.get(`/private/activity-participants-list`, { params: params });
  },
  confirm: async (id) => {
    return await AxiosInstance.patch(`/admin/activity-participants-list/${id}`);
  },
  AdminEdit: async (id, payload) => {
    return await AxiosInstance.patch(`/admin/activity-participants-list/${id}`, payload);
  },
  getById: async (id) => {
    return await AxiosInstance.get(`/private/activity-participants-list/${id}`);
  },
  delete: async (id) => {
    return await AxiosInstance.delete(`/admin/activity-participants-list/${id}`);
  },
  privateGetAll: async (params) => {
    const query = new URLSearchParams(params);
    return await AxiosInstance.get(`/private/activity-participants-list?${query.toString()}`);
  },
  userUpdate: async (id, payload) => {
    return await AxiosInstance.patch(`/user/activity-participants-list/${id}`, payload);
  },
  hostConfirm: async (id, payload) => {
    return await AxiosInstance.patch(`/host/activity-participants-list/${id}`, payload);
  },
  hostEdit: async (id, payload) => {
    return await AxiosInstance.patch(`/host/activity-participants-list/update/${id}`, payload);
  },
  assessorConfirm: async (id, payload) => {
    return await AxiosInstance.patch(`/host/activity-participants-list/assessor/${id}`, payload);
  },

  exportCSV: async (params) => {
    // const query = new URLSearchParams(params);
    return await AxiosInstance.get(`/private/activity-participants-list/export`, {
      params: params,
    });
  },
};
