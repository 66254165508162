import checkout from "./form";

const {
  formField: {
    canBoVC,
    tienThuongCoBan,
    tienThuongXuatXac,
    tongThuongCuoiNam,
    nam,
    thuongDBCaNhan,
    thuongDBNhom,
    ghiChu,
  },
} = checkout;

const initialValues = {
  [canBoVC.name]: "",
  [tienThuongCoBan.name]: "",
  [tienThuongXuatXac.name]: "",
  [tongThuongCuoiNam.name]: "",
  [nam.name]: "",
  [thuongDBCaNhan.name]: "",
  [thuongDBNhom.name]: "",
  [ghiChu.name]: "",
};

export default initialValues;
