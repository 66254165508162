import AxiosInstance from "./api";

export const FundService = {
  getAll: async (params) => {
    return await AxiosInstance.get(`/private/fund`, { params: params });
  },
  getById: async (id) => {
    return await AxiosInstance.get(`/private/fund/${id}`);
  },
  update: async (id, payload) => {
    return await AxiosInstance.patch(`/admin/fund/${id}`, payload);
  },
  create: async (payload) => {
    return await AxiosInstance.post(`/admin/fund/`, payload);
  },
  delete: async (id) => {
    return await AxiosInstance.delete(`/admin/fund/${id}`);
  },
  //   getById: async (id) => {
  //     return await AxiosInstance.get(`/notification/${id}`);
  //   },
  //   read: async (id) => {
  //     return await AxiosInstance.patch(`/user/activity-notification/${id}`);
  //   },
};
