/* eslint-disable react/prop-types */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// NewUser page components
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { ErrorMessage, Field } from "formik";
import FormField from "../FormField";
import SoftDatePicker from "components/SoftDatePicker";
import { formatDate } from "utils";
import avatar from "assets/images/avatar.jpg";
import { OfficialsService } from "services/officialsService";

function ChiTietThuongCoBanCuoiNam({ formData }) {
  const { formField, values, errors, touched, getData, onChangeValue, setFieldValue } = formData;
  const {
    // diemTongNhom,
    // heSoKyLuat,
    // heSoNhom,
    // heSoTapSu,
    // heSoThangLamViec,
    canBoVC,
    // tienThuongCoban,
    nam,
    ghiChu,
  } = formField;
  const {
    canBoVC: canBoVCV,
    // diemTongNhom: diemTongNhomV,
    // heSoKyLuat: heSoKyLuatV,
    // heSoNhom: heSoNhomV,
    // heSoTapSu: heSoTapSuV,
    // heSoThangLamViec: heSoThangLamViecV,
    // tienThuongCoban: tienThuongCobanV,
    nam: namV,
    ghiChu: ghiChuV,
  } = values;

  const { id } = useParams();
  const [files, setFiles] = useState({
    hinh: {},
    preview: avatar,
  });

  const [officals, setOfficals] = useState([]);
  const [isPayload, setIsPayload] = useState({ canBoVC: [] });

  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");

  useEffect(() => {
    const getOfficials = async () => {
      const { data } = await OfficialsService.getPaginate({ limit: 1000 });
      setOfficals(data.items);
    };
    getOfficials();
  }, []);

  const handleMultiSelect = (selectedOptions, type) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setIsPayload((prevState) => ({
      ...prevState,
      chuTri: selectedValues,
    }));
    setFieldValue(canBoVC.name, selectedValues);
  };

  // const handleChangeFile = (event) => {
  //   const { name } = event.target;
  //   const file = event.target.files[0];
  //   Object.assign(file, { preview: URL.createObjectURL(file) });
  //   if (file && Object.keys(file).length > 0) {
  //     setFiles((prev) => ({ ...prev, [name]: file }));
  //     onChangeValue(name, file);
  //   }
  // };

  // if (id && containsEdit && (!getData || Object.keys(getData).length === 0)) {
  //   return <>Loading...</>;
  // }

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          {id && containsEdit ? "Cập nhật" : id ? "Chi tiết" : "Tạo mới"}
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            {id && !containsEdit ? (
              <FormField
                type={canBoVC.type}
                label={canBoVC.label}
                name={canBoVC.name}
                error={errors.canBoVC && touched.canBoVC}
                disabled={id && !containsEdit}
              />
            ) : (
              <>
                <SoftBox display="flex" flexDirection="column">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    mb={0.9}
                    mt={1.7}
                  >
                    {canBoVC?.label}
                  </SoftTypography>
                  <SoftSelect
                    isMulti
                    className="customStyle"
                    closeMenuOnSelect={false}
                    // isLoading={loading}
                    // onMenuOpen={handleFetchDrivers}
                    onChange={(e) => handleMultiSelect(e, canBoVC.name)}
                    options={officals?.map((items) => ({
                      label: items?.personalInformation?.fullName,
                      value: items?._id,
                    }))}
                  />
                </SoftBox>
                <SoftBox mt={0.75}>
                  <SoftTypography component="div" variant="caption" color="error">
                    <ErrorMessage name={canBoVC.name} />
                  </SoftTypography>
                </SoftBox>
              </>
            )}
          </Grid>
        </Grid>

        {/* <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={diemTongNhom.type}
              label={diemTongNhom.label}
              name={diemTongNhom.name}
              value={diemTongNhomV}
              error={errors.diemTongNhom && touched.diemTongNhom}
              disabled={id && !containsEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={heSoKyLuat.type}
              label={heSoKyLuat.label}
              name={heSoKyLuat.name}
              value={heSoKyLuatV}
              error={errors.heSoKyLuat && touched.heSoKyLuat}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={heSoNhom.type}
              label={heSoNhom.label}
              name={heSoNhom.name}
              value={heSoNhomV}
              error={errors.heSoNhom && touched.heSoNhom}
              disabled={id && !containsEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={heSoTapSu.type}
              label={heSoTapSu.label}
              name={heSoTapSu.name}
              value={heSoTapSuV}
              error={errors.heSoTapSu && touched.heSoTapSu}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={heSoThangLamViec.type}
              label={heSoThangLamViec.label}
              name={heSoThangLamViec.name}
              value={heSoThangLamViecV}
              error={errors.heSoThangLamViec && touched.heSoThangLamViec}
              disabled={id && !containsEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={tienThuongCoban.type}
              label={tienThuongCoban.label}
              name={tienThuongCoban.name}
              value={tienThuongCobanV}
              error={errors.tienThuongCoban && touched.tienThuongCoban}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid> */}

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={nam.type}
              label={nam.label}
              name={nam.name}
              value={namV}
              error={errors.nam && touched.nam}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={ghiChu.type}
              label={ghiChu.label}
              name={ghiChu.name}
              value={ghiChuV}
              error={errors.ghiChu && touched.ghiChu}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
ChiTietThuongCoBanCuoiNam.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChiTietThuongCoBanCuoiNam;
