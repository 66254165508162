/* eslint-disable react/prop-types */
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import avatar from "assets/images/team-2.jpg";
// Data
import DataTable from "./data/index";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import ActionCell from "./components/ActionCell";
import { formatDate } from "utils";
// import { BookingService } from "services/bookingService";
import SoftBadge from "components/SoftBadge";
import dataTableData from "./data/dataTableData";
import { TaskServices } from "services/taskServices";

function QuanLyNhiemVu() {
  const [dataTable, setDataTable] = useState({
    columns: [
      { Header: "Tên nhiệm vụ", accessor: "ten-nv", align: "center", sorted: false },
      // { Header: "Mã nhiệm vụ", accessor: "ma-nv", sorted: false },
      { Header: "ghi chú", accessor: "ghi-chu", sorted: false },
      { Header: "thao tác", accessor: "action", sorted: false, align: "center" },
    ],
    rows: [],
  });
  const [query, setQuery] = useState({ sortBy: "createdAt", sortType: "desc" });
  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({ page: 1 });

  useEffect(() => {
    (async () => {
      setLoading(true);
      const {
        data: { items },
      } = await TaskServices.getAll({ limit: 100, ...query });
      setDataTable((prev) => ({
        ...prev,
        rows: items.map((item) => ({
          id: item._id,
          "ten-nv": item.name || "--",
          // "ma-nv": item.idTask,
          "ghi-chu": item?.note || "--",
          action: <ActionCell item={item} setDataTable={setDataTable} />,
        })),
      }));
    })();
    setLoading(false);
  }, [query, paginationData.page]);

  const getColorStatus = (status) => {
    switch (status) {
      case "Đã huỷ":
        return "error";
      case "Chưa điều phối":
        return "secondary";
      case "Đã điều phối":
        return "info";
      case "Đã hoàn thành":
        return "success";
    }
    // "primary",
    // "secondary",
    // "info",
    // "success",
    // "warning",
    // "error",
    // "light",
    // "dark",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <DataTable
            loading={loading}
            query={query}
            setQuery={setQuery}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            table={dataTable}
            canSearch
          />
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

export default QuanLyNhiemVu;
