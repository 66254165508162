const form = {
  formId: "new-user-form",
  formField: {
    canBoVC: {
      name: "canBoVC",
      label: "Cán bộ viên chức",
      type: "text",
      placeholder: "Vd: BM-A",
      errorMsg: "Cán bộ viên chức không được bỏ trống.",
    },
    tienThuongCoBan: {
      name: "tienThuongCoBan",
      label: "Tiền thưởng cơ bản",
      type: "number",
      min: 0,
      placeholder: "Vd: BM-A",
      errorMsg: "Tiền thưởng cơ bản không được bỏ trống.",
    },
    tienThuongXuatXac: {
      name: "tienThuongXuatXac",
      label: "Tiền thưởng xuất sắc cuối năm",
      type: "number",
      placeholder: "Vd: BM-A",
      errorMsg: "Tiền thưởng xuất sắc cuối năm không được bỏ trống.",
    },
    tongThuongCuoiNam: {
      name: "tongThuongCuoiNam",
      label: "Tổng thưởng cuối năm",
      type: "number",
      placeholder: "Vd: BM-A",
      errorMsg: "Tổng thưởng cuối năm không được bỏ trống.",
    },
    thuongDBCaNhan: {
      name: "thuongDBCaNhan",
      label: "Tiền Thưởng đặc biệt cá nhân",
      type: "number",
      value: 0,
      placeholder: "Vd: 100000",
      errorMsg: "Thưởng đặc biệt cá nhân không được bỏ trống.",
    },
    thuongDBNhom: {
      name: "thuongDBNhom",
      label: "Tiền Thưởng đặc biệt nhóm",
      type: "number",
      value: 0,
      placeholder: "Vd: 100000",
      errorMsg: "Thưởng đặc biệt nhóm không được bỏ trống.",
    },
    ghiChu: {
      name: "ghiChu",
      label: "Ghi chú",
      type: "text",
      placeholder: "Vd: BM-A",
      errorMsg: "Năm không được bỏ trống.",
    },
    nam: {
      name: "nam",
      label: "Năm",
      type: "number",
      placeholder: "Vd: BM-A",
      errorMsg: "Năm không được bỏ trống.",
    },
  },
};

export default form;
