const form = {
  formId: "new-user-form",
  formField: {
    heSoTapSu: {
      name: "heSoTapSu",
      label: "Hệ Số Tập Sự",
      type: "number",
      placeholder: "vd: 3",
      errorMsg: "Hệ số tập sự không được bỏ trống.",
    },
    heSoChinhThuc: {
      name: "heSoChinhThuc",
      label: "Hệ Số chính thức",
      type: "number",
      placeholder: "vd: 3",
      errorMsg: "Hệ số chính thức không được bỏ trống.",
    },

    phanTramKhenThuongDatBiet: {
      name: "phanTramKhenThuongDatBiet",
      label: "Tỷ lệ Phần trăm khen thưởng đặc biệt",
      type: "number",
      placeholder: "vd: 3",
      errorMsg: "Tỷ lệ Phần trăm khen thưởng đặc biệt không được bỏ trống.",
    },

    phanTramKhenThuongCoBan: {
      name: "phanTramKhenThuongCoBan",
      label: "Tỷ lệ Phần trăm quỹ khen thưởng cơ bản cuối năm",
      type: "number",
      placeholder: "vd: 3",
      errorMsg: "Tỷ lệ Phần trăm quỹ khen thưởng cơ bản cuối năm không được bỏ trống.",
    },

    phanTramKhenThuongXuatXac: {
      name: "phanTramKhenThuongXuatXac",
      label: "Tỷ lệ Phần trăm quỹ khen thưởng xuất sắc cuối năm",
      type: "number",
      placeholder: "vd: 3",
      errorMsg: "Tỷ lệ Phần trăm quỹ khen thưởng xuất sắc cuối năm không được bỏ trống.",
    },

    quyThuongCoBanCuoiNam: {
      name: "quyThuongCoBanCuoiNam",
      label: "Quỹ Thưởng Năm",
      type: "number",
      placeholder: "vd: 8.000.000",
      errorMsg: "Quỹ thưởng cơ Bản cuối năm không được bỏ trống.",
    },

    vienChucQuanLy: {
      name: "vienChucQuanLy",
      label: "Viên Chức Quản Lý",
      type: "number",
      placeholder: "vd: 2.5",
      errorMsg: "Hệ số viên chức quản lý không được bỏ trống.",
    },
    ngachGiangVien: {
      name: "ngachGiangVien",
      label: "Ngạch Giảng Viên",
      type: "number",
      placeholder: "vd: 2.5",
      errorMsg: "Hệ số ngạch giảng viên không được bỏ trống.",
    },
    ngachNghienCuuVien: {
      name: "ngachNghienCuuVien",
      label: "Ngạch Nghiên Cứu Viên, Kỹ Sư",
      type: "number",
      placeholder: "vd: 8.000.000",
      errorMsg: "Hệ số ngạch nghiên cứu viên, kỹ sư không được bỏ trống.",
    },
    ngachChuyenVien: {
      name: "ngachChuyenVien",
      label: "Ngạch Chuyên Viên",
      type: "number",
      placeholder: "vd: 8.000.000",
      errorMsg: "Hệ số ngạch chuyên viên không được bỏ trống.",
    },
  },
};

export default form;
