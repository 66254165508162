/* eslint-disable react/prop-types */
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import avatar from "assets/images/team-2.jpg";
// Data
import DataTable from "./data/index";
import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import ActionCell from "./components/ActionCell";
import { formatDate } from "utils";
import { BookingService } from "services/bookingService";
import SoftBadge from "components/SoftBadge";
import dataTableData from "./data/dataTableData";
import { ActivityGroupServices } from "services/activityGroupServices";
import { truncateText } from "utils";

function QuanLyNhomHoatDong() {
  const [dataTable, setDataTable] = useState({
    columns: [
      {
        Header: "Tên nhóm hoạt động",
        accessor: "ten-hd",
        sorted: false,
        Cell: ({ value }) => {
          return (
            <Box display="flex" gap={1} alignItems="center">
              <Box>{value?.length > 40 ? truncateText(value, 90) : value}</Box>
            </Box>
          );
        },
      },
      {
        Header: "loại nhiệm vụ",
        accessor: "loai-nv",
        sorted: false,
        Cell: ({ value }) => {
          return (
            <Box display="flex" gap={1} alignItems="center">
              <Box>{value?.length > 20 ? truncateText(value, 30) : value}</Box>
            </Box>
          );
        },
      },
      { Header: "Điểm thưởng", accessor: "diem-thuong", sorted: false, align: "right" },
      { Header: "số lượng hoạt động", accessor: "soLuong-hd", sorted: false, align: "right" },
      {
        Header: "Số lượng người đã tham gia",
        accessor: "tham-gia",
        sorted: false,
        align: "right",
      },

      { Header: "năm", accessor: "nam", sorted: false, align: "center" },
      { Header: "thao tác", accessor: "action", sorted: false, align: "center" },
    ],
    rows: [],
  });
  const [query, setQuery] = useState({ sortBy: "createdAt", sortType: "desc" });
  const [isData, setIsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({ page: 1 });

  useEffect(() => {
    (async () => {
      setLoading(true);
      const {
        data: { items, paginate },
      } = await ActivityGroupServices.getAll({
        limit: 99999999,
        year: new Date().getFullYear(),
        limit: paginationData.pageSize || 10,
        page: paginationData.page,
        ...query,
      });
      setPaginationData((prev) => ({ ...prev, count: paginate.count, size: paginate.size }));
      setIsData(items);

      setDataTable((prev) => ({
        ...prev,
        rows: items.map((item) => {
          return {
            id: item._id,
            "ten-hd": item?.name || "--",
            "loai-nv": item?.task?.name || "--",
            "diem-thuong": item?.maxExcellentScore || "--",
            "soLuong-hd": item?.activities?.length || "--",
            "tham-gia": item?.activities?.reduce((acc, curr) => acc + curr.totalParticipants, 0),
            nam: item?.year || "--",
            action: <ActionCell item={item} setDataTable={setDataTable} />,
          };
        }),
      }));
      setLoading(false);
    })();
  }, [query, paginationData.page]);

  const getColorStatus = (status) => {
    switch (status) {
      case "Đã huỷ":
        return "error";
      case "Chưa điều phối":
        return "secondary";
      case "Đã điều phối":
        return "info";
      case "Đã hoàn thành":
        return "success";
    }
    // "primary",
    // "secondary",
    // "info",
    // "success",
    // "warning",
    // "error",
    // "light",
    // "dark",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <DataTable
            loading={loading}
            query={query}
            setQuery={setQuery}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            table={dataTable}
            canSearch
          />
          {Object.keys(isData).length === 0 && (
            <SoftBox my={7}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                Không có dữ liệu
              </Grid>
            </SoftBox>
          )}
          {Object.keys(isData).length <= 2 && <SoftBox my={5}></SoftBox>}
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

export default QuanLyNhomHoatDong;
