import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { tenBoMon, maBoMon },
} = checkout;

const validations = [
  Yup.object().shape({
    [tenBoMon.name]: Yup.string().required(tenBoMon.errorMsg),
    // [maBoMon.name]: Yup.string().required(maBoMon.errorMsg),
  }),
];

export default validations;
