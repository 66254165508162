const form = {
  formId: "new-user-form",
  formField: {
    dinhMucNV1: {
      name: "dinhMucNV1",
      label: "Định mức Nhiệm vụ 1",
      type: "number",
      placeholder: "Vd: 100",
      errorMsg: "Định mức Nhiệm vụ 1 không được bỏ trống.",
    },
    dinhMucNV2: {
      name: "dinhMucNV2",
      label: "Định mức Nhiệm vụ 2",
      type: "number",
      placeholder: "Vd: 100",
      errorMsg: "Định mức Nhiệm vụ 2 không được bỏ trống.",
    },
    dinhMucNV3: {
      name: "dinhMucNV3",
      label: "Định mức Nhiệm vụ 3",
      type: "number",
      placeholder: "Vd: 100",
      errorMsg: "Định mức Nhiệm vụ 3 không được bỏ trống.",
    },
  },
};

export default form;
