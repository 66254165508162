/* eslint-disable react/prop-types */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// NewUser page components
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import FormField from "../FormField";
import { ErrorMessage, Field } from "formik";
import SoftDatePicker from "components/SoftDatePicker";
import { formatDate } from "utils";
import avatar from "assets/images/avatar.jpg";
import FileAttachment from "components/AttachmentFile";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import dataTableData from "../../data/dataTableData";
import DataTable from "../../data/index";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function XacNhanThamGiaHD({ formData }) {
  const { formField, values, errors, touched, getData, onChangeValue, setFieldValue } = formData;
  const {
    diemThuong,
    diemTru,
    ghiChu,
    gioToiDa,
    nhomHoatDong,
    loaiNhiemVu,
    maNhiemVu,
    ngayBatDau,
    ngayKetThuc,
    soLuongThamGia,
    tenHD,
    thoiGian,
    chuTri,
    diemChuTri,
    hoTro,
    diemHoTro,
    pho,
    diemPho,
    diemThamGia,
  } = formField;
  const {
    diemThuong: diemThuongV,
    gioToiDa: gioToiDaV,
    loaiNhiemVu: loaiNhiemVuV,
    ngayBatDau: ngayBatDauV,
    ngayKetThuc: ngayKetThucV,
    nhomHoatDong: nhomHoatDongV,
    soLuongThamGia: soLuongThamGiaV,
    tenHD: tenHDV,
    thoiGian: thoiGianV,
    ghiChu: ghiChuV,
    diemTru: diemTruV,
    chuTri: chuTriV,
    diemChuTri: diemChuTriV,
    diemHoTro: diemHoTroV,
    diemPho: diemPhoV,
    diemThamGia: diemThamGiaV,
    hoTro: hoTroV,
    pho: phoV,
  } = values;
  const { id } = useParams();
  const [files, setFiles] = useState({
    hinh: {},
    preview: avatar,
  });

  const [dob, setDob] = useState();
  const [isPayload, setIsPayload] = useState({});
  const [value, setValue] = useState(0);

  const currentPath = window.location.pathname;
  const containsConfirm = currentPath.includes("confirm");
  const containsCreate = currentPath.includes("create");
  const containsEdit = currentPath.includes("edit");
  const [deadLineDate, setDeadLineDate] = useState();

  useEffect(() => {}, [isPayload]);

  const handleSetDob = (newDate) => {
    const time = new Date(newDate[0]).getTime();
    setFieldValue("hanGuiMinhChung", time);
  };

  // if (id && !containsConfirm && (!getData || Object.keys(getData).length === 0)) {
  //   return <>Loading...</>;
  // }

  return !containsEdit ? (
    <div>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          Chi tiết
        </SoftTypography>
      </SoftBox>
      <Box sx={{ width: "100%" }}>
        <SoftBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormField
                type={"text"}
                label={"Tên hoạt động"}
                name={"tenHD"}
                value={getData?.activity?.name ?? "--"}
                error={errors.tenHD && touched.tenHD}
                disabled={id && !containsConfirm}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={"text"}
                label={"Tên Người tham gia"}
                name={"nguoiThamGia"}
                value={getData?.user?.personalInformation?.fullName ?? "--"}
                error={errors.diemThuong && touched.diemThuong}
                disabled={id && !containsConfirm}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={"text"}
                label={"Bộ môn"}
                name={"nguoiThamGia"}
                value={getData?.user?.department?.name ?? "--"}
                error={errors.diemTru && touched.diemTru}
                disabled={id && !containsConfirm}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={"text"}
                label={"Số giờ tham gia"}
                name={"nguoiThamGia"}
                value={getData?.process ?? "--"}
                error={errors.diemThuong && touched.diemThuong}
                disabled={id && !containsConfirm}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={"text"}
                label={"Vai trò"}
                name={"vaiTro"}
                value={getData?.role ?? "--"}
                error={errors.diemTru && touched.diemTru}
                disabled={id && !containsConfirm}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={diemThuong.type}
                label={diemThuong.label}
                name={diemThuong.name}
                value={getData?.activity?.rewardPoint ?? "--"}
                error={errors.diemThuong && touched.diemThuong}
                disabled={id && !containsConfirm}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={diemTru.type}
                label={diemTru.label}
                name={diemTru.name}
                value={getData?.activity?.deductionPoint ?? "--"}
                error={errors.diemTru && touched.diemTru}
                disabled={id && !containsConfirm}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={"chuTri"}
                label={"Chủ trì"}
                name={"chuTri"}
                value={
                  getData?.activity?.host?.length >= 4
                    ? truncateText(
                        getData?.activity?.host
                          ?.map((e) => e?.personalInformation?.fullName)
                          .toString(),
                        40
                      )
                    : getData?.activity?.host?.map((e) => e?.personalInformation?.fullName)
                }
                error={errors.chuTri && touched.chuTri}
                disabled={id && !containsConfirm}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={"pho"}
                label={"Phó"}
                name={"pho"}
                value={
                  getData?.activity?.deputy?.length >= 4
                    ? truncateText(
                        getData?.activity?.deputy
                          ?.map((e) => e?.personalInformation?.fullName)
                          .toString(),
                        40
                      )
                    : getData?.activity?.deputy?.map((e) => e?.personalInformation?.fullName)
                }
                error={errors.chuTri && touched.chuTri}
                disabled={id && !containsConfirm}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={"hoTro"}
                label={"Hỗ trợ"}
                name={"hoTro"}
                value={
                  getData?.activity?.supporter?.length >= 4
                    ? truncateText(
                        getData?.activity?.supporter
                          ?.map((e) => e?.personalInformation?.fullName)
                          .toString(),
                        40
                      )
                    : getData?.activity?.supporter?.map((e) => e?.personalInformation?.fullName)
                }
                error={errors.chuTri && touched.chuTri}
                disabled={id && !containsConfirm}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={"gioToiDa"}
                label={"Giờ tối đa"}
                name={"gioToiDa"}
                value={getData?.activity?.maxHours ?? "--"}
                error={errors.chuTri && touched.chuTri}
                disabled={id && !containsConfirm}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={soLuongThamGia.type}
                label={soLuongThamGia.label}
                name={soLuongThamGia.name}
                value={getData?.activity?.numberOfParticipants ?? "--"}
                error={errors.soLuongThamGia && touched.soLuongThamGia}
                disabled={id && !containsConfirm}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={"nhomHD"}
                label={"nhóm hoạt động"}
                name={"nhomHD"}
                value={getData?.activity?.activityGroup?.name ?? "--"}
                error={errors.soLuongThamGia && touched.soLuongThamGia}
                disabled={id && !containsConfirm}
              />
            </Grid>
          </Grid>
          {/* (new Date(Number(getData?.startTime) */}

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={"ngayBD"}
                label={"ngày bắt đầu"}
                name={"ngayBD"}
                value={formatDate(new Date(Number(getData?.activity?.startTime))) ?? "--"}
                error={errors.soLuongThamGia && touched.soLuongThamGia}
                disabled={id && !containsConfirm}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={"ngayKT"}
                label={"ngày kết thúc"}
                name={"ngayKT"}
                value={formatDate(new Date(Number(getData?.activity?.endTime))) ?? "--"}
                error={errors.soLuongThamGia && touched.soLuongThamGia}
                disabled={id && !containsConfirm}
              />
            </Grid>
          </Grid>
          {getData?.deadLineToPost && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type={"ngayGH"}
                  label={"ngày gia hạn"}
                  name={"ngayGH"}
                  value={formatDate(new Date(Number(getData?.deadLineToPost))) ?? "--"}
                  error={errors.soLuongThamGia && touched.soLuongThamGia}
                  disabled={id && !containsConfirm}
                />
              </Grid>
            </Grid>
          )}

          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormField
                type={ghiChu.type}
                label={ghiChu.label}
                name={ghiChu.name}
                value={getData?.note ?? "--"}
                error={errors.ghiChu && touched.ghiChu}
                disabled={id && !containsConfirm}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FileAttachment
                id={id}
                containsConfirm={containsConfirm}
                containsCreate={containsCreate}
                file={getData.files || []}
                setFile={setFiles}
                showAll={true}
              />
            </Grid>
          </Grid>
        </SoftBox>
      </Box>
    </div>
  ) : (
    <div>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          Gia hạn thời gian nộp minh chứng
        </SoftTypography>
      </SoftBox>
      <Box sx={{ width: "100%" }}>
        <SoftBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <SoftBox display="flex" flexDirection="column">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  mb={0.5}
                  mt={2.2}
                >
                  Ngày gia hạn
                </SoftTypography>
                <SoftDatePicker onChange={(e) => handleSetDob(e)} />
                <SoftBox mt={0.75}>
                  <SoftTypography component="div" variant="caption" color="error">
                    <ErrorMessage name={ngayKetThuc.name} />
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </Box>
    </div>
  );
}

// typechecking props for UserInfo
XacNhanThamGiaHD.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default XacNhanThamGiaHD;
