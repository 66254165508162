/* eslint-disable react/prop-types */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// NewUser page components
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { ErrorMessage, Field } from "formik";
import FormField from "../FormField";
import SoftDatePicker from "components/SoftDatePicker";
import { formatDate } from "utils";
import avatar from "assets/images/avatar.jpg";
import FileAttachment from "components/AttachmentFile";
import { TaskServices } from "services/taskServices";

function ChiTietNhomHoatDong({ formData }) {
  const { formField, values, errors, touched, getData, onChangeValue, setFieldValue } = formData;
  const { maNhomHoatDong, tenNhomHoatDong, loaiNhiemVu, diemThuong, ghiChu } = formField;
  const {
    maNhomHoatDong: maNhomHoatDongV,
    tenNhomHoatDong: tenNhomHoatDongV,
    loaiNhiemVu: loaiNhiemVuV,
    ghiChu: ghiChuV,
    diemThuong: diemThuongV,
  } = values;
  const { id } = useParams();
  const [files, setFiles] = useState([]);
  const [isPayload, setIsPayload] = useState({});
  const [task, setTask] = useState([]);
  const [selected, setSelected] = useState({});

  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");
  const containsCreate = currentPath.includes("create");
  const containsConfirm = currentPath.includes("confirm");

  useEffect(() => {
    if (Object.keys(getData).length > 0) {
      setFieldValue(tenNhomHoatDong.name, getData?.name || "--");
      setFieldValue(ghiChu.name, getData?.note || "--");
      setFieldValue(loaiNhiemVu.name, getData?.task?._id || "--");
      setFieldValue(diemThuong.name, getData?.maxExcellentScore || "--");
      setFieldValue("file", getData?.files || "--");
      setSelected({ value: getData?.task._id, label: getData?.task?.name });
    }
  }, [getData]);

  useEffect(() => {
    setFieldValue("file", files || []);
  }, [files]);

  useEffect(() => {
    const getTask = async () => {
      const { data } = await TaskServices.getAll({
        limit: 1000,
        sortBy: "createdAt",
        sortType: "desc",
      });
      setTask(data?.items);
    };
    getTask();
  }, []);

  if (id && containsEdit && (!getData || Object.keys(getData).length === 0)) {
    return <>Loading...</>;
  }

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          {id && containsEdit ? "Cập nhật" : id ? "Chi tiết" : "Tạo mới"}
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={tenNhomHoatDong.type}
              label={tenNhomHoatDong.label}
              name={tenNhomHoatDong.name}
              value={tenNhomHoatDongV}
              error={errors.tenNhomHoatDong && touched.tenNhomHoatDong}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SoftBox display="flex" flexDirection="column">
              {id && !containsEdit ? (
                <FormField
                  type={loaiNhiemVu.type}
                  label={loaiNhiemVu.label}
                  name={loaiNhiemVu.name}
                  value={selected.label}
                  error={errors.loaiNhiemVu && touched.loaiNhiemVu}
                  disabled={id && !containsEdit}
                />
              ) : (
                <SoftBox paddingTop={"3px"} display="block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    mb={0.5}
                    pl={0.8}
                  >
                    {loaiNhiemVu.label}
                  </SoftTypography>
                  <SoftSelect
                    value={selected}
                    onChange={(e) => {
                      setFieldValue(loaiNhiemVu.name, e.values);
                      setSelected({ value: e.values, label: e.label });
                    }}
                    options={task.map((items) => ({ values: items?._id, label: items?.name }))}
                  />
                  <SoftBox mt={0.75}>
                    <SoftTypography component="div" variant="caption" color="error">
                      <ErrorMessage name={loaiNhiemVu.name} />
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              )}
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={diemThuong.type}
              label={diemThuong.label}
              name={diemThuong.name}
              value={diemThuongV}
              error={errors.diemThuong && touched.diemThuong}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={ghiChu.type}
              label={ghiChu.label}
              name={ghiChu.name}
              value={ghiChuV}
              error={errors.ghiChu && touched.ghiChu}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FileAttachment
              id={id}
              containsEdit={containsEdit}
              containsCreate={containsCreate}
              containsConfirm={containsConfirm}
              file={getData?.files}
              setFile={setFiles}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
ChiTietNhomHoatDong.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChiTietNhomHoatDong;
