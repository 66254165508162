import axios from "axios";
import AxiosInstance from "./api";

export const YearEndBonusService = {
  export: async (payload) => {
    const query = new URLSearchParams(payload);
    return await AxiosInstance.get("/admin/year-end-bonus/export", {
      params: query,
    });
  },

  getPaginate: async (payload) => {
    // const query = new URLSearchParams(payload);
    return await AxiosInstance.get("/private/year-end-bonus", {
      params: payload,
    });
  },
  getInfo: async (payload) => {
    const query = new URLSearchParams(payload);
    return await AxiosInstance.get("/admin/year-end-bonus/information", {
      params: query,
    });
  },
  getById: async (id) => {
    return await AxiosInstance.get(`/private/year-end-bonus/${id}`);
  },
  getAll: async (payload) => {
    return await AxiosInstance.get("/private/year-end-bonus/all");
  },
  update: async (id, payload) => {
    return await AxiosInstance.patch(`/admin/year-end-bonus/${id}`, payload);
  },

  create: async (payload) => {
    return await AxiosInstance.post("/admin/year-end-bonus", payload);
  },

  delete: async (id) => {
    return await AxiosInstance.delete(`/admin/year-end-bonus/${id}`);
  },
};
