import AxiosInstance from "./api";

export const UserRewardService = {
  // BASIC SCORE
  getAllBasicScore: async (params) => {
    const query = new URLSearchParams(params);
    return await AxiosInstance.get(`/user/basic-score/auth?${query.toString()}`);
  },
  getByIdBasicScore: async (id) => {
    return await AxiosInstance.get(`/private/basic-score/${id}`);
  },
  // BASIC BONUS
  getAllBasicBonus: async (params) => {
    const query = new URLSearchParams(params);
    return await AxiosInstance.get(`/user/basic-bonus/auth?${query.toString()}`);
  },
  getByIdBasicBonus: async (id) => {
    return await AxiosInstance.get(`/private/basic-bonus/${id}`);
  },
  // REWARD RANKING
  getAllRewardRanking: async (params) => {
    const query = new URLSearchParams(params);
    return await AxiosInstance.get(`/user/reward-ranking/auth?${query.toString()}`);
  },
  getByIdRewardRanking: async (id) => {
    return await AxiosInstance.get(`/private/reward-ranking/${id}`);
  },
  // YEAR END BONUS
  getAllYearEndBonus: async (params) => {
    const query = new URLSearchParams(params);
    return await AxiosInstance.get(`/user/year-end-bonus/auth?${query.toString()}`);
  },
  getByIdYearEndBonus: async (id) => {
    return await AxiosInstance.get(`/private/year-end-bonus/${id}`);
  },
};
