import checkout from "./form";

const {
  formField: { ghiChu, lan1, lan2, lan3, lan4, mucViPham },
} = checkout;

const initialValues = {
  [mucViPham.name]: "",
  [lan1.name]: "",
  [lan2.name]: "",
  [lan3.name]: "",
  [lan4.name]: "",
  [ghiChu.name]: "",
};

export default initialValues;
