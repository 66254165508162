import { useEffect, useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { useNavigate, useParams } from "react-router-dom";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import validations from "./schemas/validations";
import { UploadService } from "services/uploadService";
import { toast } from "react-toastify";
import Info from "./components/Info";
import { OfficialsService } from "services/officialsService";
import { AuthService } from "services/authService";

function TongTinCanhan() {
  const [activeStep, setActiveStep] = useState(0);
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const { id } = useParams();
  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");
  const navigate = useNavigate();
  const [getData, setGetData] = useState({});
  const [data, setData] = useState({
    hinh: "",
    namSinh: "",
  });

  useEffect(() => {
    (async () => {
      const { data } = await AuthService.getMe();
      setGetData(data);
    })();
  }, []);

  const onChangeValue = (type, value) => {
    setData((prev) => ({ ...prev, [type]: value }));
  };

  const submitForm = async (values, actions) => {
    try {
      const urls = await UploadService.upload([values.hinh]);
      const payload = {
        fullName: values.hoTen,
        citizenId: values.cccd,
        officerId: values.maCanBo,
        birthday: values.namSinh,
        homeTown: values.queQuan,
        phoneNumber: values.sdt,
        gender: values.gioiTinh,
        address: values.diaChiLienHe,
        startTime: values.ngayBatDauLamViec,
        department: values.boMon,
        jobPosition: values.viTri,
        academicDegree: values.hocVi,
        officialRank: values.ngachVienChuc,
        avatar: urls[0],
        email: values.email,
      };

      await OfficialsService.update(getData?._id, payload);
      toast.success("Cập nhật thành công");
      navigate(-1);
    } catch (error) {
      toast.error("Cập nhật thất bại");
      console.log("🍕 ~ error:", error);
    }
    actions.setSubmitting(false);
  };

  const handleUpdate = async (values, actions) => {
    try {
      const urls = await UploadService.upload([values.hinh]);
      const payload = {
        fullName: values.hoTen,
        citizenId: values.cccd,
        officerId: values.maCanBo,
        birthday: values.namSinh,
        homeTown: values.queQuan,
        phoneNumber: values.sdt,
        gender: values.gioiTinh,
        address: values.diaChiLienHe,
        startTime: values.ngayBatDauLamViec,
        department: values.boMon,
        jobPosition: values.viTri,
        academicDegree: values.hocVi,
        officialRank: values.ngachVienChuc,
        avatar: urls[0],
        email: values.email,
      };

      await OfficialsService.update(id, payload);
      toast.success("Cập nhật thành công");
      navigate(-1);
    } catch (error) {
      toast.error("Cập nhật thất bại");
      console.log("🍕 ~ error:", error);
    }
    actions.setSubmitting(false);
  };

  const handleSubmit = (values, actions) => {
    if (id && containsEdit) {
      handleUpdate({ ...data, ...values }, actions);
    } else {
      submitForm({ ...data, ...values }, actions);
    }
  };

  const handleBack = () => navigate(-1);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <SoftBox p={2}>
                      <SoftBox>
                        <Info
                          formData={{
                            values,
                            touched,
                            formField,
                            errors,
                            getData,
                            onChangeValue,
                            setFieldValue,
                          }}
                        />
                        {/* <SoftBox mt={2} width="100%" display="flex" justifyContent="flex-end">
                          <SoftButton onClick={handleBack} variant="gradient" color="light">
                            Trở về
                          </SoftButton>
                          {!id && (
                            <SoftButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              Cập nhật
                            </SoftButton>
                          )}
                          {id && containsEdit && (
                            <SoftButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              Cập nhật
                            </SoftButton>
                          )}
                        </SoftBox> */}
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default TongTinCanhan;
