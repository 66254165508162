import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    heSoTapSu,
    heSoChinhThuc,
    phanTramKhenThuongCoBan,
    phanTramKhenThuongDatBiet,
    phanTramKhenThuongXuatXac,
    quyThuongCoBanCuoiNam,
    ngachChuyenVien,
    ngachGiangVien,
    ngachNghienCuuVien,
    vienChucQuanLy,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [heSoTapSu.name]: Yup.string().required(heSoTapSu.errorMsg),
    [heSoChinhThuc.name]: Yup.string().required(heSoChinhThuc.errorMsg),
    [phanTramKhenThuongCoBan.name]: Yup.string().required(phanTramKhenThuongCoBan.errorMsg),
    [phanTramKhenThuongDatBiet.name]: Yup.string().required(phanTramKhenThuongDatBiet.errorMsg),
    [phanTramKhenThuongXuatXac.name]: Yup.string().required(phanTramKhenThuongXuatXac.errorMsg),
    [quyThuongCoBanCuoiNam.name]: Yup.string().required(quyThuongCoBanCuoiNam.errorMsg),

    [ngachChuyenVien.name]: Yup.string().required(ngachChuyenVien.errorMsg),
    [ngachGiangVien.name]: Yup.string().required(ngachGiangVien.errorMsg),
    [ngachNghienCuuVien.name]: Yup.string().required(ngachNghienCuuVien.errorMsg),
    [vienChucQuanLy.name]: Yup.string().required(vienChucQuanLy.errorMsg),
  }),
];

export default validations;
