// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { ErrorMessage, Field } from "formik";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import { useEffect, useRef, useState } from "react";
import { formatCurrency } from "utils";

function isNumber(value) {
  return /^\d+$/.test(value);
}
function FormFieldCurrency({ label, name, value, ...rest }) {
  const [val, setVal] = useState(value);
  const firstRef = useRef(true);

  useEffect(() => {
    if (value?.toString() === "") {
      setVal("");
      return;
    }
    if (!isNumber(value)) return;
    if (firstRef.current) setVal(formatCurrency.format(value));
    else
      setVal(
        value?.toString()?.charAt(0) === "0" && value?.toString().length > 0
          ? value?.toString()?.slice(1)
          : value
      );
    firstRef.current = false;
  }, [value]);

  const handleBlur = () => {
    if (!isNumber(value)) return;
    if (val === formatCurrency.format(value)) return;
    setVal((prev) => formatCurrency.format(prev));
  };

  const handleFocus = () => {
    setVal(value);
  };

  return (
    <SoftBox mb={1.5}>
      {label && (
        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {label}
          </SoftTypography>
        </SoftBox>
      )}

      <Field
        {...rest}
        name={name}
        as={SoftInput}
        type="text"
        value={val}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      <SoftBox mt={0.75}>
        <SoftTypography component="div" variant="caption" color="error">
          <ErrorMessage name={name} />
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for FormField
FormFieldCurrency.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default FormFieldCurrency;
