import { useEffect, useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { useNavigate, useParams } from "react-router-dom";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import validations from "./schemas/validations";
import { ImageService } from "services/imageService";
import { toast } from "react-toastify";
import Info from "./components/Info";
import { MaintenanceService } from "services/maintenanceService";
import { SysService } from "services/sysServices";

function ChiTietDiemDinhMuc() {
  const [activeStep, setActiveStep] = useState(0);
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const { id } = useParams();
  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");
  const navigate = useNavigate();
  const [getData, setGetData] = useState({});
  const [data, setData] = useState({
    dinhMucNV1: "",
    idNV1: "",
    dinhMucNV2: "",
    idNV2: "",
    dinhMucNV3: "",
    idNV3: "",
  });

  useEffect(() => {
    // if (!id) return;
    // (async () => {
    //   const { data } = await DriverService.getById(id);
    //   setGetData(data);
    // })();
  }, [id]);

  const onChangeValue = (type, value) => {
    setData((prev) => ({ ...prev, [type]: value }));
  };

  const submitForm = async (values, actions) => {
    const isNumeric = (value) => {
      return /^\d+$/.test(value);
    };
    const hasSpecialCharacters = (value) => {
      return /[!@#$%^&*(),.?":{}|<>]/.test(value);
    };

    if (
      !isNumeric(values?.dinhMucNV1) ||
      hasSpecialCharacters(values?.dinhMucNV1) ||
      !isNumeric(values?.dinhMucNV2) ||
      hasSpecialCharacters(values?.dinhMucNV2) ||
      !isNumeric(values?.dinhMucNV3) ||
      hasSpecialCharacters(values?.dinhMucNV3)
    ) {
      toast.error("Định mức nhiệm vụ phải là số và không chứa ký tự đặc biệt!");
      actions.setSubmitting(false);
      return;
    }

    try {
      const updateRequests = [];
      if (values?.idNV1) {
        updateRequests.push(SysService.update(values?.idNV1, { value: +values.dinhMucNV1 }));
      }
      if (values?.idNV2) {
        updateRequests.push(SysService.update(values?.idNV2, { value: +values.dinhMucNV2 }));
      }
      if (values?.idNV3) {
        updateRequests.push(SysService.update(values?.idNV3, { value: +values.dinhMucNV3 }));
      }

      await Promise.all(updateRequests);
      toast.success("Thay đổi thành công");
      actions.setSubmitting(false);
    } catch (error) {
      actions.setSubmitting(false);
      toast.error("Thay đổi thất bại");
    }
  };

  const handleUpdate = async (values, actions) => {
    // console.log({ ...data, ...values });
    // const urls = await ImageService.uploadMany([values.hinh]);
    // const payload = {
    //   name: values.hoTen,
    //   dayOfBirth: values.dob,
    //   phone: values.sdt,
    //   address: values.diaChiLienHe,
    //   citizenId: values.cccd,
    //   homeTown: values.queQuan,
    //   email: values.email,
    //   salary: values.luongCoBan,
    //   image: urls[0],
    // };
    // try {
    //   await DriverService.update(id, payload);
    //   toast.success("Cập nhật thành công");
    //   navigate(-1);
    // } catch (error) {
    //   toast.error("Cập nhật thất bại");
    //   console.log("🍕 ~ error:", error);
    // }
    // actions.setSubmitting(false);
  };

  const handleSubmit = (values, actions) => {
    if (id && containsEdit) {
      handleUpdate({ ...data, ...values }, actions);
    } else {
      submitForm({ ...data, ...values }, actions);
    }
  };

  const handleBack = () => navigate(-1);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <SoftBox p={2}>
                      <SoftBox>
                        <Info
                          formData={{
                            values,
                            touched,
                            formField,
                            errors,
                            getData,
                            onChangeValue,
                            setFieldValue,
                          }}
                        />
                        <SoftBox mt={2} width="100%" display="flex" justifyContent="flex-end">
                          {/* <SoftButton onClick={handleBack} variant="gradient" color="light">
                            Trở về
                          </SoftButton> */}
                          {!id && (
                            <SoftButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              Thay đổi
                            </SoftButton>
                          )}
                          {id && containsEdit && (
                            <SoftButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              Cập nhật
                            </SoftButton>
                          )}
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default ChiTietDiemDinhMuc;
