import { Card, Grid, Box } from "@mui/material";
import FileAttachment from "components/AttachmentFile";
import FormField from "components/FormField";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ParticipantService } from "services/participantService";
import SoftDatePicker from "components/SoftDatePicker";
import { formatDate } from "utils";
const STATUS = {
  UN_CONFIRM: "Chưa xác nhận",
  CONFIRMED: "Đã xác nhận", // "Đã xác nhận"
  EVALUATED: "Đã đánh giá",
};

const RANKING = {
  EXCELLENCE: "Hoàn thành xuất sắc",
  GOOD: "Hoàn thành tốt",
  COMPLETE: "Hoàn thành",
  NOT_COMPLETE: "Không hoàn thành",
};

const statusOptions = [
  {
    label: "Chưa xác nhận",
    value: "Chưa xác nhận",
  },
  {
    label: "Đã xác nhận",
    value: "Đã xác nhận",
  },
  // {
  //   label: "Đã đánh giá",
  //   value: "Đã đánh giá",
  // },
];

const rankingOptions = [
  {
    label: "Hoàn thành xuất sắc",
    value: "Hoàn thành xuất sắc",
  },
  {
    label: "Hoàn thành tốt",
    value: "Hoàn thành tốt",
  },
  {
    label: "Hoàn thành",
    value: "Hoàn thành",
  },
  {
    label: "Không hoàn thành",
    value: "Không hoàn thành",
  },
];

const ROLE = {
  "Người tham gia": "Người tham gia",
  "Chủ trì": "Chủ trì",
  Phó: "Phó",
  "Hỗ trợ": "Hỗ trợ",
  "Toàn quyền đánh giá và chấm điểm": "Toàn quyền đánh giá và chấm điểm",
  "Người chấm điểm": "Người chấm điểm",
};

const NguoiThamGia = () => {
  const { activityId, participantId } = useParams();
  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("duyet");
  const containsExtend = currentPath.includes("edit");
  const navigate = useNavigate();

  const [data, setData] = useState();
  const [status, setStatus] = useState(statusOptions[0]);
  const [ranking, setRanking] = useState(rankingOptions[0]);
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [getData, setGetData] = useState();
  const [deadLineDate, setDeadLineDate] = useState();
  const isHostOrAdmin = [ROLE["Chủ trì"], ROLE["Toàn quyền đánh giá và chấm điểm"]].includes(
    data?.role
  );
  const isAssessorOrAdmin = [
    ROLE["Người chấm điểm"],
    ROLE["Toàn quyền đánh giá và chấm điểm"],
  ].includes(data?.role);

  const isHostConfirmed = data?.status === STATUS.CONFIRMED;
  const isAssessorConfirmed = data?.status === STATUS.EVALUATED;
  const isShowButtonSubmit =
    participantId &&
    containsEdit &&
    ((isHostOrAdmin && !isHostConfirmed) || (isAssessorOrAdmin && !isAssessorConfirmed));

  const handleSetDob = (newDate) => {
    const time = new Date(newDate[0]).getTime();
    setDeadLineDate(time);
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await ParticipantService.getById(participantId);
        setData(data);
        data.status && setStatus({ value: data.status, label: data.status });
        data.ranking && setRanking({ value: data.ranking, label: data.ranking });
      } catch (error) {
        console.log({ error });
      }
    })();
  }, [participantId, refetch]);

  useEffect(() => {
    if (!participantId) return;
    getParticipant();
  }, [participantId]);

  const getParticipant = async () => {
    const { data } = await ParticipantService.getById(participantId);
    setGetData(data);
  };

  const onToggleRefetch = () => setRefetch((prev) => !prev);

  const handleBack = () => navigate(-1);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (ROLE["Chủ trì"] === data?.role) {
        await handleConfirmByHost();
      } else if (ROLE["Người chấm điểm"] === data?.role) {
        await handleConfirmByAssessor();
      }
      // } else if (ROLE["Toàn quyền đánh giá và chấm điểm"] === data?.role) {
      //   await handleConfirmByHost();
      //   await handleConfirmByAssessor();
      // }
      setLoading(false);
      toast.success("Duyệt thành công");
      onToggleRefetch();
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || "Duyệt thất bại");
      console.log({ error });
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      if (ROLE["Chủ trì"] === data?.role) {
        await ParticipantService.hostEdit(participantId, {
          deadLineToPost: new Date(deadLineDate).setHours(23, 59, 59),
        });
      }
      setLoading(false);
      toast.success("Gia hạn thành công");
      getParticipant();
      onToggleRefetch();
      setDeadLineDate(0);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || "Gia hạn thất bại");
      console.log({ error });
    }
  };

  const handleChangeValue = (event) => {
    let val;
    const { name, value } = event.target;
    const regexNumber = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
    if (value && name !== "note") {
      val = regexNumber.test(value) ? value : "";
    } else val = value;
    setData((prev) => ({ ...prev, [name]: val }));
  };

  const handleConfirmByHost = async () => {
    const payload = {
      status: status.value,
      note: data.note,
      process: data.process,
      otherRewardPoint: +data.otherRewardPoint,
      otherMinusPoint: +data.otherMinusPoint,
      ranking: ranking.value,
    };
    await ParticipantService.hostConfirm(participantId, payload);
  };

  const handleConfirmByAssessor = async () => {
    const payload = {
      minusPoint: +data.minusPoint,
      rewardPoint: +data.rewardPoint,
    };
    await ParticipantService.assessorConfirm(participantId, payload);
  };

  return !containsExtend ? (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <Formik initialValues={{}}>
              {() => (
                <Form id={"participant-form"} autoComplete="off">
                  <Card sx={{ height: "100%", overflow: "visible" }}>
                    <SoftBox p={2}>
                      <SoftBox>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              type="text"
                              label="Họ và tên"
                              name="fullName"
                              value={data?.user?.personalInformation?.fullName}
                              disabled={true}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              type="text"
                              label="Số giờ tham gia"
                              name="process"
                              value={data?.process}
                              disabled={
                                (participantId && !containsEdit) ||
                                loading ||
                                !isHostOrAdmin ||
                                isHostConfirmed
                              }
                              onChange={handleChangeValue}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                            {(participantId && !containsEdit) ||
                            !isHostOrAdmin ||
                            isHostConfirmed ? (
                              <FormField
                                type="text"
                                label="Trạng thái"
                                name="status"
                                value={data?.status}
                                disabled={
                                  (participantId && !containsEdit) ||
                                  loading ||
                                  !isHostOrAdmin ||
                                  isHostConfirmed
                                }
                              />
                            ) : (
                              <SoftBox mb={1.5}>
                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                  >
                                    Trạng thái
                                  </SoftTypography>
                                </SoftBox>
                                <SoftSelect
                                  onChange={(e) => setStatus(e)}
                                  options={statusOptions}
                                  value={status}
                                />
                              </SoftBox>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                            {(participantId && !containsEdit) ||
                            !isHostOrAdmin ||
                            isHostConfirmed ? (
                              <FormField
                                type="text"
                                label="Xếp hạng"
                                name="ranking"
                                value={data?.ranking}
                                disabled={
                                  (participantId && !containsEdit) ||
                                  loading ||
                                  !isHostOrAdmin ||
                                  isHostConfirmed
                                }
                              />
                            ) : (
                              <SoftBox mb={1.5}>
                                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                  >
                                    Xếp hạng
                                  </SoftTypography>
                                </SoftBox>
                                <SoftSelect
                                  onChange={(e) => setRanking(e)}
                                  options={rankingOptions}
                                  value={ranking}
                                />
                              </SoftBox>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                            <FormField
                              type="text"
                              label="Điểm trừ"
                              name="minusPoint"
                              value={data?.minusPoint}
                              onChange={handleChangeValue}
                              disabled={!isAssessorOrAdmin || isAssessorConfirmed}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                            <FormField
                              type="text"
                              label="Điểm thưởng"
                              name="rewardPoint"
                              value={data?.rewardPoint}
                              onChange={handleChangeValue}
                              disabled={!isAssessorOrAdmin || isAssessorConfirmed}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                            <FormField
                              type="text"
                              label="Điểm trừ khác"
                              name="otherMinusPoint"
                              value={data?.otherMinusPoint}
                              disabled={
                                (participantId && !containsEdit) ||
                                loading ||
                                !isHostOrAdmin ||
                                isHostConfirmed
                              }
                              onChange={handleChangeValue}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                            <FormField
                              type="text"
                              label="Điểm thưởng khác"
                              name="otherRewardPoint"
                              value={data?.otherRewardPoint}
                              disabled={
                                (participantId && !containsEdit) ||
                                loading ||
                                !isHostOrAdmin ||
                                isHostConfirmed
                              }
                              onChange={handleChangeValue}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                            <FormField
                              type="text"
                              label="Ghi chú"
                              name="note"
                              value={data?.note}
                              disabled={
                                (participantId && !containsEdit) ||
                                loading ||
                                !isHostOrAdmin ||
                                isHostConfirmed
                              }
                              onChange={handleChangeValue}
                            />
                          </Grid>
                        </Grid>
                        <SoftBox style={{ width: "fit-content" }}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                              <FileAttachment
                                id={participantId}
                                containsEdit={false}
                                containsConfirm={false}
                                containsCreate={false}
                                file={data?.files || []}
                                showAll={true}
                              />
                            </Grid>
                          </Grid>
                        </SoftBox>
                        <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          <SoftButton onClick={handleBack} variant="gradient" color="light">
                            Trở về
                          </SoftButton>
                          {isShowButtonSubmit && (
                            <SoftButton
                              disabled={loading}
                              type="submit"
                              variant="gradient"
                              color="dark"
                              onClick={handleSubmit}
                            >
                              Duyệt
                            </SoftButton>
                          )}
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <Formik initialValues={{}}>
              {() => (
                <Form id={"participant-form"} autoComplete="off">
                  <Card sx={{ height: "100%", overflow: "visible" }}>
                    <SoftBox p={2}>
                      <div>
                        <SoftBox lineHeight={0}>
                          <SoftTypography variant="h5" fontWeight="bold">
                            Gia hạn thời gian nộp minh chứng
                          </SoftTypography>
                        </SoftBox>
                        <Box sx={{ width: "100%" }}>
                          <SoftBox>
                            {getData?.deadLineToPost && (
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                  <FormField
                                    type={"text"}
                                    label={"Ngày gia hạn"}
                                    name={"dob"}
                                    value={formatDate(getData?.deadLineToPost) ?? "--"}
                                    disabled={true}
                                  />
                                </Grid>
                              </Grid>
                            )}
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12}>
                                <SoftBox display="flex" flexDirection="column">
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    mb={0.5}
                                    mt={2.2}
                                  >
                                    Cập nhật ngày gia hạn
                                  </SoftTypography>
                                  <SoftDatePicker onChange={(e) => handleSetDob(e)} />
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                          <SoftBox
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            <SoftButton onClick={handleBack} variant="gradient" color="light">
                              Trở về
                            </SoftButton>

                            <SoftButton
                              type="submit"
                              variant="gradient"
                              color="dark"
                              onClick={handleUpdate}
                            >
                              Cập nhật
                            </SoftButton>
                          </SoftBox>
                        </Box>
                      </div>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
};

export default NguoiThamGia;
