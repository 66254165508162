import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { dinhMucNV1, dinhMucNV2, dinhMucNV3 },
} = checkout;

const validations = [
  Yup.object().shape({
    // [dinhMucNV1.name]: Yup.string().required(dinhMucNV1.errorMsg),
    // [dinhMucNV2.name]: Yup.string().required(dinhMucNV2.errorMsg),
    // [dinhMucNV3.name]: Yup.string().required(dinhMucNV3.errorMsg),
  }),
];

export default validations;
