import checkout from "./form";

const {
  formField: { tenCBVC, mucViPham, soLanViPham, ngayViPham, ngayKetThuc, ghiChu },
} = checkout;

const initialValues = {
  [tenCBVC.name]: "",
  [mucViPham.name]: "",
  // [soLanViPham.name]: "",
  [ngayViPham.name]: "",
  // [ngayKetThuc.name]: "",
  [ghiChu.name]: "",
};

export default initialValues;
