import AxiosInstance from "./api";

export const DiscipLineService = {
  getData: async () => {
    return await AxiosInstance.get("/admin/discipline-list/all");
  },
  getAll: async (params) => {
    return await AxiosInstance.get("/private/discipline-list", { params: params });
  },
  getById: async (id) => {
    return await AxiosInstance.get(`/private/discipline-list/${id}`);
  },
  create: async (payload) => {
    return await AxiosInstance.post("/admin/discipline-list/", payload);
  },
  update: async (id, payload) => {
    return await AxiosInstance.patch(`/admin/discipline-list/${id}`, payload);
  },
  delete: async (id) => {
    return await AxiosInstance.delete(`/admin/discipline-list/${id}`);
  },
  getAllLv: async (params) => {
    return await AxiosInstance.get("/private/discipline-level", { params: params });
  },
  getByIdLv: async (id) => {
    return await AxiosInstance.get(`/private/discipline-level/${id}`);
  },
  createLv: async (payload) => {
    return await AxiosInstance.post(`/admin/discipline-level`, payload);
  },
  updateLv: async (id, payload) => {
    return await AxiosInstance.patch(`/admin/discipline-level/${id}`, payload);
  },
  deleteLv: async (id) => {
    return await AxiosInstance.delete(`/admin/discipline-level/${id}`);
  },
  // getAll: async (payload) => {
  //   const query = new URLSearchParams(payload);
  //   return await AxiosInstance.get("/vehicle", {
  //     params: query,
  //   });
  // },
};
