import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    diemThuong,
    gioToiDa,
    nhomHoatDong,
    loaiNhiemVu,
    ngayBatDau,
    ngayKetThuc,
    soLuongThamGia,
    tenNhiemVu,
    thoiGian,
    ghiChu,
    diemTru,
    maNhiemVu,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [tenNhiemVu.name]: Yup.string().required(tenNhiemVu.errorMsg),
    // [diemThuong.name]: Yup.string().required(diemThuong.errorMsg),
    // [gioToiDa.name]: Yup.string().required(gioToiDa.errorMsg),
    // [nhomHoatDong.name]: Yup.string().required(nhomHoatDong.errorMsg),
    // [loaiNhiemVu.name]: Yup.string().required(loaiNhiemVu.errorMsg),
    // [ngayBatDau.name]: Yup.string().required(ngayBatDau.errorMsg),
    // [ngayKetThuc.name]: Yup.string().required(ngayKetThuc.errorMsg),
    // [nhomHoatDong.name]: Yup.string().required(nhomHoatDong.errorMsg),
    // [soLuongThamGia.name]: Yup.string().required(soLuongThamGia.errorMsg),
    // [thoiGian.name]: Yup.string().required(thoiGian.errorMsg),
  }),
];

export default validations;
