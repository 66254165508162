import checkout from "./form";

const {
  formField: { quyThuongNam, nam },
} = checkout;

const initialValues = {
  [quyThuongNam.name]: "",
  [nam.name]: "",
};

export default initialValues;
