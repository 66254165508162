import checkout from "./form";

const {
  formField: {
    hoTen,
    cccd,
    namSinh,
    queQuan,
    diaChiLienHe,
    email,
    sdt,
    boMon,
    hocVi,
    ngayBatDauLamViec,
    ngachVienChuc,
    viTri,
    gioiTinh,
    maCanBo,
    avatar,
  },
} = checkout;

const initialValues = {
  [hoTen.name]: "",
  [cccd.name]: "",
  [namSinh.name]: "",
  [queQuan.name]: "",
  [diaChiLienHe.name]: "",
  [email.name]: "",
  [sdt.name]: "",
  [boMon.name]: "",
  [hocVi.name]: "",
  [ngayBatDauLamViec.name]: "",
  [ngachVienChuc.name]: "",
  [viTri.name]: "",
  [gioiTinh.name]: "",
  [maCanBo.name]: "",
  [avatar.name]: "",
};

export default initialValues;
