/* eslint-disable react/prop-types */
import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftInput from "components/SoftInput";
import SoftPagination from "components/SoftPagination";

// Soft UI Dashboard PRO React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
import SoftButton from "components/SoftButton";
import dataTableData from "./dataTableData";
import { Box, Grid, Typography } from "@mui/material";
import { truncateText } from "utils";
import { item } from "examples/Sidenav/styles/sidenavItem";
import { ActivityGroupServices } from "services/activityGroupServices";
import { limits } from "chroma-js";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { formatDate } from "utils";
import { ActivityServices } from "services/activityServices";
import TableCommon from "components/TableCommon";
function DataTable({
  loading,
  query,
  setQuery,
  entriesPerPage,
  canSearch,
  table,
  paginationData,
  setPaginationData,
  isSorted,
  noEndBorder,
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries ? entriesPerPage.entries : [5, 10, 15, 20, 25];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = ({ value }) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <SoftPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </SoftPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);
  const [isYear, setYear] = useState([]);
  const [isData, setIsData] = useState([]);
  const [activity, setActivity] = useState([]);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;
    if (isSorted && column.sorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else {
      sortedValue = false;
    }
    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [activityGroup, setActivityGroup] = useState([]);

  useEffect(() => {
    let arr = [];
    for (var i = -2; i <= 7; i++) {
      const year = new Date().getFullYear() - i;
      arr.push(year);
    }
    setYear(arr);

    (async () => {
      const { data } = await ActivityServices.getAll({ limit: 999999 });
      setActivity(data);
    })();

    const getActivity = async () => {
      const { data } = await ActivityGroupServices.getAll({
        limit: 100,
        sortBy: "createdAt",
        sortType: "desc",
        year: new Date().getFullYear(),
      });
      setActivityGroup([{ name: "Tất cả", _id: "" }, ...data.items]);
    };
    getActivity();
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const exportToCSV = async () => {
    const dataCus = await ActivityServices.exportCSVByHost({ ...query });
    const listCus = dataCus?.data?.items?.map((item, index) => {
      return [
        item?.name,
        item?.numberOfParticipants ? item?.numberOfParticipants : "--",
        item?.rewardPoint ? item?.rewardPoint : "--",
        item?.deductionPoint ? item?.deductionPoint : "--",
        item?.startTime ? formatDate(item?.startTime) : "--",
        item?.endTime ? formatDate(item?.endTime) : "--",
      ];
    });
    const listCustomer = [
      [
        "Tên hoạt động",
        "Số lượng người tham gia tối đa",
        "Điểm thưởng",
        "Điểm trừ",
        "Ngày bắt đầu",
        "Ngày kết thúc",
      ],
      ...(listCus || []),
    ];
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const ws = XLSX.utils.aoa_to_sheet(listCustomer);

    const colWidths = [
      { wch: 100 },
      { wch: 20 },
      { wch: 20 },
      { wch: 12 },
      { wch: 20 },
      { wch: 15 },
    ];

    ws["!cols"] = colWidths;
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      `bang-danh-sach-hoat-dong-${query?.year || new Date().getFullYear()}.xlsx`
    );
  };

  useEffect(() => {
    const getData = async () => {
      const { data } = await ActivityServices.exportCSVByHost({ ...query });
      setIsData(data);
    };
    getData();
  }, []);

  return (
    <>
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        p={3}
        pb={1}
        style={{ width: "100%" }}
      >
        <Stack spacing={1} direction="row" mb={3}>
          {isData?.length > 0 && (
            <SoftButton variant="outlined" color="info" size="small" onClick={() => exportToCSV()}>
              Xuất file
            </SoftButton>
          )}
        </Stack>
        <SoftBox lineHeight={1} style={{ width: "100%" }}>
          <Grid container gap={2} style={{ width: "100%" }}>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <SoftBox display="flex" flexDirection="column" width={"100%"}>
                <SoftTypography component="label" variant="caption" fontWeight="bold" mb={0.5}>
                  Theo năm
                </SoftTypography>
                <SoftSelect
                  placeholder={new Date().getFullYear()}
                  onChange={(e) => setQuery((prev) => ({ ...prev, year: e.value }))}
                  options={isYear?.map((e) => ({ label: e, value: e }))}
                />
              </SoftBox>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <SoftBox display="flex" flexDirection="column" width={"100%"}>
                <SoftTypography component="label" variant="caption" fontWeight="bold" mb={0.5}>
                  Nhóm hoạt động
                </SoftTypography>
                <SoftSelect
                  onChange={(e) => setQuery((prev) => ({ ...prev, activityGroup: e.value }))}
                  options={activityGroup?.map((e) => ({ label: e?.name, value: e?._id }))}
                />
              </SoftBox>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3}>
              {canSearch && (
                <SoftBox display="flex" flexDirection="column">
                  <SoftTypography component="label" variant="caption" fontWeight="bold" mb={0.5}>
                    Tìm kiếm
                  </SoftTypography>
                  <SoftBox width="100%">
                    <SoftInput
                      placeholder="Tìm kiếm..."
                      value={search}
                      onChange={({ currentTarget }) => {
                        setSearch(search);
                        onSearchChange(currentTarget.value);
                      }}
                    />
                  </SoftBox>
                </SoftBox>
              )}
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>

      <TableContainer sx={{ boxShadow: "none" }}>
        <TableCommon
          loading={loading}
          query={query}
          setQuery={setQuery}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          table={table}
          isSorted={isSorted}
          noEndBorder={noEndBorder}
          tableInstance={tableInstance}
        />
      </TableContainer>
    </>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 99999999999, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  query: PropTypes.any,
  setQuery: PropTypes.any,
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default DataTable;
