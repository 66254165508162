import checkout from "./form";

const {
  formField: { maNhomHoatDong, tenNhomHoatDong, loaiNhiemVu, diemThuong, ghiChu },
} = checkout;

const initialValues = {
  [tenNhomHoatDong.name]: "",
  [loaiNhiemVu.name]: "",
  [diemThuong.name]: "",
  [ghiChu.name]: "",
};

export default initialValues;
