/* eslint-disable react/prop-types */
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import avatar from "assets/images/team-2.jpg";
// Data
import DataTable from "./data/index";
import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import ActionCell from "./components/ActionCell";
import { formatDate } from "utils";
import { BookingService } from "services/bookingService";
import SoftBadge from "components/SoftBadge";
import dataTableData from "./data/dataTableData";
import { ActivityServices } from "services/activityServices";
import { truncateText } from "utils";
import { ActivityGroupServices } from "services/activityGroupServices";
import { useSelector } from "react-redux";
import { authSelector } from "redux/selector";
import SoftTypography from "components/SoftTypography";

const STATUS_ENUM = {
  JOINED: "Đã tham gia",
  NOT_PARTICIPATE: "Chưa tham gia",
  END: "Đã kết thúc",
};

function QuanLyHoatDong() {
  const [dataTable, setDataTable] = useState({
    columns: [
      {
        Header: "Tên hoạt động",
        accessor: "ten-hoat-dong",
        width: "10%",
        sorted: false,
        Cell: ({ value }) => {
          const { name, title } = value;
          return (
            <Box display="flex" gap={1} alignItems="center">
              {/* <FirstColumn value={value} /> */}
              {!title ? (
                truncateText(name, 90)
              ) : (
                <SoftTypography
                  variant={"h6"}
                  component="label"
                  // variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {truncateText(name, 100)}
                </SoftTypography>
              )}
            </Box>
          );
        },
      },
      {
        Header: "Số lượng người đã tham gia",
        width: "15%",
        accessor: "tham-gia",
        sorted: false,
        align: "right",
      },

      {
        Header: "Điểm thưởng",
        accessor: "diem-thuong",
        width: "15%",
        align: "right",
        // sorted: true,
      },
      {
        Header: "Điểm trừ",
        accessor: "diem-tru",
        width: "15%",
        align: "right",
        // sorted: true,
      },

      {
        Header: "ngày bắt đầu",
        accessor: "ngay-bat-dau",
        width: "15%",
        align: "center",
        // sorted: true,
      },
      {
        Header: "ngày kết thúc",
        accessor: "ngay-ket-thuc",
        width: "15%",
        align: "center",
        // sorted: true,
      },
      {
        Header: "trạng thái",
        accessor: "trang-thai",
        width: "15%",
        align: "center",
        Cell: ({ value }) => {
          return (
            <Box display="flex" gap={1} alignItems="center">
              <span
                style={{
                  color:
                    value === STATUS_ENUM.JOINED
                      ? "#17C1E8"
                      : value === STATUS_ENUM.NOT_PARTICIPATE
                      ? "#344767"
                      : "red",
                }}
              >
                {truncateText(value, 90)}
              </span>
            </Box>
          );
        },
        // sorted: true,
      },
      { Header: "thao tác", accessor: "action", sorted: false, align: "center" },
    ],
    rows: [],
  });
  const [query, setQuery] = useState({
    sortBy: "createdAt",
    sortType: "desc",
    year: new Date().getFullYear(),
  });
  const [isData, setIsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({ page: 1 });
  const { user } = useSelector(authSelector);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await ActivityGroupServices.getForActivity({ ...query });
      let existingActivities = new Set();
      let newData = data.flatMap((item) => {
        let newActivities = item.activities
          .filter((activityItem) => {
            return !existingActivities.has(activityItem._id);
          })
          .map((activityItem) => {
            existingActivities.add(activityItem._id);
            return { name: item.name, ...activityItem };
          });
        return [{ name: item?.name, title: true }, ...newActivities];
      });
      setIsData(newData);
      setDataTable((prev) => ({
        ...prev,
        rows: newData.map((item) => ({
          id: item?._id,
          "ten-hoat-dong": { name: item?.name, title: item?.title } || "",
          "tham-gia": (!item?.title && item?.totalParticipants) || "",
          "diem-thuong": (!item?.title && item?.rewardPoint) || "",
          "diem-tru": (!item?.title && item?.deductionPoint) || "",
          "ngay-bat-dau": (!item?.title && formatDate(item?.startTime)) || "",
          "ngay-ket-thuc": (!item?.title && formatDate(item?.endTime)) || "",
          "trang-thai": !item?.title
            ? item?.activityparticipantslists &&
              item?.activityparticipantslists.length !== 0 &&
              item?.activityparticipantslists.find((item2) => item2.user == user._id)
              ? STATUS_ENUM.JOINED
              : new Date(item?.endTime).getTime() <= Date.now()
              ? STATUS_ENUM.END
              : STATUS_ENUM.NOT_PARTICIPATE || "--"
            : null,
          title: item.title || false,
          action: !item?.title && <ActionCell item={item} setDataTable={setDataTable} />,
        })),
      }));
      setLoading(false);
    })();
  }, [query]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card style={{ overflow: "visible" }}>
          <DataTable
            loading={loading}
            query={query}
            setQuery={setQuery}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            table={dataTable}
            canSearch
          />
          {Object.keys(isData).length === 0 && !loading && (
            <SoftBox my={7}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                Không có dữ liệu
              </Grid>
            </SoftBox>
          )}
          {Object.keys(isData).length <= 2 && <SoftBox my={5}></SoftBox>}
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

export default QuanLyHoatDong;
