import AxiosInstance from "./api";
export const TaskServices = {
  create: async (payload) => {
    return await AxiosInstance.post("/admin/task/", payload);
  },
  update: async (id, payload) => {
    return await AxiosInstance.patch(`/admin/task/${id}`, payload);
  },
  delete: async (id) => {
    return await AxiosInstance.delete(`/admin/task/${id}`);
  },
  getAll: async (params) => {
    return await AxiosInstance.get("/private/task/", { params: params });
  },
  getData: async () => {
    return await AxiosInstance.get("/private/task/all");
  },
  getById: async (id) => {
    return await AxiosInstance.get(`/private/task/${id}`);
  },
};
