/* eslint-disable react/prop-types */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// NewUser page components
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { ErrorMessage, Field } from "formik";
import FormField from "../FormField";
import SoftDatePicker from "components/SoftDatePicker";
import { formatDate } from "utils";
import avatar from "assets/images/avatar.jpg";
import { SysService } from "services/sysServices";
import { FundService } from "services/fundServices";
import FormFieldCurrency from "../FormField/currency";

const SYS_KEY_ENUM = {
  TS: "F-TS-TS",
  CT: "F-TS-CT",
  MTXS: "MTXS",
  QKTDB: "QKTDB",
  QKTCBCN: "QKTCBCN",
  QKTXSCN: "QKTXSCN",
  N1: "F-NH-1",
  N2: "F-NH-2",
  N3: "F-NH-3",
  N4: "F-NH-4",
};

function ChiTietHeSoCoBan({ formData }) {
  const { formField, values, errors, touched, getData, onChangeValue, setFieldValue } = formData;
  const {
    heSoTapSu,
    quyThuongCoBanCuoiNam,
    ngachChuyenVien,
    ngachGiangVien,
    ngachNghienCuuVien,
    vienChucQuanLy,
    heSoChinhThuc,

    phanTramKhenThuongCoBan,
    phanTramKhenThuongDatBiet,
    phanTramKhenThuongXuatXac,
  } = formField;
  const {
    heSoTapSu: heSoTapSuV,
    heSoChinhThuc: heSoChinhThucV,
    quyThuongCoBanCuoiNam: quyThuongCoBanCuoiNamV,
    ngachChuyenVien: ngachChuyenVienV,
    ngachGiangVien: ngachGiangVienV,
    ngachNghienCuuVien: ngachNghienCuuVienV,
    vienChucQuanLy: vienChucQuanLyV,
    phanTramKhenThuongCoBan: phanTramKhenThuongCoBanV,
    phanTramKhenThuongDatBiet: phanTramKhenThuongDatBietV,
    phanTramKhenThuongXuatXac: phanTramKhenThuongXuatXacV,
  } = values;
  const { id } = useParams();
  const [files, setFiles] = useState({
    hinh: {},
    preview: avatar,
  });
  const [isPayload, setIsPayload] = useState({});
  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");

  useEffect(() => {}, [isPayload]);

  const handleChangeFile = (event) => {
    const { name } = event.target;
    const file = event.target.files[0];
    Object.assign(file, { preview: URL.createObjectURL(file) });
    if (file && Object.keys(file).length > 0) {
      setFiles((prev) => ({ ...prev, [name]: file }));
      onChangeValue(name, file);
    }
  };

  // if (id && containsEdit && (!getData || Object.keys(getData).length === 0)) {
  //   return <>Loading...</>;
  // }

  useEffect(() => {
    const getAllSys = async () => {
      const { data } = await SysService.getAll();
      data?.forEach((item) => {
        switch (item?.key) {
          case SYS_KEY_ENUM.CT:
            setFieldValue(heSoChinhThuc.name, item?.value || 0);
            setFieldValue("idChinhThuc", item?._id || 0);
            break;
          case SYS_KEY_ENUM.TS:
            setFieldValue(heSoTapSu.name, item?.value || 0);
            setFieldValue("idTapSu", item?._id || 0);
            break;
          case SYS_KEY_ENUM.QKTCBCN:
            setFieldValue(phanTramKhenThuongCoBan.name, item?.value || 0);
            setFieldValue("idThuongCoBan", item?._id || 0);
            break;
          case SYS_KEY_ENUM.QKTXSCN:
            setFieldValue(phanTramKhenThuongXuatXac.name, item?.value || 0);
            setFieldValue("idThuongXuatXac", item?._id || 0);
            break;
          case SYS_KEY_ENUM.QKTDB:
            setFieldValue(phanTramKhenThuongDatBiet.name, item?.value || 0);
            setFieldValue("idThuongDatBiet", item?._id || 0);
            break;

          case SYS_KEY_ENUM.N1:
            setFieldValue(vienChucQuanLy.name, item?.value || 0);
            setFieldValue("idVienChuc", item?._id || 0);
            break;

          case SYS_KEY_ENUM.N2:
            setFieldValue(ngachGiangVien.name, item?.value || 0);
            setFieldValue("idGiangVien", item?._id || 0);
            break;

          case SYS_KEY_ENUM.N3:
            setFieldValue(ngachNghienCuuVien.name, item?.value || 0);
            setFieldValue("idNghienCuuVien", item?._id || 0);
            break;

          case SYS_KEY_ENUM.N4:
            setFieldValue(ngachChuyenVien.name, item?.value || 0);
            setFieldValue("idChuyenVien", item?._id || 0);
            break;
          default:
            break;
        }
      });
    };

    const getFund = async () => {
      const currentYear = new Date().getFullYear();
      const { data } = await FundService.getAll({ year: currentYear });
      setFieldValue(quyThuongCoBanCuoiNam.name, data?.items?.[0]?.amount || 0);
      setFieldValue("idQuyNam", data?.items?.[0]?._id || 0);
    };

    getAllSys();
    getFund();
  }, []);

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          Chi Tiết
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={heSoTapSu.type}
              label={heSoTapSu.label}
              name={heSoTapSu.name}
              value={heSoTapSuV}
              error={errors.heSoTapSu && touched.heSoTapSu}
              disabled={id && !containsEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={"number"}
              label={heSoChinhThuc.label}
              name={heSoChinhThuc.name}
              value={heSoChinhThucV}
              error={errors.heSoChinhThuc && touched.heSoChinhThuc}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={phanTramKhenThuongCoBan.type}
              label={phanTramKhenThuongCoBan.label}
              name={phanTramKhenThuongCoBan.name}
              value={phanTramKhenThuongCoBanV}
              error={errors.phanTramKhenThuongCoBan && touched.phanTramKhenThuongCoBan}
              disabled={id && !containsEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={phanTramKhenThuongDatBiet.type}
              label={phanTramKhenThuongDatBiet.label}
              name={phanTramKhenThuongDatBiet.name}
              value={phanTramKhenThuongDatBietV}
              error={errors.phanTramKhenThuongDatBiet && touched.phanTramKhenThuongDatBiet}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={phanTramKhenThuongXuatXac.type}
              label={phanTramKhenThuongXuatXac.label}
              name={phanTramKhenThuongXuatXac.name}
              value={phanTramKhenThuongXuatXacV}
              error={errors.phanTramKhenThuongXuatXac && touched.phanTramKhenThuongXuatXac}
              disabled={id && !containsEdit}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormFieldCurrency
              type={quyThuongCoBanCuoiNam.type}
              label={quyThuongCoBanCuoiNam.label}
              name={quyThuongCoBanCuoiNam.name}
              value={quyThuongCoBanCuoiNamV}
              error={errors.quyThuongCoBanCuoiNam && touched.quyThuongCoBanCuoiNam}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              * Hệ Số Ngạch Viên Chức
            </SoftTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={vienChucQuanLy.type}
              label={vienChucQuanLy.label}
              name={vienChucQuanLy.name}
              value={vienChucQuanLyV}
              error={errors.vienChucQuanLy && touched.vienChucQuanLy}
              disabled={id && !containsEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={ngachGiangVien.type}
              label={ngachGiangVien.label}
              name={ngachGiangVien.name}
              value={ngachGiangVienV}
              error={errors.ngachGiangVien && touched.ngachGiangVien}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={ngachNghienCuuVien.type}
              label={ngachNghienCuuVien.label}
              name={ngachNghienCuuVien.name}
              value={ngachNghienCuuVienV}
              error={errors.ngachNghienCuuVien && touched.ngachNghienCuuVien}
              disabled={id && !containsEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={ngachChuyenVien.type}
              label={ngachChuyenVien.label}
              name={ngachChuyenVien.name}
              value={ngachChuyenVienV}
              error={errors.ngachChuyenVien && touched.ngachChuyenVien}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
ChiTietHeSoCoBan.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChiTietHeSoCoBan;
