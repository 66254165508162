// Soft UI Dashboard PRO React layouts
import Default from "layouts/dashboards/default";
import ProductsList from "layouts/ecommerce/products/products-list";
import Icon from "@mui/material/Icon";
import Settings from "examples/Icons/Settings";
import GavelIcon from "@mui/icons-material/Gavel";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
// Soft UI Dashboard PRO React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Basket from "examples/Icons/Basket";
import Cube from "examples/Icons/Cube";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import PersonIcon from "@mui/icons-material/Person";
import WifiProtectedSetupIcon from "@mui/icons-material/WifiProtectedSetup";
//routes
import ThongTinCaNhan from "layouts/thong-tin-ca-nhan/chi-tiet";
import QuanLyCanBo from "layouts/quan-ly-can-bo";
import ChiTietCanBo from "layouts/quan-ly-can-bo/chi-tiet/index";
import QuanLyBoMon from "layouts/quan-ly-bo-mon";
import ChiTietBoMon from "layouts/quan-ly-bo-mon/chi-tiet";
import QuanLyNhiemVu from "layouts/quan-ly-nhiem-vu";
import ChiTietNhiemVu from "layouts/quan-ly-nhiem-vu/chi-tiet";
import QuanLyHoatDong from "layouts/quan-ly-hoat-dong/hoat-dong";
import ChiTietHoatDong from "layouts/quan-ly-hoat-dong/hoat-dong/chi-tiet";
import QuanLyNhomHoatDong from "layouts/quan-ly-hoat-dong/nhom-hoat-dong";
import ChiTietNhomHoatDong from "layouts/quan-ly-hoat-dong/nhom-hoat-dong/chi-tiet/index";
import XacNhanThamGiaHD from "layouts/quan-ly-hoat-dong/hoat-dong/chi-tiet/confirm";
import ChiTietDiemDinhMuc from "layouts/cai-dat/diem-dinh-muc/chi-tiet";
import ChiTietHeSoCoBan from "layouts/cai-dat/he-so/chi-tiet";
import QuanLyKyLuat from "layouts/quan-ly-ky-luat/ky-luat";
import ChiTietKyLuat from "layouts/quan-ly-ky-luat/ky-luat/chi-tiet";
import QuanLyViPham from "layouts/quan-ly-ky-luat/vi-pham";
import ChiTietViPham from "layouts/quan-ly-ky-luat/vi-pham/chi-tiet";

import DiemCoBanCuoiNam from "layouts/quan-ly-khen-thuong/diem-co-ban-cuoi-nam";
import ChiTietDiemCoBanCuoiNam from "layouts/quan-ly-khen-thuong/diem-co-ban-cuoi-nam/chi-tiet";

import ThuongCoBanCuoiNam from "layouts/quan-ly-khen-thuong/thuong-co-ban-cuoi-nam";
import ChiTietThuongCoBanCuoiNam from "layouts/quan-ly-khen-thuong/thuong-co-ban-cuoi-nam/chi-tiet";

import DiemXuatXacCuoiNam from "layouts/quan-ly-khen-thuong/diem-xuat-xac-cuoi-nam";
import ChiTietDiemXuatXacCuoiNam from "layouts/quan-ly-khen-thuong/diem-xuat-xac-cuoi-nam/chi-tiet";

import ThuongXuatXacCuoiNam from "layouts/quan-ly-khen-thuong/thuong-xuat-xac-cuoi-nam";
import ChiTietThuongXuatXacCuoiNam from "layouts/quan-ly-khen-thuong/thuong-xuat-xac-cuoi-nam/chi-tiet";

import TongThuongCuoiNam from "layouts/quan-ly-khen-thuong/tong-thuong-cuoi-nam";
import ChiTietTongThuongCuoiNam from "layouts/quan-ly-khen-thuong/tong-thuong-cuoi-nam/chi-tiet";

// user private

import QuanLyNhiemVuNguoiDung from "layouts/private/quan-ly-nhiem-vu";
import QuanLyHoatDongNguoiDung from "layouts/private/quan-ly-hoat-dong/hoat-dong";
import ChiTietHoatDongNguoiDung from "layouts/private/quan-ly-hoat-dong/hoat-dong/chi-tiet";
import QuanLyKyLuatNguoiDung from "layouts/private/quan-ly-ky-luat/ky-luat";
import ChiTietKyLuatNguoiDung from "layouts/private/quan-ly-ky-luat/ky-luat/chi-tiet";
import QuanLyViPhamNguoiDung from "layouts/private/quan-ly-ky-luat/vi-pham";
import ChiTietViPhamNguoiDung from "layouts/private/quan-ly-ky-luat/vi-pham/chi-tiet";
import DiemCoBangCuoiNamNguoiDung from "layouts/private/quan-ly-khen-thuong/diem-co-ban-cuoi-nam";
import ChiTietDiemCoBangCuoiNamNguoiDung from "layouts/private/quan-ly-khen-thuong/diem-co-ban-cuoi-nam/chi-tiet";
import TongThuongCuoiNamNguoiDung from "layouts/private/quan-ly-khen-thuong/tong-thuong-cuoi-nam";
import ChiTietTongThuongCuoiNamNguoiDung from "layouts/private/quan-ly-khen-thuong/tong-thuong-cuoi-nam/chi-tiet";
import TienThuongCoBanNguoiDung from "layouts/private/quan-ly-khen-thuong/tien-thuong-co-ban";
import ChiTietTienThuongCoBanNguoiDung from "layouts/private/quan-ly-khen-thuong/tien-thuong-co-ban/chi-tiet";
import TienThuongXuatSacNguoiDung from "layouts/private/quan-ly-khen-thuong/tien-thuong-xuat-sac";
import ChiTietTienThuongXuatSacNguoiDung from "layouts/private/quan-ly-khen-thuong/tien-thuong-xuat-sac/chi-tiet";
import ChiTietnguoiThamGia from "layouts/private/quan-ly-hoat-dong/hoat-dong/chi-tiet/components/Info/NguoiThamGia";
import ChiTietCaiDatHeThong from "layouts/cai-dat/he-thong/chi-tiet";
import QuyKhenThuong from "layouts/quy-khen-thuong";
import ChiTietQuyKhenThuong from "layouts/quy-khen-thuong/chi-tiet";

const routes = [
  {
    type: "collapse",
    name: "Bảng điều khiển",
    key: "dashboards",
    icon: <Shop size="12px" />,
    route: "/dashboards",
    noCollapse: true,
    component: <Default />,
    permission: ["admin"],
    // collapse: [
    //   {
    //     name: "Trang chủ",
    //     key: "trang-chu",
    //     route: "/dashboards",
    //     component: <Default />,
    //     hidden: true,
    //   },
    // ],
  },
  { type: "title", title: "Quản lý", key: "title-quan-ly", permission: ["admin", "user"] },

  {
    type: "collapse",
    name: "Quản lý hoạt động",
    key: "quan-ly-hoat-dong",
    icon: <Cube size="12px" />,
    route: "/quan-ly-hoat-dong/hoat-dong",
    component: <QuanLyHoatDong />,
    permission: ["admin"],
    collapse: [
      {
        name: "Hoạt động",
        key: "hoat-dong",
        route: "/quan-ly-hoat-dong/hoat-dong",
        component: <QuanLyHoatDong />,
        permission: ["admin"],
        hidden: true,
      },
      {
        name: "Hoạt động",
        key: "hoat-dong",
        route: "/quan-ly-hoat-dong/hoat-dong/create",
        component: <ChiTietHoatDong />,
        permission: ["admin"],

        hidden: true,
      },
      {
        name: "Hoạt động",
        key: "hoat-dong",
        route: "/quan-ly-hoat-dong/hoat-dong/:id",
        component: <ChiTietHoatDong />,
        permission: ["admin"],

        hidden: true,
      },
      {
        name: "Hoạt động",
        key: "hoat-dong",
        route: "/quan-ly-hoat-dong/hoat-dong/:id/edit",
        component: <ChiTietHoatDong />,
        permission: ["admin"],

        hidden: true,
      },
      {
        name: "Hoạt động",
        key: "hoat-dong",
        route: "/quan-ly-hoat-dong/hoat-dong/duyet/:id/edit",
        component: <XacNhanThamGiaHD />,
        permission: ["admin"],
        hidden: true,
      },
      {
        name: "Hoạt động",
        key: "hoat-dong",
        route: "/quan-ly-hoat-dong/hoat-dong/chi-tiet/:id/",
        component: <XacNhanThamGiaHD />,
        permission: ["admin"],
        hidden: true,
      },
    ],
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Quản lý khen thưởng",
    key: "quan-ly-khen-thuong",
    icon: <ThumbUpIcon style={{ width: "15px" }} />,
    route: "/quan-ly-khen-thuong/tong-thuong-cuoi-nam",
    component: <TongThuongCuoiNam />,
    permission: ["admin"],

    collapse: [
      {
        name: "Tổng thưởng cuối năm",
        key: "tong-thuong-cuoi-nam",
        route: "/quan-ly-khen-thuong/tong-thuong-cuoi-nam",
        component: <TongThuongCuoiNam />,
        permission: ["admin", "user"],
        hidden: true,
      },
      {
        name: "Tổng thưởng cuối năm",
        key: "tong-thuong-cuoi-nam",
        route: "/quan-ly-khen-thuong/tong-thuong-cuoi-nam/:id",
        component: <ChiTietTongThuongCuoiNam />,
        hidden: true,
        permission: ["admin", "user"],
      },
      {
        name: "Tổng thưởng cuối năm",
        key: "tong-thuong-cuoi-nam",
        route: "/quan-ly-khen-thuong/tong-thuong-cuoi-nam/:id/edit",
        component: <ChiTietTongThuongCuoiNam />,
        hidden: true,
        permission: ["admin", "user"],
      },
      {
        name: "Tổng thưởng cuối năm",
        key: "tong-thuong-cuoi-nam",
        route: "/quan-ly-khen-thuong/tong-thuong-cuoi-nam/create",
        component: <ChiTietTongThuongCuoiNam />,
        hidden: true,
        permission: ["admin", "user"],
      },
    ],
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Quản lý CBVC",
    key: "quan-ly-can-bo-vien-chuc",
    icon: <CustomerSupport size="12px" />,
    route: "/quan-ly-can-bo-vien-chuc/can-bo-vien-chuc",
    component: <QuanLyCanBo />,
    permission: ["admin"],
    collapse: [
      {
        name: "Cán bộ viên chức",
        key: "quan-ly-can-bo-vien-chuc",
        route: "/quan-ly-can-bo-vien-chuc/can-bo-vien-chuc",
        component: <QuanLyCanBo />,
        permission: ["admin"],
      },
      {
        name: "Cán bộ viên chức",
        key: "quan-ly-can-bo-vien-chuc",
        route: "/quan-ly-can-bo-vien-chuc/can-bo-vien-chuc/create",
        component: <ChiTietCanBo />,
        hidden: true,
        permission: ["admin"],
      },
      {
        name: "Cán bộ viên chức",
        key: "quan-ly-can-bo-vien-chuc",
        route: "/quan-ly-can-bo-vien-chuc/can-bo-vien-chuc/:id",
        component: <ChiTietCanBo />,
        hidden: true,
        permission: ["admin"],
      },
      {
        name: "Cán bộ viên chức",
        key: "quan-ly-can-bo-vien-chuc",
        route: "/quan-ly-can-bo-vien-chuc/can-bo-vien-chuc/:id/edit",
        component: <ChiTietCanBo />,
        hidden: true,
        permission: ["admin"],
      },
    ],
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Quản lý nhiệm vụ",
    key: "quan-ly-nhiem-vu",
    icon: <Document size="12px" />,
    route: "/quan-ly-nhiem-vu/nhiem-vu",
    component: <QuanLyNhiemVu />,
    permission: ["admin"],
    collapse: [
      {
        name: "Nhiệm vụ",
        key: "quan-ly-nhiem-vu",
        route: "/quan-ly-nhiem-vu/nhiem-vu",
        component: <QuanLyNhiemVu />,
        permission: ["admin", "user"],
      },
      {
        name: "Nhiệm vụ",
        key: "quan-ly-nhiem-vu",
        route: "/quan-ly-nhiem-vu/nhiem-vu/create",
        component: <ChiTietNhiemVu />,
        permission: ["admin", "user"],

        hidden: true,
      },
      {
        name: "Nhiệm vụ",
        key: "quan-ly-nhiem-vu",
        route: "/quan-ly-nhiem-vu/nhiem-vu/:id",
        component: <ChiTietNhiemVu />,
        permission: ["admin", "user"],
        hidden: true,
      },
      {
        name: "Nhiệm vụ",
        key: "quan-ly-nhiem-vu",
        route: "/quan-ly-nhiem-vu/nhiem-vu/:id/edit",
        component: <ChiTietNhiemVu />,
        permission: ["admin", "user"],
        hidden: true,
      },
    ],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Quản lý vi phạm",
    key: "quan-ly-ky-luat",
    icon: <GavelIcon style={{ width: "15px" }} />,
    route: "/quan-ly-ky-luat/vi-pham",
    component: <QuanLyViPham />,
    permission: ["admin"],
    collapse: [
      {
        name: "Vi phạm",
        key: "vi-pham",
        route: "/quan-ly-ky-luat/vi-pham",
        component: <QuanLyViPham />,
        permission: ["admin"],
      },
      {
        name: "Vi phạm",
        key: "vi-pham",
        route: "/quan-ly-ky-luat/vi-pham/create",
        component: <ChiTietViPham />,
        hidden: true,
        permission: ["admin"],
      },
      {
        name: "Vi phạm",
        key: "vi-pham",
        route: "/quan-ly-ky-luat/vi-pham/:id",
        component: <ChiTietViPham />,
        hidden: true,
        permission: ["admin"],
      },
      {
        name: "Vi phạm",
        key: "vi-pham",
        route: "/quan-ly-ky-luat/vi-pham/:id/edit",
        component: <ChiTietViPham />,
        hidden: true,
        permission: ["admin"],
      },
    ],
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Quản lý bộ môn",
    key: "quan-ly-bo-mon",
    icon: <Office size="12px" />,
    route: "/quan-ly-bo-mon/bo-mon",
    component: <QuanLyBoMon />,
    permission: ["admin"],

    collapse: [
      {
        name: "Bộ Môn",
        key: "bo-mon",
        route: "/quan-ly-bo-mon/bo-mon",
        component: <QuanLyBoMon />,
        permission: ["admin"],
        hidden: true,
      },
      {
        name: "Bộ Môn",
        key: "bo-mon",
        route: "/quan-ly-bo-mon/bo-mon/create",
        component: <ChiTietBoMon />,
        permission: ["admin"],
        hidden: true,
      },
      {
        name: "Bộ Môn",
        key: "bo-mon",
        route: "/quan-ly-bo-mon/bo-mon/:id",
        component: <ChiTietBoMon />,
        permission: ["admin"],
        hidden: true,
      },
      {
        name: "Bộ Môn",
        key: "bo-mon",
        route: "/quan-ly-bo-mon/bo-mon/:id/edit",
        component: <ChiTietBoMon />,
        permission: ["admin"],
        hidden: true,
      },
    ],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Chức năng khác",
    key: "chuc-nang-khac",
    icon: <WifiProtectedSetupIcon size="12px" />,
    permission: ["admin"],
    collapse: [
      // hoat-dong
      {
        name: "Nhóm hoạt động",
        key: "nhom-hoat-dong",
        route: "/chuc-nang-khac/nhom-hoat-dong",
        component: <QuanLyNhomHoatDong />,
        permission: ["admin"],
      },
      {
        name: "Nhóm hoạt động",
        key: "quan-ly-hoat-dong",
        route: "/chuc-nang-khac/nhom-hoat-dong/create",
        component: <ChiTietNhomHoatDong />,
        permission: ["admin"],
        hidden: true,
      },
      {
        name: "Nhóm hoạt động",
        key: "quan-ly-hoat-dong",
        route: "/chuc-nang-khac/nhom-hoat-dong/:id",
        component: <ChiTietNhomHoatDong />,
        permission: ["admin"],
        hidden: true,
      },
      {
        name: "Nhóm hoạt động",
        key: "quan-ly-hoat-dong",
        route: "/chuc-nang-khac/nhom-hoat-dong/:id/edit",
        component: <ChiTietNhomHoatDong />,
        permission: ["admin"],
        hidden: true,
      },
      //ky luat

      {
        name: "Kỷ luật",
        key: "ky-luat",
        route: "/chuc-nang-khac/ky-luat",
        component: <QuanLyKyLuat />,
        permission: ["admin"],
      },
      {
        name: "Kỷ luật",
        key: "ky-luat",
        route: "/chuc-nang-khac/ky-luat/create",
        component: <ChiTietKyLuat />,
        hidden: true,
        permission: ["admin"],
      },
      {
        name: "Kỷ luật",
        key: "ky-luat",
        route: "/chuc-nang-khac/ky-luat/:id",
        component: <ChiTietKyLuat />,
        hidden: true,
        permission: ["admin"],
      },
      {
        name: "Kỷ luật",
        key: "ky-luat",
        route: "/chuc-nang-khac/ky-luat/:id/edit",
        component: <ChiTietKyLuat />,
        hidden: true,
        permission: ["admin"],
      },
      //
      {
        name: "Điểm cơ bản cuối năm",
        key: "diem-co-ban",
        route: "/chuc-nang-khac/diem-co-ban",
        component: <DiemCoBanCuoiNam />,
        permission: ["admin"],
      },
      {
        name: "Điểm cơ bản cuối năm",
        key: "diem-co-ban",
        route: "/chuc-nang-khac/diem-co-ban/create",
        component: <ChiTietDiemCoBanCuoiNam />,
        hidden: true,
        permission: ["admin"],
      },
      {
        name: "Điểm cơ bản cuối năm",
        key: "diem-co-ban",
        route: "/chuc-nang-khac/diem-co-ban/:id",
        component: <ChiTietDiemCoBanCuoiNam />,
        hidden: true,
        permission: ["admin"],
      },
      {
        name: "Điểm cơ bản cuối năm",
        key: "diem-co-ban",
        route: "/chuc-nang-khac/diem-co-ban/:id/edit",
        component: <ChiTietDiemCoBanCuoiNam />,
        hidden: true,
        permission: ["admin"],
      },

      {
        name: "Tiền thưởng cơ bản",
        key: "thuong-co-ban",
        route: "/chuc-nang-khac/thuong-co-ban",
        component: <ThuongCoBanCuoiNam />,
        permission: ["admin"],
      },
      {
        name: "Tiền thưởng cơ bản",
        key: "thuong-co-ban",
        route: "/chuc-nang-khac/thuong-co-ban/create",
        component: <ChiTietThuongCoBanCuoiNam />,
        hidden: true,
        permission: ["admin"],
      },
      {
        name: "Tiền thưởng cơ bản",
        key: "thuong-co-ban",
        route: "/chuc-nang-khac/thuong-co-ban/:id",
        component: <ChiTietThuongCoBanCuoiNam />,
        hidden: true,
        permission: ["admin"],
      },
      {
        name: "Tiền thưởng cơ bản",
        key: "thuong-co-ban",
        route: "/chuc-nang-khac/thuong-co-ban/:id/edit",
        component: <ChiTietThuongCoBanCuoiNam />,
        hidden: true,
        permission: ["admin"],
      },

      {
        name: "Tiền thưởng xuất sắc",
        key: "thuong-xuat-xac",
        route: "/chuc-nang-khac/thuong-xuat-xac",
        component: <ThuongXuatXacCuoiNam />,
        permission: ["admin"],
      },
      {
        name: "Tiền thưởng xuất sắc",
        key: "thuong-xuat-xac",
        route: "/chuc-nang-khac/thuong-xuat-xac/create",
        component: <ChiTietThuongXuatXacCuoiNam />,
        hidden: true,
        permission: ["admin"],
      },
      {
        name: "Tiền thưởng xuất sắc",
        key: "thuong-xuat-xac",
        route: "/chuc-nang-khac/thuong-xuat-xac/:id",
        component: <ChiTietThuongXuatXacCuoiNam />,
        hidden: true,
        permission: ["admin"],
      },
      {
        name: "Tiền thưởng xuất sắc",
        key: "thuong-xuat-xac",
        route: "/chuc-nang-khac/thuong-xuat-xac/:id/edit",
        component: <ChiTietThuongXuatXacCuoiNam />,
        hidden: true,
        permission: ["admin"],
      },

      {
        name: "Quỹ khen thưởng",
        key: "quy-khen-thuong",
        route: "/chuc-nang-khac/quy-khen-thuong",
        component: <QuyKhenThuong />,
        permission: ["admin"],
      },
      {
        name: "Quỹ khen thưởng",
        key: "quy-khen-thuong",
        route: "/chuc-nang-khac/quy-khen-thuong/create",
        component: <ChiTietQuyKhenThuong />,
        hidden: true,
        permission: ["admin"],
      },
      {
        name: "Quỹ khen thưởng",
        key: "quy-khen-thuong",
        route: "/chuc-nang-khac/quy-khen-thuong/:id",
        component: <ChiTietQuyKhenThuong />,

        hidden: true,
        permission: ["admin"],
      },
      {
        name: "Quỹ khen thưởng",
        key: "quy-khen-thuong",
        route: "/chuc-nang-khac/quy-khen-thuong/:id/edit",
        component: <ChiTietQuyKhenThuong />,
        hidden: true,
        permission: ["admin"],
      },

      {
        name: "Điểm định mức",
        key: "diem-dinh-muc",
        route: "/chuc-nang-khac/diem-dinh-muc",
        component: <ChiTietDiemDinhMuc />,
        permission: ["admin"],
      },
      {
        name: "Hệ số cơ bản",
        key: "he-so-co-ban",
        route: "/chuc-nang-khac/he-so-co-ban",
        component: <ChiTietHeSoCoBan />,
        permission: ["admin"],
      },
      {
        name: "Cài đặt hệ thống",
        key: "cai-dat-he-thong",
        route: "/chuc-nang-khac/cai-dat-he-thong",
        component: <ChiTietCaiDatHeThong />,
        permission: ["admin"],
      },
    ],
  },
  // {
  //   type: "collapse",
  //   name: "Cài đặc hệ thống",
  //   key: "cai-dat",
  //   icon: <Settings size="12px" />,
  //   permission: ["admin"],
  //   collapse: [
  //     {
  //       name: "Điểm định mức",
  //       key: "diem-dinh-muc",
  //       route: "/cai-dat/diem-dinh-muc",
  //       component: <ChiTietDiemDinhMuc />,
  //       permission: ["admin"],
  //     },
  //     {
  //       name: "Hệ số cơ bản",
  //       key: "he-so-co-ban",
  //       route: "/cai-dat/he-so-co-ban",
  //       component: <ChiTietHeSoCoBan />,
  //       permission: ["admin"],
  //     },
  //   ],
  // },

  // PRIVATE

  {
    type: "collapse",
    name: "Thông tin cá nhân",
    key: "thong-tin-ca-nhan",
    icon: <PersonIcon size="12px" />,
    route: "/thong-tin-ca-nhan",
    noCollapse: true,
    component: <ThongTinCaNhan />,
    permission: ["user"],
  },
  {
    type: "collapse",
    name: "Quản lý hoạt động",
    key: "user-quan-ly-hoat-dong",
    icon: <Cube size="12px" />,
    route: "user-quan-ly-hoat-dong/hoat-dong",
    component: <QuanLyHoatDongNguoiDung />,
    permission: ["user"],
    collapse: [
      {
        name: "Hoạt động",
        key: "quan-ly-hoat-dong",
        route: "user-quan-ly-hoat-dong/hoat-dong",
        component: <QuanLyHoatDongNguoiDung />,
        permission: ["user"],
        hidden: true,
      },
      {
        name: "Hoạt động",
        key: "quan-ly-hoat-dong",
        route: "user-quan-ly-hoat-dong/hoat-dong/:id",
        component: <ChiTietHoatDongNguoiDung />,
        permission: ["user"],
        hidden: true,
      },
      {
        name: "Hoạt động",
        key: "quan-ly-hoat-dong",
        route: "/user-quan-ly-hoat-dong/hoat-dong/:activityId/nguoi-tham-gia/:participantId",
        component: <ChiTietnguoiThamGia />,
        permission: ["user"],
        hidden: true,
      },
      {
        name: "Hoạt động",
        key: "quan-ly-hoat-dong",
        route: "/user-quan-ly-hoat-dong/hoat-dong/:activityId/nguoi-tham-gia/:participantId/duyet",
        component: <ChiTietnguoiThamGia />,
        permission: ["user"],
        hidden: true,
      },
      {
        name: "Hoạt động",
        key: "quan-ly-hoat-dong",
        route: "/user-quan-ly-hoat-dong/hoat-dong/:activityId/nguoi-tham-gia/:participantId/edit",
        component: <ChiTietnguoiThamGia />,
        permission: ["user"],
        hidden: true,
      },
    ],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Quản lý khen thưởng",
    key: "user-quan-ly-khen-thuong",
    icon: <Document size="12px" />,
    route: "/user-quan-ly-khen-thuong/tong-thuong-cuoi-nam",
    component: <TongThuongCuoiNamNguoiDung />,
    permission: ["user"],
    collapse: [
      {
        name: "Quản lý khen thưởng",
        key: "tong-thuong-cuoi-nam",
        route: "/user-quan-ly-khen-thuong/tong-thuong-cuoi-nam",
        component: <TongThuongCuoiNamNguoiDung />,
        permission: ["user"],
        hidden: true,
      },
      {
        name: "Quản lý khen thưởng",
        key: "tong-thuong-cuoi-nam",
        route: "/user-quan-ly-khen-thuong/tong-thuong-cuoi-nam/:id",
        component: <ChiTietTongThuongCuoiNamNguoiDung />,
        hidden: true,
        permission: ["user"],
      },
    ],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Quản lý nhiệm vụ",
    key: "user-quan-ly-nhiem-vu",
    icon: <Document size="12px" />,
    route: "user-quan-ly-nhiem-vu/nhiem-vu",
    component: <QuanLyNhiemVuNguoiDung />,
    permission: ["user"],
    collapse: [
      {
        name: "Nhiệm vụ",
        key: "quan-ly-nhiem-vu",
        route: "user-quan-ly-nhiem-vu/nhiem-vu",
        component: <QuanLyNhiemVuNguoiDung />,
        permission: ["user"],
        hidden: true,
      },
      {
        name: "Nhiệm vụ",
        key: "quan-ly-nhiem-vu",
        route: "user-quan-ly-nhiem-vu/nhiem-vu/:id",
        component: <ChiTietNhiemVu />,
        permission: ["user"],
        hidden: true,
      },
    ],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Quản lý kỷ luật",
    key: "user-quan-ly-ky-luat",
    icon: <Document size="12px" />,
    route: "user-quan-ly-ky-luat/ky-luat",
    component: <QuanLyKyLuatNguoiDung />,
    permission: ["user"],
    collapse: [
      {
        name: "Kỷ luật",
        key: "quan-ly-ky-luat",
        route: "user-quan-ly-ky-luat/ky-luat",
        component: <QuanLyKyLuatNguoiDung />,
        permission: ["user"],
        hidden: true,
      },
      {
        name: "Kỷ luật",
        key: "quan-ly-ky-luat",
        route: "user-quan-ly-ky-luat/ky-luat/:id",
        component: <ChiTietKyLuatNguoiDung />,
        hidden: true,
        permission: ["user"],
      },
    ],
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Quản lý vi phạm",
    key: "user-quan-ly-vi-pham",
    icon: <Document size="12px" />,
    route: "/user-quan-ly-vi-pham/vi-pham",
    component: <QuanLyViPhamNguoiDung />,
    permission: ["user"],
    collapse: [
      {
        name: "Vi phạm",
        key: "quan-ly-vi-pham",
        route: "user-quan-ly-vi-pham/vi-pham",
        component: <QuanLyViPhamNguoiDung />,
        permission: ["user"],
        hidden: true,
      },
      {
        name: "Vi phạm",
        key: "vi-pham",
        route: "/user-quan-ly-vi-pham/vi-pham/:id",
        component: <ChiTietViPhamNguoiDung />,
        hidden: true,
        permission: ["user"],
      },
    ],
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Chức năng khác",
    key: "user-chuc-nang-khac",
    icon: <Document size="12px" />,
    permission: ["user"],
    collapse: [
      {
        name: "Điểm cơ bản cuối năm",
        key: "diem-co-ban-cuoi-nam",
        route: "/user-chuc-nang-khac/diem-co-ban-cuoi-nam",
        component: <DiemCoBangCuoiNamNguoiDung />,
        permission: ["user"],
      },
      {
        name: "Điểm cơ bản cuối năm chi tiết",
        key: "diem-co-ban-cuoi-nam",
        route: "/user-chuc-nang-khac/diem-co-ban-cuoi-nam/:id",
        component: <ChiTietDiemCoBangCuoiNamNguoiDung />,
        permission: ["user"],
        hidden: true,
      },
      {
        name: "Tiền thưởng cơ bản",
        key: "tien-thuong-co-ban",
        route: "/user-chuc-nang-khac/tien-thuong-co-ban",
        component: <TienThuongCoBanNguoiDung />,
        permission: ["user"],
      },
      {
        name: "Tiền thưởng cơ bản chi tiết",
        key: "tien-thuong-co-ban",
        route: "/user-chuc-nang-khac/tien-thuong-co-ban/:id",
        component: <ChiTietTienThuongCoBanNguoiDung />,
        permission: ["user"],
        hidden: true,
      },
      {
        name: "Tiền thưởng xuất sắc",
        key: "tien-thuong-xuat-sac",
        route: "/user-chuc-nang-khac/tien-thuong-xuat-sac",
        component: <TienThuongXuatSacNguoiDung />,
        permission: ["user"],
      },
      {
        name: "Tiền thưởng xuất sắc chi tiết",
        key: "tien-thuong-xuat-sac",
        route: "/user-chuc-nang-khac/tien-thuong-xuat-sac/:id",
        component: <ChiTietTienThuongXuatSacNguoiDung />,
        permission: ["user"],
        hidden: true,
      },
    ],
    // noCollapse: true,
  },
];

export default routes;
