import AxiosInstance from "./api";

export const DepartmentService = {
  getData: async () => {
    return await AxiosInstance.get("/admin/department/all");
  },
  getAll: async (params) => {
    return await AxiosInstance.get("/private/department", { params: params });
  },
  create: async (payload) => {
    return await AxiosInstance.post("/admin/department/", payload);
  },
  update: async (id, payload) => {
    return await AxiosInstance.patch(`/admin/department/${id}`, payload);
  },
  getById: async (id) => {
    return await AxiosInstance.get(`/admin/department/${id}`);
  },
  delete: async (id) => {
    return await AxiosInstance.delete(`/admin/department/${id}`);
  },
  // getAll: async (payload) => {
  //   const query = new URLSearchParams(payload);
  //   return await AxiosInstance.get("/vehicle", {
  //     params: query,
  //   });
  // },
  getAllForUser: async (payload) => {
    const query = new URLSearchParams(payload);
    return await AxiosInstance.get("/private/department", {
      params: query,
    });
  },
};
