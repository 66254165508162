/* eslint-disable react/prop-types */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// NewUser page components
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { ErrorMessage, Field } from "formik";
import FormField from "../FormField";
import SoftDatePicker from "components/SoftDatePicker";
import { formatDate } from "utils";
import avatar from "assets/images/avatar.jpg";
import { SysService } from "services/sysServices";

const TASK_ENUM = {
  NV1: "DMNV1",
  NV2: "DMNV2",
  NV3: "DMNV3",
};

function ChiTietDiemDinhMuc({ formData }) {
  function isNumber(value) {
    return /^\d+$/.test(value);
  }

  const { formField, values, errors, touched, getData, onChangeValue, setFieldValue } = formData;
  const { dinhMucNV1, dinhMucNV2, dinhMucNV3 } = formField;
  const { dinhMucNV1: dinhMucNV1V, dinhMucNV2: dinhMucNV2V, dinhMucNV3: dinhMucNV3V } = values;
  const { id } = useParams();
  const [selectGroup, setSelectGroup] = useState({
    value: "N1",
    label: "Nhóm 1",
  });
  const [task1, setTask1] = useState({});
  const [task2, setTask2] = useState({});
  const [task3, setTask3] = useState({});
  const [isPayload, setIsPayload] = useState({});

  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");

  useEffect(() => {}, [isPayload]);

  // if (id && containsEdit && (!getData || Object.keys(getData).length === 0)) {
  //   return <>Loading...</>;
  // }

  useEffect(() => {
    const getAll = async () => {
      const { data } = await SysService.getAll();

      let newTask1 = {};
      let newTask2 = {};
      let newTask3 = {};

      data?.forEach((item) => {
        switch (item?.key) {
          case TASK_ENUM.NV1 + "-" + selectGroup.value:
            newTask1 = item || {};
            break;
          case TASK_ENUM.NV2 + "-" + selectGroup.value:
            newTask2 = item || {};
            break;
          case TASK_ENUM.NV3:
            newTask3 = item || {};
            break;
          default:
            break;
        }
      });
      setTask1(newTask1);
      setTask2(newTask2);
      setTask3(newTask3);
    };

    getAll();
  }, [selectGroup]);

  useEffect(() => {
    setFieldValue(dinhMucNV1.name, task1?.value || 0);
    setFieldValue("idNV1", task1?._id || 0);
    setFieldValue(dinhMucNV2.name, task2?.value || 0);
    setFieldValue("idNV2", task2?._id);
    setFieldValue(dinhMucNV3.name, task3?.value || 0);
    setFieldValue("idNV3", task3?._id);
  }, [task1, task2, task3]);

  const handleBlur = (e) => {
    if (!isNumber(e.target.value)) return;
    // if (val === formatCurrency.format(value)) return;
    // setVal((prev) => formatCurrency.format(prev));
  };

  const handleFocus = (e) => {};

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          Chi Tiết
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <SoftBox paddingTop={"3px"} display="block">
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                mb={0.5}
                pl={0.8}
              >
                Nhóm
              </SoftTypography>
              <SoftSelect
                value={selectGroup}
                // value={selectGroup}
                onChange={(e) => setSelectGroup({ value: e.value, label: e.label })}
                options={[
                  {
                    value: "N1",
                    label: "Nhóm 1",
                  },
                  {
                    value: "N2",
                    label: "Nhóm 2",
                  },
                  {
                    value: "N3A",
                    label: "Nhóm 3-A",
                  },
                  {
                    value: "N3B",
                    label: "Nhóm 3-B",
                  },
                  {
                    value: "N4",
                    label: "Nhóm 4",
                  },
                ]}
              />
            </SoftBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={dinhMucNV1.type}
              label={dinhMucNV1.label}
              name={dinhMucNV1.name}
              value={dinhMucNV1V}
              error={errors.dinhMucNV1 && touched.dinhMucNV1}
              disabled={Object.keys(task1)?.length === 0}
              onBlur={(e) => handleBlur(e)}
              onFocus={(e) => handleFocus(e)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={dinhMucNV2.type}
              label={dinhMucNV2.label}
              name={dinhMucNV2.name}
              value={dinhMucNV2V}
              error={errors.dinhMucNV2 && touched.dinhMucNV2}
              disabled={Object.keys(task2)?.length === 0}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={dinhMucNV3.type}
              label={dinhMucNV3.label}
              name={dinhMucNV3.name}
              value={dinhMucNV3V}
              error={errors.dinhMucNV3 && touched.dinhMucNV3}
              disabled={Object.keys(task3)?.length === 0}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
ChiTietDiemDinhMuc.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChiTietDiemDinhMuc;
