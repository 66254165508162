/* eslint-disable react/prop-types */
import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { Grid } from "@mui/material";
import FileAttachment from "components/AttachmentFile";
import { useParams } from "react-router-dom";
import FormField from "../FormField";
import { formatCurrency } from "utils";

function ChiTietDiemCoBanCuoiNam({ formData }) {
  const { getData } = formData;
  const { id } = useParams();

  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");
  const containsCreate = currentPath.includes("create");

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          Chi tiết
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Họ và tên"
              name="name"
              value={getData?.user?.personalInformation?.fullName || ""}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Năm"
              name="year"
              value={getData?.year || ""}
              disabled={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Tiền thưởng đặt biệt nhóm"
              name="thuongDBNhom"
              value={formatCurrency.format(getData?.specicalGroupBonus) || "0"}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Tiền thưởng đặt biệt cá nhân"
              name="thuongDBCaNhan"
              value={formatCurrency.format(getData?.specicalPersonalBonus) || "0"}
              disabled={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormField
              type="text"
              label="Tiền thưởng xuất sắc"
              name={"thuongXuatSac"}
              value={formatCurrency.format(getData?.rewardBonus) || "0"}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type="text"
              label="Tiền thưởng cơ bản"
              name={"thuongCoban"}
              value={formatCurrency.format(getData?.basicBonus) || "0"}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type="text"
              label="Tổng thưởng cuối năm"
              name={"tongThuong"}
              value={formatCurrency.format(getData?.total) || "0"}
              disabled={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type="text"
              label="Ghi Chú"
              name="ghiChu"
              value={getData?.note || "--"}
              disabled={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FileAttachment
              id={id}
              containsEdit={containsEdit}
              containsCreate={containsCreate}
              file={getData.files || []}
              setFile={() => {}}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
ChiTietDiemCoBanCuoiNam.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChiTietDiemCoBanCuoiNam;
