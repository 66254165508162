/* eslint-disable react/prop-types */
import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { Grid } from "@mui/material";
import FileAttachment from "components/AttachmentFile";
import { useParams } from "react-router-dom";
import FormField from "../FormField";

function ChiTietDiemCoBanCuoiNam({ formData }) {
  const { getData } = formData;
  const { id } = useParams();

  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");
  const containsCreate = currentPath.includes("create");

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          Chi tiết
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Họ và tên"
              name="name"
              value={getData?.user?.personalInformation?.fullName || ""}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Năm"
              name="year"
              value={getData?.year || ""}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Giờ quản lý"
              name="managerScore"
              value={getData?.managerScore || "0"}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Giờ kỹ thuật"
              name="technicalScore"
              value={getData?.technicalScore || "0"}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type="text"
              label="Giờ nhiệm vụ 1"
              name="task1Score"
              value={new Intl.NumberFormat().format(Number(getData?.task1Score).toFixed(2)) || "0"}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type="text"
              label="Giờ nhiệm vụ 2"
              name="task2Score"
              value={new Intl.NumberFormat().format(Number(getData?.task2Score).toFixed(2)) || "0"}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type="text"
              label="Giờ nhiệm vụ 3"
              name="task3Score"
              value={new Intl.NumberFormat().format(Number(getData?.task3Score).toFixed(2)) || "0"}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type="text"
              label="Giờ kỷ luật"
              name="negativeScore"
              value={
                new Intl.NumberFormat().format(Number(getData?.negativeScore).toFixed(2)) || "0"
              }
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type="text"
              label="Giờ khen thưởng"
              name="rewardScore"
              value={new Intl.NumberFormat().format(Number(getData?.rewardScore).toFixed(2)) || "0"}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type="text"
              label="Tổng Giờ"
              name="totalScore"
              value={new Intl.NumberFormat().format(Number(getData?.totalScore).toFixed(2)) || "0"}
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FileAttachment
              id={id}
              containsEdit={containsEdit}
              containsCreate={containsCreate}
              file={getData.files || []}
              setFile={() => {}}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
ChiTietDiemCoBanCuoiNam.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChiTietDiemCoBanCuoiNam;
