import { useEffect, useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { useNavigate, useParams } from "react-router-dom";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import validations from "./schemas/validations";
// import { ImageService } from "services/imageService";
import { toast } from "react-toastify";
import Info from "./components/Info";
import { DiscipLineService } from "services/disciplineServices";
import { uploadFile } from "utils";
// import { MaintenanceService } from "services/maintenanceService";

function ChiTietViPham() {
  const [activeStep, setActiveStep] = useState(0);
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const { id } = useParams();
  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");
  const containsCreate = currentPath.includes("create");
  const navigate = useNavigate();
  const [getData, setGetData] = useState({});
  const [data, setData] = useState({
    hinh: "",
    namSinh: "",
  });

  useEffect(() => {
    if (!id) return;
    (async () => {
      const { data } = await DiscipLineService.getById(id);
      setGetData(data);
    })();
  }, [id]);

  const onChangeValue = (type, value) => {
    setData((prev) => ({ ...prev, [type]: value }));
  };

  const submitForm = async (values, actions) => {
    let attachmentFile;
    if (values.file.length > 0) {
      attachmentFile = await uploadFile(values.file);
    }
    try {
      await DiscipLineService.create({
        user: values.tenCBVC,
        disciplineLevel: values.mucViPham,
        time: values.ngayViPham,
        reason: "reason",
        note: values.ghiChu,
        files: attachmentFile,
      });
      toast.success("Tạo vi phạm thành công");
      actions.setSubmitting(false);
      actions.resetForm();
      setTimeout(() => {
        navigate(-1);
      }, 1500);
    } catch (error) {
      actions.setSubmitting(false);
      toast.error(error?.response?.data?.message || "Tạo vi phạm thất bại");
    }
  };

  const handleUpdate = async (values, actions) => {
    let attachmentFile;
    if (values.file.length > 0) {
      attachmentFile = await uploadFile(values.file);
    }
    const payload = {
      user: values.tenCBVC,
      disciplineLevel: values.mucViPham,
      time: values.ngayViPham,
      reason: "reason",
      note: values.ghiChu,
      files: attachmentFile ?? [],
    };
    try {
      await DiscipLineService.update(id, payload);
      toast.success("Cập nhật vi phạm thành công");
      navigate(-1);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Cập nhật vi phạm thất bại");
      console.log("🍕 ~ error:", error);
    }
    actions.setSubmitting(false);
  };

  const handleSubmit = (values, actions) => {
    if (id && containsEdit) {
      handleUpdate({ ...data, ...values }, actions);
    } else {
      submitForm({ ...data, ...values }, actions);
    }
  };

  const handleBack = () => navigate(-1);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <SoftBox p={2}>
                      <SoftBox>
                        <Info
                          formData={{
                            values,
                            touched,
                            formField,
                            errors,
                            getData,
                            onChangeValue,
                            setFieldValue,
                          }}
                        />
                        <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          <SoftButton onClick={handleBack} variant="gradient" color="light">
                            Trở về
                          </SoftButton>
                          {!id && (
                            <SoftButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              Tạo
                            </SoftButton>
                          )}
                          {id && containsEdit && (
                            <SoftButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              Cập nhật
                            </SoftButton>
                          )}
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default ChiTietViPham;
