import AxiosInstance from "./api";

export const NotificationService = {
  getForUser: async (payload) => {
    const query = new URLSearchParams(payload);
    return await AxiosInstance.get(`/user/activity-notification?${query.toString()}`);
  },
  getById: async (id) => {
    return await AxiosInstance.get(`/notification/${id}`);
  },
  read: async (id) => {
    return await AxiosInstance.patch(`/user/activity-notification/${id}`);
  },
};
