/* eslint-disable react/prop-types */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { ErrorMessage, Field } from "formik";
import { useEffect, useRef, useState } from "react";
import { formatNumber } from "utils";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

function FormField({ label, name, value, ...rest }) {
  function isNumber(value) {
    return /^\d+$/.test(value);
  }

  const [val, setVal] = useState(value);
  const firstRef = useRef(true);
  useEffect(() => {
    // if (rest.autoFormat) {
    //   setVal((prev) => new Intl.NumberFormat().format(Number(value).toFixed(2)));
    // } else {
    // console.log({ type: typeof value, value: value });
    const formattedValue =
      typeof value === "number" ? new Intl.NumberFormat().format(Number(value).toFixed(2)) : value;
    if (firstRef.current) setVal(formattedValue);
    else setVal(formattedValue.charAt(0) === "0" ? formattedValue.slice(1) : formattedValue);
    firstRef.current = false;
    // }
  }, [value]);

  const handleBlur = () => {
    if (!isNumber(value)) return;
    if (val === new Intl.NumberFormat().format(Number(value).toFixed(2))) return;
    setVal((prev) => new Intl.NumberFormat().format(Number(prev).toFixed(2)));
  };

  const handleFocus = () => {
    setVal(value);
  };

  return (
    <SoftBox mb={1.5}>
      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SoftTypography>
      </SoftBox>
      <Field
        {...rest}
        name={name}
        as={SoftInput}
        type="text"
        value={val}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      <SoftBox mt={0.75}>
        <SoftTypography component="div" variant="caption" color="error">
          <ErrorMessage name={name} />
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default FormField;
