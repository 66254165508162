const form = {
  formId: "new-user-form",
  formField: {
    kichThuocFile: {
      name: "kichThuocFile",
      label: "Giới hạn kích thước file (MB)",
      type: "number",
      placeholder: "vd: 3",
      errorMsg: "Giới hạn kích thước file không được bỏ trống.",
    },
  },
};

export default form;
