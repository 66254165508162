import checkout from "./form";

const {
  formField: {
    diemThuong,
    diemTru,
    ghiChu,
    gioToiDa,
    nhomHoatDong,
    loaiNhiemVu,
    maNhiemVu,
    ngayBatDau,
    ngayKetThuc,
    soLuongThamGia,
    tenHoatDong,
    chuTri,
    diemChuTri,
    hoTro,
    pho,
    chiTieu,
    diaDiem,
    diemThuongXuatXac,
  },
} = checkout;

const initialValues = {
  [diemThuong.name]: "",
  [diemTru.name]: "",
  [ghiChu.name]: "",
  [gioToiDa.name]: "",
  [nhomHoatDong.name]: "",
  [maNhiemVu.name]: "",
  [ngayBatDau.name]: "",
  [ngayKetThuc.name]: "",
  [soLuongThamGia.name]: "",
  [tenHoatDong.name]: "",
  [chuTri.name]: "",
  [diemChuTri.name]: "",
  [hoTro.name]: "",
  [pho.name]: "",
  [chiTieu.name]: "",
  [diaDiem.name]: "",
  [diemThuongXuatXac.name]: "",
};

export default initialValues;
