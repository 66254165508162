import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { quyThuongNam, nam },
} = checkout;

const validations = [
  Yup.object().shape({
    // [canBoVC.name]: Yup.string().required(canBoVC.errorMsg),
    // [diemNV1.name]: Yup.string().required(diemNV1.errorMsg),
    // [diemNV2.name]: Yup.string().required(diemNV2.errorMsg),
    // [diemNV3.name]: Yup.string().required(diemNV3.errorMsg),
    [quyThuongNam.name]: Yup.string().required(quyThuongNam.errorMsg),
    [nam.name]: Yup.string().required(nam.errorMsg),
    // [diemQuanLy.name]: Yup.string().required(diemQuanLy.errorMsg),
    // [ngayBatDau.name]: Yup.string().required(ngayBatDau.errorMsg),
    // [ngayKetThuc.name]: Yup.string().required(ngayKetThuc.errorMsg),
  }),
];

export default validations;
