import { useEffect, useState } from "react";

// formik components
import { Form, Formik } from "formik";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { useNavigate, useParams } from "react-router-dom";
import { UserRewardService } from "services/userRewardService";
import Info from "./components/Info";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import validations from "./schemas/validations";

function ChiTietDiemConBanCuoiNam() {
  const [activeStep, setActiveStep] = useState(0);
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const { id } = useParams();
  const navigate = useNavigate();
  const [getData, setGetData] = useState({});

  useEffect(() => {
    if (!id) return;
    (async () => {
      const { data } = await UserRewardService.getByIdBasicScore(id);
      setGetData(data);
    })();
  }, [id]);

  const handleBack = () => navigate(-1);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <Formik initialValues={initialValues} validationSchema={currentValidation}>
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <SoftBox p={2}>
                      <SoftBox>
                        <Info
                          formData={{
                            values,
                            touched,
                            formField,
                            errors,
                            getData,
                            setFieldValue,
                          }}
                        />
                        <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          <SoftButton onClick={handleBack} variant="gradient" color="light">
                            Trở về
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default ChiTietDiemConBanCuoiNam;
