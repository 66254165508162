/* eslint-disable react/prop-types */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// NewUser page components
import { Box, Button, Grid, Typography } from "@mui/material";
import FileAttachment from "components/AttachmentFile";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { formatDate } from "utils";
import FormField from "../FormField";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { truncateText } from "utils";
import DataTable from "../../data/index";
import FirstColumn from "examples/FirstColumn";
import ActionCell from "../ActionCell";
import { ParticipantService } from "services/participantService";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { UploadService } from "services/uploadService";
import SoftButton from "components/SoftButton";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { authSelector } from "redux/selector";
import DeleteIcon from "@mui/icons-material/Delete";
import SoftDatePicker from "components/SoftDatePicker";
import SoftInput from "components/SoftInput";
import TableCommon from "components/TableCommon";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ActivityParticipantsListStatusEnum = {
  UNCONFIRMED: "Chưa xác nhận",
  CONFIRMED: "Đã xác nhận",
  EVALUATED: "Đã đánh giá",
};
const dataTableData = {
  columns: [
    {
      Header: "Họ và Tên",
      accessor: "name",
      width: "10%",
      sorted: false,
      Cell: ({ value: [name, data] }) => (
        <Box display="flex" gap={1} alignItems="center">
          <Box width={40} height={40}>
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: 12 }}
              src={data.image}
              alt={name}
            />
          </Box>
          <FirstColumn value={name} />
        </Box>
      ),
    },
    {
      Header: "bộ môn",
      accessor: "bo-mon",
    },
    { Header: "Vị trí", accessor: "vi-tri", sorted: false, align: "center" },
    { Header: "điểm thưởng", accessor: "diem-thuong", sorted: false, align: "right" },
    { Header: "điểm trừ", accessor: "diem-tru", sorted: false, align: "right" },
    { Header: "Giờ tham gia", accessor: "gio-tham-gia", sorted: false, align: "right" },
    { Header: "xếp loại", accessor: "xep-loai", sorted: false, align: "center" },
    {
      Header: "Minh chứng",
      accessor: "mc",
      sorted: false,
      align: "center",
      Cell: ({ value }) => {
        return (
          <span
            style={{
              color: value === "Đã gửi" ? "#17C1E8" : "",
            }}
          >
            {value}
          </span>
        );
      },
    },
    {
      Header: "Trạng thái duyệt",
      accessor: "status",
      sorted: false,
      align: "center",
      Cell: ({ value }) => {
        return (
          <span
            style={{
              color:
                value === ActivityParticipantsListStatusEnum.CONFIRMED
                  ? "#00CC00"
                  : value === ActivityParticipantsListStatusEnum.EVALUATED
                  ? "#17C1E8"
                  : "",
            }}
          >
            {value}
          </span>
        );
      },
    },
    { Header: "thao tác", accessor: "action", sorted: false, align: "center" },
  ],
  rows: [],
};

function ChiTietHoatDong({ formData }) {
  const {
    formField,
    values,
    errors,
    touched,
    getData,
    onChangeValue,
    setFieldValue,
    onToggleRefetch,
  } = formData;
  const {
    diemThuong,
    diemTru,
    ghiChu,
    gioToiDa,
    nhomHoatDong,
    loaiNhiemVu,
    maNhiemVu,
    ngayBatDau,
    ngayKetThuc,
    soLuongThamGia,
    tenHoatDong,
    chuTri,
    hoTro,
    pho,
    chiTieu,
    diaDiem,
    diemThuongXuatXac,
  } = formField;
  const {
    diemThuong: diemThuongV,
    gioToiDa: gioToiDaV,
    loaiNhiemVu: loaiNhiemVuV,
    ngayBatDau: ngayBatDauV,
    ngayKetThuc: ngayKetThucV,
    nhomHoatDong: nhomHoatDongV,
    soLuongThamGia: soLuongThamGiaV,
    tenHoatDong: tenHoatDongV,
    ghiChu: ghiChuV,
    diemTru: diemTruV,
    chuTri: chuTriV,
    diemChuTri: diemChuTriV,
    diemHoTro: diemHoTroV,
    diemPho: diemPhoV,
    diemThamGia: diemThamGiaV,
    hoTro: hoTroV,
    pho: phoV,
    chiTieu: chiTieuV,
    diaDiem: diaDiemV,
    diemThuongXuatXac: diemThuongXuatXacV,
  } = values;
  const { id } = useParams();
  const [files, setFiles] = useState();
  const [isPayload, setIsPayload] = useState({
    chuTri: [],
    pho: [],
    hoTro: [],
  });
  const [value, setValue] = useState(0);
  const [tableData, setTableData] = useState(dataTableData);
  const [query, setQuery] = useState({ department: "" });
  const [proofList, setProofList] = useState([]);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const { user } = useSelector(authSelector);
  const [proofTime, setProofTime] = useState(0);
  const [paginationData, setPaginationData] = useState({ page: 1 });
  const [loadingPaginate, setLoadingPaginate] = useState(false);

  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");
  const containsCreate = currentPath.includes("create");
  const containsConfirm = currentPath.includes("confirm");

  const isDeputy =
    getData?.deputy &&
    getData?.deputy.length > 0 &&
    getData.deputy.find((item) => item._id === user._id)?._id;
  const isHost =
    getData?.host &&
    getData?.host.length > 0 &&
    getData.host.find((item) => item._id === user._id)?._id;
  const isSupporter =
    getData?.supporter &&
    getData?.supporter.length > 0 &&
    getData.supporter.find((item) => item._id === user._id)?._id;
  const isParticipant =
    getData?.activityparticipantslists &&
    getData?.activityparticipantslists.length > 0 &&
    getData.activityparticipantslists.find((item) => item?.user._id === user._id)?._id;

  const isJoined = isDeputy || isHost || isSupporter || isParticipant;

  const handleChangeFiles = async (files) => {
    setProofList((prev) => [...prev, ...Array.from(files)]);
  };

  const handleRemoveFile = async (file) => {
    const urls = proofList.filter((item) =>
      file?.name && item.name ? item.name !== file.name : item !== file
    );
    setProofList(urls);
  };

  const handleUpdate = async () => {
    try {
      setLoadingUpdate(true);
      const result =
        getData.activityparticipantslists &&
        getData.activityparticipantslists.length &&
        getData.activityparticipantslists.find((item) => item.user._id == user._id);
      if (!result.process && !proofTime) {
        toast.warn("Vui lý nhập số giờ tham gia");
        setLoadingUpdate(false);
        return;
      }

      const urls = await UploadService.uploadNoPreview(proofList);
      await ParticipantService.userUpdate(result._id, {
        files: urls,
        process: proofTime,
      });
      onToggleRefetch();
      setLoadingUpdate(false);
      toast.success("Tải file thành công");
    } catch (error) {
      setLoadingUpdate(false);
      toast.error(error?.response?.data?.message || "Tải file thất bại");
      console.log({ error });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setFieldValue(chuTri.name, isPayload?.chuTri);
    setFieldValue(pho.name, isPayload?.pho);
    setFieldValue(hoTro.name, isPayload?.hoTro);
    setFieldValue(gioToiDa.name, isPayload?.gioToiDa);
    setFieldValue(nhomHoatDong.name, isPayload?.nhomHoatDong);
  }, [isPayload]);

  useEffect(() => {
    onChangeValue("file", files || []);
    // console.log(getData?.task, "id");
    if (Object.keys(getData).length > 0) {
      setFieldValue(tenHoatDong.name, getData?.name || "--");
      setFieldValue(chiTieu.name, getData?.targets || "--");
      setFieldValue(diaDiem.name, getData?.places || "--");
      setFieldValue(diemThuong.name, getData?.rewardPoint);
      setFieldValue(diemTru.name, getData?.deductionPoint);
      setFieldValue(diemThuongXuatXac.name, getData?.maxExcellentPoint || "--");
      setFieldValue(soLuongThamGia.name, getData?.numberOfParticipants || "--");
      setFieldValue(ngayBatDau.name, getData?.startTime || "--");
      setFieldValue(ngayKetThuc.name, getData?.endTime || "--");
      setFieldValue(diemThuongXuatXac.name, getData?.maxExcellentPoint || "--");
      setFieldValue(diemThuongXuatXac.name, getData?.maxExcellentPoint || "--");
      setFieldValue(ghiChu.name, getData?.note || "--");
      setFieldValue(gioToiDa.name, getData?.maxHours || "--");
      const selectedHostValues = getData.host.map((option) => option.value);
      setIsPayload((prevState) => ({
        ...prevState,
        chuTri: selectedHostValues,
      }));
      const result =
        getData.activityparticipantslists &&
        getData.activityparticipantslists.length &&
        getData.activityparticipantslists.find((item) => item.user._id == user._id);
      setProofList(result?.files || []);
      setProofTime(result?.process || 0);
    }
  }, [getData, files]);

  useEffect(() => {
    if (!getData || !getData._id) return;
    (async () => {
      try {
        setLoadingPaginate(true);
        const {
          data: { items, paginate, role },
        } = await ParticipantService.privateGetAll({
          ...query,
          activity: getData._id,
          limit: paginationData.pageSize || 10,
          page: paginationData.page,
        });
        setPaginationData((prev) => ({ ...prev, count: paginate.count, size: paginate.size }));
        const newRows = items?.map((e) => {
          return {
            name: [
              e?.user?.personalInformation?.fullName,
              { image: e?.user?.personalInformation?.avatar || "/logo-dhbk.png", checked: true },
            ],
            "bo-mon": e?.user?.department?.name || "--",
            "vi-tri": e?.user?.personalInformation?.jobPosition || "--",
            "diem-thuong": e?.rewardPoint || "--",
            "diem-tru": e?.minusPoint || "--",
            "xep-loai": e?.ranking || "--",
            "gio-tham-gia": e?.process || "--",
            mc: e?.files?.length > 0 ? "Đã gửi" : "Chưa gửi",
            status: e?.status || "--",
            action: <ActionCell role={role} activityId={id} item={e} />,
          };
        });
        setTableData((prev) => ({ ...prev, rows: newRows }));
        setLoadingPaginate(false);
      } catch (error) {
        setLoadingPaginate(false);
        console.log(error);
      }
    })();
  }, [getData, query, paginationData.page]);

  // useEffect(() => {
  //   proofList.map((item) => {
  //     if (item === Object) {
  //       console.log(item, "file");
  //     } else {
  //       console.log(item, "string");
  //     }
  //     console.log(typeof item)
  //   });
  // }, [proofList]);

  return (
    <div>
      {(containsEdit || containsCreate) && (
        <SoftBox lineHeight={0}>
          <SoftTypography variant="h5" fontWeight="bold">
            {id && containsEdit ? "Cập nhật" : id ? "Chi tiết" : "Tạo mới"}
          </SoftTypography>
        </SoftBox>
      )}
      <Box sx={{ width: "100%" }}>
        {id && !containsEdit && (
          <Box>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Chi tiết" {...a11yProps(0)} />
              <Tab label="Danh sách tham gia" {...a11yProps(1)} />
            </Tabs>
          </Box>
        )}
        <CustomTabPanel value={value} index={0}>
          <SoftBox mt={1.625}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type={tenHoatDong.type}
                  label={tenHoatDong.label}
                  name={tenHoatDong.name}
                  value={tenHoatDongV}
                  error={errors.tenHoatDong && touched.tenHoatDong}
                  disabled={id && !containsEdit}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={chiTieu.type}
                  label={chiTieu.label}
                  name={chiTieu.name}
                  value={chiTieuV}
                  error={errors.chiTieu && touched.chiTieu}
                  disabled={id && !containsEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={diaDiem.type}
                  label={diaDiem.label}
                  name={diaDiem.name}
                  value={diaDiemV}
                  error={errors.diaDiem && touched.diaDiem}
                  disabled={id && !containsEdit}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={diemThuong.type}
                  label={diemThuong.label}
                  name={diemThuong.name}
                  value={diemThuongV}
                  error={errors.diemThuong && touched.diemThuong}
                  disabled={id && !containsEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={diemTru.type}
                  label={diemTru.label}
                  name={diemTru.name}
                  value={diemTruV}
                  error={errors.diemTru && touched.diemTru}
                  disabled={id && !containsEdit}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type={diemThuongXuatXac.type}
                  label={diemThuongXuatXac.label}
                  name={diemThuongXuatXac.name}
                  value={diemThuongXuatXacV}
                  error={errors.diemThuongXuatXac && touched.diemThuongXuatXac}
                  disabled={id && !containsEdit}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={chuTri.type}
                  label={chuTri.label}
                  name={chuTri.name}
                  value={
                    getData?.host?.length >= 4
                      ? truncateText(
                          getData?.host?.map((e) => e?.personalInformation?.fullName).toString(),
                          40
                        )
                      : getData?.host?.map((e) => e?.personalInformation?.fullName)?.join(", ")
                  }
                  error={errors.chuTri && touched.chuTri}
                  disabled={id && !containsEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={pho.type}
                  label={pho.label}
                  name={pho.name}
                  value={
                    getData?.deputy?.length >= 4
                      ? truncateText(
                          getData?.deputy?.map((e) => e?.personalInformation?.fullName).toString(),
                          40
                        )
                      : getData?.deputy?.map((e) => e?.personalInformation?.fullName)
                  }
                  error={errors.pho && touched.pho}
                  disabled={id && !containsEdit}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={hoTro.type}
                  label={hoTro.label}
                  name={hoTro.name}
                  value={
                    getData?.supporter?.length >= 4
                      ? truncateText(
                          getData?.supporter
                            ?.map((e) => e?.personalInformation?.fullName)
                            .toString(),
                          40
                        )
                      : getData?.supporter?.map((e) => e?.personalInformation?.fullName)
                  }
                  error={errors.hoTro && touched.hoTro}
                  disabled={id && !containsEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={gioToiDa.type}
                  label={gioToiDa.label}
                  name={gioToiDa.name}
                  value={gioToiDaV}
                  error={errors.gioToiDa && touched.gioToiDa}
                  disabled={id && !containsEdit}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={soLuongThamGia.type}
                  label={soLuongThamGia.label}
                  name={soLuongThamGia.name}
                  value={soLuongThamGiaV}
                  error={errors.soLuongThamGia && touched.soLuongThamGia}
                  disabled={id && !containsEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={hoTro.type}
                  label={hoTro.label}
                  name={hoTro.name}
                  value={getData?.activityGroup?.name}
                  error={errors.hoTro && touched.hoTro}
                  disabled={id && !containsEdit}
                />
              </Grid>
            </Grid>

            {id && !containsEdit && (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormField
                    type={soLuongThamGia.type}
                    label={"Số lượng người Đã tham gia"}
                    name={soLuongThamGia.name}
                    value={getData?.activityparticipantslists?.length || 0}
                    error={errors.soLuongThamGia && touched.soLuongThamGia}
                    disabled={id && !containsEdit}
                  />
                </Grid>
              </Grid>
            )}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={"text"}
                  label={"Ngày Bắt Đầu"}
                  name={"dob"}
                  value={formatDate(getData?.startTime)}
                  disabled={id && !containsEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={"text"}
                  label={"Ngày Kết Thúc"}
                  name={"dob"}
                  value={formatDate(getData?.endTime)}
                  disabled={id && !containsEdit}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type={ghiChu.type}
                  label={ghiChu.label}
                  name={ghiChu.name}
                  value={ghiChuV}
                  error={errors.ghiChu && touched.ghiChu}
                  disabled={id && !containsEdit}
                />
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FileAttachment
                  id={id}
                  containsEdit={containsEdit}
                  containsCreate={containsCreate}
                  containsConfirm={containsConfirm}
                  file={getData.files || []}
                  setFile={setFiles}
                  showAll={false}
                />
              </Grid>
            </Grid>
          </SoftBox>
          {isJoined && new Date(getData?.deadLineToPost).getTime() > Date.now() && (
            <SoftBox className="mt-4">
              <hr className="my-3" />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <input
                    id="files-upload"
                    name="files-upload"
                    type="file"
                    multiple={true}
                    className="hidden"
                    onChange={(e) => handleChangeFiles(e.target.files)}
                  />
                  <div className="flex flex-col mb-2 ">
                    <div className="text-sm font-medium">
                      Số giờ tham gia {getData?.maxHours && `(Tối đa: ${getData?.maxHours})`}
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="w-fit">
                        <SoftInput
                          value={proofTime}
                          disabled={loadingUpdate}
                          onChange={(e) => {
                            if (e.target.value === "") {
                              setProofTime("");
                              return;
                            }
                            const regexNumberInteger = /^[0-9\b]+$/;
                            if (
                              +e.target.value > getData?.maxHours ||
                              !regexNumberInteger.test(e.target.value)
                            )
                              return;
                            setProofTime(+e.target.value);
                          }}
                        />
                      </div>
                      <SoftButton
                        variant="gradient"
                        color="dark"
                        className="w-fit"
                        onClick={handleUpdate}
                        disabled={loadingUpdate}
                      >
                        {loadingUpdate ? "Đang cập nhật..." : "Cập nhật"}
                      </SoftButton>
                    </div>
                  </div>

                  <label htmlFor="files-upload" className="cursor-pointer w-fit block">
                    <span className="flex gap-1 px-2 py-1 w-fit border border-gray-300 rounded-lg items-center">
                      <CloudUploadIcon /> <span style={{ fontSize: 14 }}>Tải minh chứng</span>
                    </span>
                  </label>
                  <div className="mt-4 flex flex-col gap-1">
                    {proofList &&
                      proofList.length > 0 &&
                      proofList.map((file, index) => {
                        if (typeof file === "object") {
                          {
                            return (
                              <div
                                key={index}
                                className="flex items-center gap-1"
                                style={{ fontSize: "12px" }}
                              >
                                <span>{file.name || file.split("/")?.[4]}</span>
                                <span
                                  className="cursor-pointer"
                                  onClick={() => handleRemoveFile(file)}
                                >
                                  <DeleteIcon color="error" />
                                </span>
                              </div>
                            );
                          }
                        } else {
                          {
                            return (
                              <div
                                key={index}
                                className="flex items-center gap-1"
                                style={{ fontSize: "12px" }}
                              >
                                <a
                                  className="cursor-pointer"
                                  href={file}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {file || file.split("/")?.[4]}
                                </a>
                                <span
                                  className="cursor-pointer"
                                  onClick={() => handleRemoveFile(file)}
                                >
                                  <DeleteIcon color="error" />
                                </span>
                              </div>
                            );
                          }
                        }
                      })}
                  </div>
                </Grid>
              </Grid>
            </SoftBox>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {getData.activityparticipantslists && getData.activityparticipantslists.length > 0 ? (
            <DataTable
              loading={loadingPaginate}
              query={query}
              setQuery={setQuery}
              table={tableData}
              paginationData={paginationData}
              setPaginationData={setPaginationData}
              canSearch
            />
          ) : (
            <div className="flex items-center justify-center min-h-[200px]">
              Không có người nào tham gia
            </div>
          )}
        </CustomTabPanel>
      </Box>
    </div>
  );
}

// typechecking props for UserInfo
ChiTietHoatDong.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChiTietHoatDong;
