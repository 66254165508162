/* eslint-disable react/prop-types */
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import avatar from "assets/images/team-2.jpg";
// Data
import DataTable from "./data/index";
import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import ActionCell from "./components/ActionCell";
import { formatDate } from "utils";
import { BookingService } from "services/bookingService";
import SoftBadge from "components/SoftBadge";
import dataTableData from "./data/dataTableData";
import FirstColumn from "examples/FirstColumn";
import { BasicsCoreService } from "services/basicsCoreServices";
import { FundService } from "services/fundServices";

function QuyKhenThuong() {
  const [dataTable, setDataTable] = useState({
    columns: [
      // {
      //   Header: "Quỹ thưởng đặt biệt",
      //   accessor: "qtdb",
      //   width: "15%",
      //   sorted: false,
      //   align: "right",
      // },
      // {
      //   Header: "Thưởng cơ bản cuối năm",
      //   accessor: "tcbcn",
      //   width: "15%",
      //   align: "right",
      // },
      // {
      //   Header: "Thưởng xuất sắc cuối năm",
      //   accessor: "txscn",
      //   align: "right",
      // },
      {
        Header: "Tổng",
        accessor: "tong",
        sorted: true,
        width: "55%",
        align: "center",
      },
      { Header: "Năm", accessor: "nam", sorted: true, align: "center" },
      { Header: "thao tác", accessor: "action", sorted: false, align: "center" },
    ],
    rows: [],
  });
  const [query, setQuery] = useState({
    sortBy: "createdAt",
    sortType: "desc",
    year: new Date().getFullYear(),
  });
  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({ page: 1 });
  const [isData, setIsData] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(true);

      const {
        data: { items, paginate },
      } = await FundService.getAll({
        limit: paginationData.pageSize || 10,
        page: paginationData.page,
        ...query,
      });

      setPaginationData((prev) => ({ ...prev, count: paginate.count, size: paginate.size }));
      setIsData(items);
      setDataTable((prev) => ({
        ...prev,
        rows: items.map((item) => {
          return {
            id: item?._id,
            // qtdb: new Intl.NumberFormat().format(Number(item?.specialRewardFund).toFixed(2)),
            // tcbcn: new Intl.NumberFormat().format(Number(item?.basicYearEndBonusFund).toFixed(2)),
            // txscn: new Intl.NumberFormat().format(
            //   Number(item?.yearEndExcellentRewardFund).toFixed(2)
            // ),
            tong: new Intl.NumberFormat().format(Number(item?.amount).toFixed(2)) || "--",
            // tong: new Intl.NumberFormat().format(Number(item?.totalScore).toFixed(2)) || "--",
            nam: item?.year,
            action: <ActionCell item={item} setDataTable={setDataTable} />,
          };
        }),
      }));
      setLoading(false);
    })();
  }, [query, paginationData.page]);

  const getColorStatus = (status) => {
    switch (status) {
      case "Đã huỷ":
        return "error";
      case "Chưa điều phối":
        return "secondary";
      case "Đã điều phối":
        return "info";
      case "Đã hoàn thành":
        return "success";
    }
    // "primary",
    // "secondary",
    // "info",
    // "success",
    // "warning",
    // "error",
    // "light",
    // "dark",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <DataTable
            loading={loading}
            query={query}
            setQuery={setQuery}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            table={dataTable}
            canSearch
          />
          {Object.keys(isData).length === 0 && !loading && (
            <SoftBox my={7}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                Không có dữ liệu
              </Grid>
            </SoftBox>
          )}
          {Object.keys(dataTable.rows)?.length <= 2 && <SoftBox my={5}></SoftBox>}
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

export default QuyKhenThuong;
