const form = {
  formId: "new-user-form",
  formField: {
    hoTen: {
      name: "hoTen",
      label: "Họ và Tên",
      type: "text",
      placeholder: "Vd: Nguyen Van A",
      errorMsg: "Họ và Tên không được bỏ trống.",
    },
    cccd: {
      name: "cccd",
      label: "Căn Cước Công Dân",
      type: "text",
      placeholder: "Xe abcxyz",
      errorMsg: "Căn cước công dân không được bỏ trống.",
    },
    luongCoBan: {
      name: "luongCoBan",
      label: "Lương Cơ Bản",
      type: "number",
      placeholder: "",
      errorMsg: "First name is required.",
    },
    namSinh: {
      name: "namSinh",
      label: "Năm Sinh",
      type: "number",
      placeholder: "Xe abcxyz",
      errorMsg: "Năm sinh không được bỏ trống.",
    },
    queQuan: {
      name: "queQuan",
      label: "Quê Quán",
      type: "text",
      placeholder: "Xe abcxyz",
      errorMsg: "Quê quán không được bỏ trống.",
    },
    sdt: {
      name: "sdt",
      label: "Số điện thoại",
      type: "text",
      placeholder: "Xe abcxyz",
      errorMsg: "Số điện thoại không được bỏ trống.",
    },
    email: {
      name: "email",
      label: "Email",
      type: "text",
      placeholder: "Xe abcxyz",
      errorMsg: "Email không được bỏ trống.",
    },
    diaChiLienHe: {
      name: "diaChiLienHe",
      label: "Địa chỉ liên hệ",
      type: "text",
      placeholder: "",
      errorMsg: "Địa chỉ liên hệ không được bỏ trống.",
    },
    ngayBatDauLamViec: {
      name: "ngayBatDauLamViec",
      label: "Ngày Bắt Đầu Làm Việc",
      type: "text",
      placeholder: "",
      errorMsg: "Ngày bắt đầu làm việc không được bỏ trống.",
    },
    boMon: {
      name: "boMon",
      label: "Bộ Môn",
      type: "text",
      placeholder: "",
      errorMsg: "Bộ môn không được bỏ trống.",
    },
    viTri: {
      name: "viTri",
      label: "Vị Trí",
      type: "text",
      placeholder: "",
      errorMsg: "Vị trí không được bỏ trống.",
    },
    hocVi: {
      name: "hocVi",
      label: "Học Vị",
      type: "text",
      placeholder: "",
      errorMsg: "Học vị không được bỏ trống.",
    },
    ngachVienChuc: {
      name: "ngachVienChuc",
      label: "Ngạch Viên Chức",
      type: "text",
      placeholder: "",
      errorMsg: "Ngạch viên chức không được bỏ trống.",
    },
    gioiTinh: {
      name: "gioiTinh",
      label: "Giới Tính",
      type: "text",
      placeholder: "",
      errorMsg: "Giới tính không được bỏ trống.",
    },
    maCanBo: {
      name: "maCanBo",
      label: "Mã Cán Bộ",
      type: "text",
      placeholder: "",
      errorMsg: "Mã cán bộ không được bỏ trống.",
    },
    avatar: {
      name: "avatar",
      label: "Hình",
      type: "text",
      placeholder: "",
    },
  },
};

export default form;
