/* eslint-disable react/prop-types */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// NewUser page components
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { ErrorMessage, Field } from "formik";
import FormField from "../FormField";
import SoftDatePicker from "components/SoftDatePicker";
import { formatDate } from "utils";
import avatar from "assets/images/avatar.jpg";
import FileAttachment from "components/AttachmentFile";
import { DiscipLineService } from "services/disciplineServices";
import { OfficialsService } from "services/officialsService";
import moment from "moment";

function ChiTietViPham({ formData }) {
  const { formField, values, errors, touched, getData, onChangeValue, setFieldValue } = formData;
  const { tenCBVC, mucViPham, soLanViPham, ngayViPham, ngayKetThuc, ghiChu } = formField;
  const {
    tenCBVC: tenCBVCV,
    soLanViPham: soLanViPhamV,
    ngayViPham: ngayViPhamV,
    ngayKetThuc: ngayKetThucV,
    ghiChu: ghiChuV,
    mucViPham: mucViPhamV,
  } = values;
  const { id } = useParams();
  const [files, setFiles] = useState({
    hinh: {},
    preview: avatar,
  });
  const [dob, setDob] = useState();
  const [isPayload, setIsPayload] = useState({});
  const [optionCBVC, setOptionCBVC] = useState([]);
  const [optionMVP, setoptionMVP] = useState([]);

  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");
  const containsCreate = currentPath.includes("create");

  useEffect(() => {
    (async () => {
      const option = [];
      const {
        data: { items },
      } = await OfficialsService.getPaginate({ limit: 1000 });
      items.map((item) => {
        option.push({
          value: item?._id,
          label: `${item?.personalInformation?.fullName} - ${item?.personalInformation?.officerId}`,
        });
      });
      setOptionCBVC(option);
    })();

    (async () => {
      const option = [];
      const {
        data: { items },
      } = await DiscipLineService.getAllLv({ limit: 1000 });
      items.map((item) => {
        option.push({
          value: item?._id,
          label: item?.name,
        });
      });
      setoptionMVP(option);
    })();
  }, []);

  useEffect(() => {
    if (Object.keys(getData).length > 0) {
      setIsPayload({
        ...isPayload,
        cbvc: {
          value: getData?.user._id,
          label:
            `${getData?.user?.personalInformation?.fullName} - ${getData?.user?.personalInformation?.officerId}` ||
            "--",
        },
        mucViPham: {
          value: getData?.disciplineLevel._id,
          label: getData?.disciplineLevel?.name || "--",
        },
      });

      setFieldValue(tenCBVC.name, getData?.user?._id || "--");
      setFieldValue(mucViPham.name, getData?.disciplineLevel?._id || "--");
      setFieldValue(ngayViPham.name, getData?.time);
      setFieldValue("file", getData?.files || "--");
      setFieldValue(ghiChu.name, getData?.note || "--");

      setDob(new Date(Number(getData?.time)));
    }
  }, [getData]);

  useEffect(() => {
    setFieldValue("file", files || []);
  }, [files]);

  const handleSetDob = (newDate) => {
    const time = new Date(newDate[0]).getTime();
    setDob(newDate);
    setFieldValue(ngayViPham.name, time);
  };

  // if (id && containsEdit && (!getData || Object.keys(getData).length === 0)) {
  //   return <>Loading...</>;
  // }

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          {id && containsEdit ? "Cập nhật" : id ? "Chi tiết" : "Tạo mới"}
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            {id && !containsEdit ? (
              <FormField
                type={tenCBVC.type}
                label={tenCBVC.label}
                name={tenCBVC.name}
                value={isPayload?.cbvc?.label}
                error={errors.tenCBVC && touched.tenCBVC}
                disabled={id && !containsEdit}
              />
            ) : (
              <>
                <SoftBox display="flex" flexDirection="column">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    mb={0.9}
                    mt={1.7}
                  >
                    {tenCBVC.label}
                  </SoftTypography>
                  <SoftSelect
                    // isLoading={loading}
                    // onMenuOpen={handleFetchDrivers}
                    onChange={(e) => {
                      setIsPayload((prev) => ({ ...prev, cbvc: e }));
                      setFieldValue(tenCBVC.name, e.value);
                    }}
                    value={isPayload.cbvc}
                    options={optionCBVC}
                  />
                </SoftBox>
                <SoftBox mt={0.75}>
                  <SoftTypography component="div" variant="caption" color="error">
                    <ErrorMessage name={tenCBVC.name} />
                  </SoftTypography>
                </SoftBox>
              </>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            {id && !containsEdit ? (
              <FormField
                type={mucViPham.type}
                label={mucViPham.label}
                name={mucViPham.name}
                value={isPayload?.mucViPham?.label}
                error={errors.mucViPham && touched.mucViPham}
                disabled={id && !containsEdit}
              />
            ) : (
              <>
                <SoftBox display="flex" flexDirection="column">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    mb={0.9}
                    mt={1.7}
                  >
                    {mucViPham.label}
                  </SoftTypography>
                  <SoftSelect
                    // isLoading={loading}
                    // onMenuOpen={handleFetchDrivers}
                    onChange={(e) => {
                      setIsPayload((prev) => ({ ...prev, mucViPham: e }));
                      setFieldValue(mucViPham.name, e.value);
                    }}
                    value={isPayload.mucViPham}
                    options={optionMVP}
                  />
                </SoftBox>
                <SoftBox mt={0.75}>
                  <SoftTypography component="div" variant="caption" color="error">
                    <ErrorMessage name={mucViPham.name} />
                  </SoftTypography>
                </SoftBox>
              </>
            )}
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <FormField
              type={soLanViPham.type}
              label={soLanViPham.label}
              name={soLanViPham.name}
              value={soLanViPhamV}
              error={errors.soLanViPham && touched.soLanViPham}
              disabled={id && !containsEdit}
            />
          </Grid> */}
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {!containsEdit && id ? (
              <FormField
                type={"text"}
                label={ngayViPham.label}
                name={"dob"}
                value={moment(ngayViPhamV).format("DD/MM/YYYY")}
                disabled={id && !containsEdit}
              />
            ) : (
              <SoftBox display="flex" flexDirection="column">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  mb={0.5}
                  mt={2.2}
                >
                  {ngayViPham.label}
                </SoftTypography>
                <SoftDatePicker value={dob} onChange={(e) => handleSetDob(e, ngayViPham.name)} />
                <SoftBox mt={0.75}>
                  <SoftTypography component="div" variant="caption" color="error">
                    <ErrorMessage name={ngayViPham.name} />
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={ghiChu.type}
              label={ghiChu.label}
              name={ghiChu.name}
              value={ghiChuV}
              error={errors.ghiChu && touched.ghiChu}
              disabled={id && !containsEdit}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            {!containsEdit && id ? (
              <FormField
                type={"text"}
                label={"Ngày Kết Thúc"}
                name={"dob"}
                value={ngayKetThucV}
                disabled={id && !containsEdit}
              />
            ) : (
              <SoftBox display="flex" flexDirection="column">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  mb={0.5}
                  mt={2.2}
                >
                  Ngày Kết Thúc
                </SoftTypography>
                <SoftDatePicker
                  // value={}
                  onChange={(e) => handleSetDob(e, ngayKetThuc.name)}
                />
                <SoftBox mt={0.75}>
                  <SoftTypography component="div" variant="caption" color="error">
                    <ErrorMessage name={ngayKetThuc.name} />
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            )}
          </Grid> */}
        </Grid>

        {/* <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={ghiChu.type}
              label={ghiChu.label}
              name={ghiChu.name}
              value={ghiChuV}
              error={errors.ghiChu && touched.ghiChu}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid> */}

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FileAttachment
              id={id}
              containsEdit={containsEdit}
              containsCreate={containsCreate}
              file={getData.files || []}
              setFile={setFiles}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
ChiTietViPham.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChiTietViPham;
