/* eslint-disable react/prop-types */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// NewUser page components
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { ErrorMessage, Field } from "formik";
import FormField from "../FormField";
import SoftDatePicker from "components/SoftDatePicker";
import { formatDate } from "utils";
import avatar from "assets/images/avatar.jpg";
import FileAttachment from "components/AttachmentFile";

function ChiTietKyLuat({ formData }) {
  const { formField, values, errors, touched, getData, onChangeValue, setFieldValue } = formData;
  const { ghiChu, lan1, lan2, lan3, lan4, mucViPham } = formField;
  const {
    ghiChu: ghiChuV,
    lan1: lan1V,
    lan2: lan2V,
    lan3: lan3V,
    lan4: lan4V,
    mucViPham: mucViPhamV,
  } = values;
  const { id } = useParams();
  const [files, setFiles] = useState();
  const [isPayload, setIsPayload] = useState({});

  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");
  const containsCreate = currentPath.includes("create");
  const containsConfirm = currentPath.includes("confirm");

  useEffect(() => {
    if (Object.keys(getData).length > 0) {
      setFieldValue(mucViPham.name, getData?.name || "--");
      setFieldValue(lan1.name, getData?.violation_1 || 0);
      setFieldValue(lan2.name, getData?.violation_2 || 0);
      setFieldValue(lan3.name, getData?.violation_3 || 0);
      setFieldValue(lan4.name, getData?.violation_4 || 0);
      setFieldValue(ghiChu.name, getData?.note || "--");
      setFieldValue("file", getData?.files || "--");
    }
  }, [getData]);

  useEffect(() => {
    setFieldValue("file", files || []);
  }, [files]);

  const handleChangeFile = (event) => {
    const { name } = event.target;
    const file = event.target.files[0];
    Object.assign(file, { preview: URL.createObjectURL(file) });
    if (file && Object.keys(file).length > 0) {
      setFiles((prev) => ({ ...prev, [name]: file }));
      onChangeValue(name, file);
    }
  };

  // if (id && containsEdit && (!getData || Object.keys(getData).length === 0)) {
  //   return <>Loading...</>;
  // }

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          {id && containsEdit ? "Cập nhật" : id ? "Chi tiết" : "Tạo mới"}
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={mucViPham.type}
              label={mucViPham.label}
              name={mucViPham.name}
              value={mucViPhamV}
              error={errors.mucViPham && touched.mucViPham}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={lan1.type}
              label={lan1.label}
              name={lan1.name}
              value={lan1V}
              error={errors.lan1 && touched.lan1}
              disabled={id && !containsEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={lan2.type}
              label={lan2.label}
              name={lan2.name}
              value={lan2V}
              error={errors.lan2 && touched.lan2}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={lan3.type}
              label={lan3.label}
              name={lan3.name}
              value={lan3V}
              error={errors.lan3 && touched.lan3}
              disabled={id && !containsEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={lan4.type}
              label={lan4.label}
              name={lan4.name}
              value={lan4V}
              error={errors.lan4 && touched.lan4}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={ghiChu.type}
              label={ghiChu.label}
              name={ghiChu.name}
              value={ghiChuV}
              error={errors.ghiChu && touched.ghiChu}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FileAttachment
              id={id}
              containsEdit={containsEdit}
              containsCreate={containsCreate}
              containsConfirm={containsConfirm}
              file={getData.files || []}
              setFile={setFiles}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
ChiTietKyLuat.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChiTietKyLuat;
