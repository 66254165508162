/* eslint-disable react/prop-types */
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import avatar from "assets/images/team-2.jpg";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Data
import { Box } from "@mui/material";
import FirstColumn from "examples/FirstColumn";
import { useEffect, useState } from "react";
import { UserRewardService } from "services/userRewardService";
import ActionCell from "./components/ActionCell";
import DataTable from "./data/index";
import { formatCurrency } from "utils";

function TienThuongCoBan() {
  const [dataTable, setDataTable] = useState({
    columns: [
      {
        Header: "Họ và Tên",
        accessor: "name",
        width: "10%",
        sorted: true,
        Cell: ({ value: [name, data] }) => (
          <Box display="flex" gap={1} alignItems="center">
            <Box width={40} height={40}>
              <img
                style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: 12 }}
                src={data.image}
                alt={name}
              />
            </Box>
            <FirstColumn value={name} />
          </Box>
        ),
      },
      {
        Header: "Tiền thưởng cơ bản",
        accessor: "basicBonus",
        width: "10%",
        align: "right",
      },
      {
        Header: "Mức thưởng cơ bản",
        accessor: "basicBonusLevel",
        width: "10%",
        align: "right",
      },
      {
        Header: "Hệ số nhóm",
        accessor: "groupCoefficient",
        width: "10%",
        align: "right",
      },
      {
        Header: "Hệ số thực tập",
        accessor: "internshipCoefficient",
        width: "10%",
        align: "right",
      },
      {
        Header: "Hệ số tháng làm việc",
        accessor: "workingMonthCoefficient",
        width: "10%",
        align: "right",
      },
      {
        Header: "Hệ số kỷ luật",
        accessor: "disciplineCoefficient",
        width: "10%",
        align: "right",
      },
      {
        Header: "Tổng điểm",
        accessor: "totalScore",
        width: "10%",
        align: "right",
      },
      {
        Header: "Năm",
        accessor: "year",
        width: "10%",
        align: "center",
      },
      { Header: "Thao tác", accessor: "action", sorted: false, align: "center" },
    ],
    rows: [],
  });

  const [query, setQuery] = useState({ sortBy: "createdAt", sortType: "desc" });
  const [paginationData, setPaginationData] = useState({ page: 1 });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const {
        data: { items, paginate },
      } = await UserRewardService.getAllBasicBonus({
        ...query,
        limit: paginationData.size || 10,
        page: paginationData.page,
      });
      setPaginationData((prev) => ({ ...prev, count: paginate.count, size: paginate.size }));
      setDataTable((prev) => ({
        ...prev,
        rows: items.map((item) => ({
          id: item._id,
          name: [
            item?.user?.personalInformation?.fullName || "--",
            { image: item?.user?.personalInformation?.avatar || "/logo-dhbk.png", checked: true },
          ],
          basicBonus: formatCurrency.format(item?.basicBonus) || "--",
          basicBonusLevel: formatCurrency.format(item?.basicBonusLevel) || "--",
          disciplineCoefficient: JSON.stringify(item?.disciplineCoefficient) || "--",
          groupCoefficient:
            new Intl.NumberFormat().format(Number(item.groupCoefficient).toFixed(2)) || "--",
          internshipCoefficient: JSON.stringify(item?.internshipCoefficient) || "--",
          workingMonthCoefficient:
            new Intl.NumberFormat().format(Number(item?.workingMonthCoefficient).toFixed(2)) ||
            "--",
          totalScore: new Intl.NumberFormat().format(Number(item.totalScore).toFixed(2)) || "--",
          year: item?.year || "--",
          action: <ActionCell item={item} setDataTable={setDataTable} />,
        })),
      }));
      setLoading(false);
    })();
  }, [query, paginationData.page]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card style={{ overflow: "visible" }}>
          <DataTable
            loading={loading}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            query={query}
            setQuery={setQuery}
            table={dataTable}
            canSearch
          />
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

export default TienThuongCoBan;
