const form = {
  formId: "new-user-form",
  formField: {
    mucViPham: {
      name: "mucViPham",
      label: "Mức vi phạm",
      type: "text",
      placeholder: "Vd: Nhắc nhở",
      errorMsg: "Mức vi phạm không được bỏ trống.",
    },
    lan1: {
      name: "lan1",
      label: "Lần 1",
      type: "number",
      placeholder: "0.1",
      errorMsg: "Hệ số vi phạm lần 1 không được bỏ trống.",
    },
    lan2: {
      name: "lan2",
      label: "Lần 2",
      type: "number",
      placeholder: "0.1",
      errorMsg: "Hệ số vi phạm lần 2 không được bỏ trống.",
    },
    lan3: {
      name: "lan3",
      label: "Lần 3",
      type: "number",
      placeholder: "0.1",
      errorMsg: "Hệ số vi phạm lần 3 không được bỏ trống.",
    },
    lan4: {
      name: "lan4",
      label: "Lần >=4",
      type: "number",
      placeholder: "0.1",
      errorMsg: "Hệ số vi phạm lần 4 không được bỏ trống.",
    },
    ghiChu: {
      name: "ghiChu",
      label: "Ghi Chú",
      type: "text",
      placeholder: "0.1",
      errorMsg: "Hệ số lần 1 không được bỏ trống.",
    },
  },
};

export default form;
