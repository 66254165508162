import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    diemThuong,
    gioToiDa,
    nhomHoatDong,
    ngayBatDau,
    ngayKetThuc,
    soLuongThamGia,
    tenHoatDong,
    chuTri,
    diaDiem,
    chiTieu,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    // [diemThuong.name]: Yup.string().required(diemThuong.errorMsg),
    // [gioToiDa.name]: Yup.string().required(gioToiDa.errorMsg),
    // [nhomHoatDong.name]: Yup.string().required(nhomHoatDong.errorMsg),
    // [diaDiem.name]: Yup.string().required(diaDiem.errorMsg),
    // [chiTieu.name]: Yup.string().required(chiTieu.errorMsg),
    // [ngayBatDau.name]: Yup.string().required(ngayBatDau.errorMsg),
    // [ngayKetThuc.name]: Yup.string().required(ngayKetThuc.errorMsg),
    // [soLuongThamGia.name]: Yup.string().required(soLuongThamGia.errorMsg),
    // [tenHoatDong.name]: Yup.string().required(tenHoatDong.errorMsg),
    // [chuTri.name]: Yup.string().required(chuTri.errorMsg),
  }),
];

export default validations;
