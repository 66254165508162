import checkout from "./form";

const {
  formField: {
    heSoTapSu,
    heSoChinhThuc,
    phanTramKhenThuongCoBan,
    phanTramKhenThuongDatBiet,
    phanTramKhenThuongXuatXac,
    quyThuongCoBanCuoiNam,
    ngachChuyenVien,
    ngachGiangVien,
    ngachNghienCuuVien,
    vienChucQuanLy,
  },
} = checkout;

const initialValues = {
  [heSoTapSu.name]: "",
  [heSoChinhThuc.name]: "",
  [phanTramKhenThuongCoBan.name]: "",
  [phanTramKhenThuongDatBiet.name]: "",
  [phanTramKhenThuongXuatXac.name]: "",
  [quyThuongCoBanCuoiNam.name]: "",
  [ngachChuyenVien.name]: "",
  [ngachGiangVien.name]: "",
  [ngachNghienCuuVien.name]: "",
  [vienChucQuanLy.name]: "",
};

export default initialValues;
