/* eslint-disable react/prop-types */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// NewUser page components
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { ErrorMessage, Field } from "formik";
import FormField from "../FormField";
import SoftDatePicker from "components/SoftDatePicker";
import { formatDate } from "utils";
import avatar from "assets/images/avatar.jpg";
import { SysService } from "services/sysServices";
import { FundService } from "services/fundServices";
import FormFieldCurrency from "../FormField/currency";

const SYS_KEY_ENUM = {
  TS: "F-TS-TS",
  CT: "F-TS-CT",
  MTXS: "MTXS",
  QKTDB: "QKTDB",
  QKTCBCN: "QKTCBCN",
  QKTXSCN: "QKTXSCN",
  N1: "F-NH-1",
  N2: "F-NH-2",
  N3: "F-NH-3",
  N4: "F-NH-4",
  FILE: "FILE-UPLOAD",
};

function ChiTietCaiDatHeThong({ formData }) {
  const { formField, values, errors, touched, getData, onChangeValue, setFieldValue } = formData;
  const { kichThuocFile } = formField;
  const { kichThuocFile: kichThuocFileV } = values;
  const { id } = useParams();
  const [files, setFiles] = useState({
    hinh: {},
    preview: avatar,
  });
  const [isPayload, setIsPayload] = useState({});
  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");

  useEffect(() => {}, [isPayload]);

  useEffect(() => {
    const getAllSys = async () => {
      const { data } = await SysService.getAll();
      data?.forEach((item) => {
        switch (item?.key) {
          case SYS_KEY_ENUM.FILE:
            setFieldValue(kichThuocFile.name, item?.value || 0);
            setFieldValue("idKichThuocFile", item?._id || 0);
            break;
          default:
            break;
        }
      });
    };
    getAllSys();
  }, []);

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          Cài đặt hệ thống
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={kichThuocFile.type}
              label={kichThuocFile.label}
              name={kichThuocFile.name}
              value={kichThuocFileV}
              error={errors.kichThuocFile && touched.kichThuocFile}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
ChiTietCaiDatHeThong.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChiTietCaiDatHeThong;
