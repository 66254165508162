import checkout from "./form";

const {
  formField: {
    canBoVC,
    ghiChu,
    //  heSoNhom,
    nam,
    // tienThuongXuatXac
  },
} = checkout;

const initialValues = {
  [canBoVC.name]: "",
  [ghiChu.name]: "",
  // [heSoNhom.name]: "",
  [nam.name]: "",
  // [tienThuongXuatXac.name]: "",
};

export default initialValues;
