/* eslint-disable react/prop-types */
// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelector } from "redux/selector";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const ROLE = {
  "Người tham gia": "Người tham gia",
  "Chủ trì": "Chủ trì",
  Phó: "Phó",
  "Hỗ trợ": "Hỗ trợ",
  "Toàn quyền đánh giá và chấm điểm": "Toàn quyền đánh giá và chấm điểm",
  "Người chấm điểm": "Người chấm điểm",
};

const ACTIVITY_ENUM = {
  UNCONFIRMED: "Chưa xác nhận",
  CONFIRMED: "Đã xác nhận",
  EVALUATED: "Đã đánh giá",
};

const roleCanEdit = [
  ROLE["Chủ trì"],
  ROLE["Toàn quyền đánh giá và chấm điểm"],
  ROLE["Người chấm điểm"],
];

function ActionCell({ activityId, item, role }) {
  const isCanEdit = roleCanEdit.includes(role);
  const { user } = useSelector(authSelector);
  return (
    <SoftBox display="flex" alignItems="center">
      {item?.user?._id === user?._id && (
        <Link to={`/user-quan-ly-hoat-dong/hoat-dong/${activityId}/nguoi-tham-gia/${item?._id}`}>
          <SoftTypography variant="body1" color="success" sx={{ cursor: "pointer", lineHeight: 0 }}>
            <Tooltip title="Xem chi tiết" placement="top">
              <Icon>visibility</Icon>
            </Tooltip>
          </SoftTypography>
        </Link>
      )}
      {isCanEdit && item.status !== ACTIVITY_ENUM.EVALUATED ? (
        <Link
          to={`/user-quan-ly-hoat-dong/hoat-dong/${activityId}/nguoi-tham-gia/${item?._id}/duyet`}
        >
          <SoftBox ml={2}>
            <SoftTypography variant="body1" color="info" sx={{ cursor: "pointer", lineHeight: 0 }}>
              <Tooltip title="Duyệt" placement="top">
                <Icon>edit</Icon>
              </Tooltip>
            </SoftTypography>
          </SoftBox>
        </Link>
      ) : null}

      <Link to={`/user-quan-ly-hoat-dong/hoat-dong/${activityId}/nguoi-tham-gia/${item?._id}/edit`}>
        {isCanEdit && item?.status === ACTIVITY_ENUM.EVALUATED && (
          <SoftBox ml={2}>
            <SoftTypography variant="body1" color="info" sx={{ cursor: "pointer", lineHeight: 0 }}>
              <Tooltip title="Gia hạn" placement="top">
                <AccessTimeIcon />
              </Tooltip>
            </SoftTypography>
          </SoftBox>
        )}
      </Link>
    </SoftBox>
  );
}

export default ActionCell;
