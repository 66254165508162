/* eslint-disable react/prop-types */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// NewUser page components
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { ErrorMessage, Field, setIn } from "formik";
import FormField from "../FormField";
import SoftDatePicker from "components/SoftDatePicker";
import { formatDate } from "utils";
import avatar from "assets/images/avatar.jpg";
import FormFieldCurrency from "../FormField/currency";
import { OfficialsService } from "services/officialsService";
import { YearEndBonusService } from "services/yearEndBonusServices";

function ChiTietTongThuongCuoiNam({ formData }) {
  const { formField, values, errors, touched, getData, onChangeValue, setFieldValue } = formData;
  const {
    canBoVC,
    tienThuongCoBan,
    tienThuongXuatXac,
    tongThuongCuoiNam,
    nam,
    thuongDBCaNhan,
    thuongDBNhom,
    ghiChu,
  } = formField;
  const {
    canBoVC: canBoVCV,
    tienThuongCoBan: tienThuongCoBanV,
    tienThuongXuatXac: tienThuongXuatXacV,
    tongThuongCuoiNam: tongThuongCuoiNamV,
    thuongDBCaNhan: thuongDBCaNhanV,
    thuongDBNhom: thuongDBNhomV,
    nam: namV,
    ghiChu: ghiChuV,
  } = values;
  const { id } = useParams();
  const [files, setFiles] = useState({
    hinh: {},
    preview: avatar,
  });
  const [isPayload, setIsPayload] = useState({
    canboVC: "",
    year: "",
  });
  const [info, setInfo] = useState({});
  const [selected, setSelected] = useState({});
  const [officals, setOfficals] = useState([]);
  const [isYear, setYear] = useState([]);
  const [users, setUsers] = useState();
  const [totalReward, setTotalReward] = useState(0);

  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");

  useEffect(() => {
    let currentYear = new Date().getFullYear();
    let arrayLength = 999;
    let startYear = currentYear - 3;
    let yearDifference = 1;
    let arr = [];
    for (let i = 0; i < arrayLength; i++) {
      if (startYear + i * yearDifference <= currentYear) {
        arr.push(startYear + i * yearDifference);
      }
    }
    setYear(arr.reverse());
  }, []);

  useEffect(() => {
    if (Object.keys(getData).length > 0) {
      // console.log(getData);
      setSelected({
        value: getData?.user?._id,
        label: getData?.user?.personalInformation?.fullName,
      });
      setFieldValue(canBoVC.name, getData?.user?._id || 0);
      setFieldValue(thuongDBCaNhan.name, getData?.specicalPersonalBonus || 0);
      setFieldValue(thuongDBNhom.name, getData?.specicalGroupBonus || 0);
      setFieldValue(tongThuongCuoiNam.name, getData?.total || 0);
      setFieldValue(nam.name, getData?.year || "--");
      setFieldValue(ghiChu.name, getData?.note || "--");
      setFieldValue("file", getData?.files || []);
      setUsers({
        label: getData?.user?.personalInformation?.fullName || "--",
        value: getData?.user?._id || "--",
      });
    }
  }, [getData]);

  useEffect(() => {
    const getOfficials = async () => {
      const { data } = await OfficialsService.getAll();
      setOfficals(data);
    };
    getOfficials();
  }, []);

  useEffect(() => {
    const getInfo = async () => {
      if (Object.keys(isPayload).length > 0 && isPayload.canboVC && isPayload.year) {
        const { data } = await YearEndBonusService.getInfo({
          user: isPayload?.canboVC || "--",
          year: isPayload?.year || new Date().getFullYear(),
        });

        setInfo(data);
      }
    };
    getInfo();
  }, [isPayload]);

  useEffect(() => {
    setTotalReward(
      (thuongDBCaNhanV / 1 ?? 0) +
        (thuongDBNhomV / 1 ?? 0) +
        (info?.rewardRankingBonus?.bonustReward ?? 0) +
        (info?.basicBonus?.basicBonus ?? 0)
    );
  }, [thuongDBCaNhanV, thuongDBNhomV, info]);

  // if (id && containsEdit && (!getData || Object.keys(getData).length === 0)) {
  //   return <>Loading...</>;
  // }

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          {id && containsEdit
            ? "Cập nhật"
            : id
            ? "Chi tiết"
            : "Tính tổng thưởng cuối năm cho cán bộ"}
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SoftBox display="flex" flexDirection="column">
              {id && !containsEdit ? (
                <FormField
                  type={canBoVC.type}
                  label={canBoVC.label}
                  name={canBoVC.name}
                  value={getData?.user?.personalInformation?.fullName}
                  error={errors.canBoVC && touched.canBoVC}
                  disabled={id && !containsEdit}
                />
              ) : (
                <>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    mb={0.9}
                    mt={1.7}
                  >
                    {canBoVC.label}
                  </SoftTypography>
                  <SoftSelect
                    disabled={id && !containsEdit}
                    // isLoading={loading}
                    // onMenuOpen={handleFetchDrivers}
                    value={users}
                    onChange={(e) => {
                      setIsPayload((prev) => ({ ...prev, canboVC: e.value }));
                      setFieldValue(canBoVC.name, e.value || "--");
                      setUsers({
                        label: e?.label,
                        value: e?.value,
                      });
                    }}
                    options={officals?.map((items) => ({
                      label: items?.personalInformation?.fullName,
                      value: items?._id,
                    }))}
                  />
                </>
              )}
            </SoftBox>
            <SoftBox mt={0.75}>
              <SoftTypography component="div" variant="caption" color="error">
                <ErrorMessage name={canBoVC.name} />
              </SoftTypography>
            </SoftBox>
          </Grid>

          <Grid item xs={12} sm={6}>
            {id && !containsEdit ? (
              <FormField
                type="text"
                label="Năm"
                name="year"
                value={getData?.year || ""}
                disabled={true}
              />
            ) : (
              <SoftBox paddingTop={"3px"} display="block">
                <SoftTypography component="label" variant="caption" fontWeight="bold" mb={0.5}>
                  Theo năm
                </SoftTypography>
                <SoftSelect
                  placeholder={"Chọn năm"}
                  onChange={(e) => {
                    setIsPayload((prev) => ({ ...prev, year: e.value }));
                    setFieldValue(nam.name, e.value || "--");
                  }}
                  options={isYear?.map((e) => ({ label: e, value: e }))}
                />
              </SoftBox>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormFieldCurrency
              type={thuongDBNhom.type}
              label={thuongDBNhom.label}
              name={thuongDBNhom.name}
              value={thuongDBNhomV}
              error={errors.thuongDBNhom && touched.thuongDBNhom}
              disabled={id && !containsEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormFieldCurrency
              type={thuongDBCaNhan.type}
              label={thuongDBCaNhan.label}
              name={thuongDBCaNhan.name}
              value={thuongDBCaNhanV}
              error={errors.thuongDBCaNhan && touched.thuongDBCaNhan}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormFieldCurrency
              type={thuongDBNhom.type}
              label={"Tiền Thưởng xuất sắc"}
              name={"thuongXuatSac"}
              value={getData?.rewardBonus || info?.rewardRankingBonus?.bonustReward || 0}
              error={""}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormFieldCurrency
              type={thuongDBCaNhan.type}
              label={"Tiền Thưởng cơ bản"}
              name={"thuongCoban"}
              value={getData?.basicBonus || info?.basicBonus?.basicBonus || 0}
              error={""}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormFieldCurrency
              type={thuongDBCaNhan.type}
              label={"Tổng thưởng"}
              name={"tongThuong"}
              value={getData?.total || totalReward}
              error={""}
              disabled={true}
              autoFormat={true}
            />
          </Grid>
        </Grid>
        {/* 
        <Grid container spacing={3}>
          
        </Grid> */}

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={ghiChu.type}
              label={ghiChu.label}
              name={ghiChu.name}
              value={ghiChuV}
              error={errors.ghiChu && touched.ghiChu}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
ChiTietTongThuongCuoiNam.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChiTietTongThuongCuoiNam;
