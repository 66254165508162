import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    canBoVC,
    // diemTongNhom,
    // heSoKyLuat,
    // heSoNhom,
    // heSoTapSu,
    // heSoThangLamViec,
    // tienThuongCoban,
    nam,
    ghiChu,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [canBoVC.name]: Yup.array().required(canBoVC.errorMsg),
    // [diemTongNhom.name]: Yup.string().required(diemTongNhom.errorMsg),
    // [heSoKyLuat.name]: Yup.string().required(heSoKyLuat.errorMsg),
    // [heSoNhom.name]: Yup.string().required(heSoNhom.errorMsg).min(0, "Phải lớn hơn 0"),
    // [heSoTapSu.name]: Yup.string().required(heSoTapSu.errorMsg),
    // [heSoThangLamViec.name]: Yup.string().required(heSoThangLamViec.errorMsg),
    // [tienThuongCoban.name]: Yup.string().required(tienThuongCoban.errorMsg),
    [nam.name]: Yup.string().required(nam.errorMsg),
  }),
];

export default validations;
