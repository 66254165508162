/* eslint-disable react/prop-types */
// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Link } from "react-router-dom";

function ActionCell({ item }) {
  return (
    <SoftBox display="flex" alignItems="center">
      <Link to={`/user-chuc-nang-khac/tien-thuong-co-ban/${item?._id}`}>
        <SoftTypography variant="body1" color="success" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Xem chi tiết" placement="top">
            <Icon>visibility</Icon>
          </Tooltip>
        </SoftTypography>
      </Link>
    </SoftBox>
  );
}

export default ActionCell;
