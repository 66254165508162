/* eslint-disable react/prop-types */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// NewUser page components
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ErrorMessage, Field } from "formik";
import FormField from "../FormField";
import SoftDatePicker from "components/SoftDatePicker";
import { formatDate } from "utils";
import avatar from "assets/images/avatar.jpg";
import FileAttachment from "components/AttachmentFile";

function ChiTietNhiemVu({ formData }) {
  const { formField, values, errors, touched, getData, onChangeValue, setFieldValue } = formData;
  const { tenNhiemVu, ghiChu } = formField;
  const { tenNhiemVu: tenNhiemVuV, ghiChu: ghiChuV } = values;
  const { id } = useParams();
  const [files, setFiles] = useState([]);

  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");
  const containsCreate = currentPath.includes("create");
  const containsConfirm = currentPath.includes("confirm");

  useEffect(() => {
    if (Object.keys(getData).length > 0) {
      setFieldValue(tenNhiemVu.name, getData?.name || "--");
      setFieldValue(ghiChu.name, getData?.note || "--");
      setFieldValue("file", getData?.files || "--");
    }
  }, [getData]);

  useEffect(() => {
    setFieldValue("file", files || []);
  }, [files]);

  // if (id && containsEdit && (!getData || Object.keys(getData).length === 0)) {
  //   return <>Loading...</>;
  // }

  return (
    <div>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          {id && containsEdit ? "Cập nhật" : id ? "Chi tiết" : "Tạo mới"}
        </SoftTypography>
      </SoftBox>

      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={tenNhiemVu.type}
              label={tenNhiemVu.label}
              name={tenNhiemVu.name}
              value={tenNhiemVuV}
              error={errors.tenNhiemVu && touched.tenNhiemVu}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={ghiChu.type}
              label={ghiChu.label}
              name={ghiChu.name}
              value={ghiChuV}
              error={errors.ghiChu && touched.ghiChu}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FileAttachment
              id={id}
              containsEdit={containsEdit}
              containsCreate={containsCreate}
              containsConfirm={containsConfirm}
              file={getData.files}
              setFile={setFiles}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </div>
  );
}

// typechecking props for UserInfo
ChiTietNhiemVu.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChiTietNhiemVu;
