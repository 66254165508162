import { useEffect, useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { useNavigate, useParams } from "react-router-dom";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import validations from "./schemas/validations";
import { toast } from "react-toastify";
import Info from "./components/Info";
import { uploadFile } from "utils";
import { ActivityServices } from "services/activityServices";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ParticipantService } from "services/participantService";
import { authSelector } from "redux/selector";
import { useSelector } from "react-redux";

function ChiTietHoatDong() {
  const [activeStep, setActiveStep] = useState(0);
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const { id } = useParams();
  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");
  const navigate = useNavigate();
  const [getData, setGetData] = useState({});
  const [data, setData] = useState({
    file: "",
  });
  const { user } = useSelector(authSelector);
  const [openConfirmJoin, setOpenConfirmJoin] = useState(false);
  const [isRefetch, setIsRefetch] = useState(false);

  useEffect(() => {
    if (!id) return;
    (async () => {
      const { data } = await ActivityServices.getById(id);
      setGetData(data);
    })();
  }, [id, user, isRefetch]);

  const onToggleRefetch = () => setIsRefetch((prev) => !prev);

  const onChangeValue = (type, value) => {
    setData((prev) => ({ ...prev, [type]: value }));
  };

  const submitForm = async (values, actions) => {
    // console.log({ values });
    let attachmentFile;
    if (values.file.length > 0) {
      attachmentFile = await uploadFile(values.file);
    }
    try {
      await ActivityServices.create({
        activityGroup: values.nhomHoatDong,
        name: values.tenHoatDong,
        maxExcellentPoint: values.diemThuongXuatXac,
        numberOfParticipants: values.soLuongThamGia,
        rewardPoint: values.diemThuong,
        deductionPoint: values.diemTru,
        startTime: values.ngayBatDau,
        endTime: values.ngayKetThuc,
        note: values.ghiChu,
        files: attachmentFile,
        host: values.chuTri,
        deputy: values.pho,
        supporter: values.hoTro,
        maxHours: values.gioToiDa,
        targets: values.chiTieu,
        places: values.diaDiem,
      });
      toast.success("Tạo hoạt động thành công");
      actions.setSubmitting(false);
      actions.resetForm();
      setTimeout(() => {
        navigate(-1);
      }, 1500);
    } catch (error) {
      actions.setSubmitting(false);
      toast.error("Tạo hoạt động thất bại");
    }
  };

  const handleUpdate = async (values, actions) => {
    let attachmentFile;
    if (values.file.length > 0) {
      attachmentFile = await uploadFile(values.file);
    }
    try {
      await ActivityServices.update(getData?._id, {
        activityGroup: values.nhomHoatDong,
        name: values.tenHoatDong,
        maxExcellentPoint: values.diemThuongXuatXac,
        numberOfParticipants: values.soLuongThamGia,
        rewardPoint: values.diemThuong,
        deductionPoint: values.diemTru,
        startTime: values.ngayBatDau,
        endTime: values.ngayKetThuc,
        note: values.ghiChu,
        files: attachmentFile || [],
        host: values.chuTri,
        deputy: values.pho,
        supporter: values.hoTro,
        maxHours: values.gioToiDa,
        targets: values.chiTieu,
        places: values.diaDiem,
      });
      toast.success("Cập nhật hoạt động thành công");
      actions.setSubmitting(false);
      actions.resetForm();
      setTimeout(() => {
        navigate(-1);
      }, 1500);
    } catch (error) {
      actions.setSubmitting(false);
      toast.error("Cập nhật hoạt động thất bại");
    }
  };

  const handleSubmit = (values, actions) => {
    if (id && containsEdit) {
      handleUpdate({ ...data, ...values }, actions);
    } else {
      submitForm({ ...data, ...values }, actions);
    }
  };

  const handleBack = () => navigate(-1);

  const handleJoin = async () => {
    try {
      await ParticipantService.create({
        activity: id,
      });
      const { data } = await ActivityServices.getById(id);
      setGetData(data);
      setOpenConfirmJoin(false);
      toast.success("Tham gia hoạt động thành công");
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Tham gia hoạt động thất bại");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%", overflow: "visible" }}>
                    <SoftBox p={2}>
                      <SoftBox>
                        <Info
                          formData={{
                            values,
                            touched,
                            formField,
                            errors,
                            getData,
                            onChangeValue,
                            setFieldValue,
                            onToggleRefetch,
                          }}
                        />
                        <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          <SoftButton onClick={handleBack} variant="gradient" color="light">
                            Trở về
                          </SoftButton>
                          {getData.activityparticipantslists &&
                          getData.activityparticipantslists.length !== 0 &&
                          getData.activityparticipantslists.find(
                            (item) => item.user._id == user._id
                          ) ? (
                            <SoftButton variant="gradient" color="dark">
                              Đã tham gia
                            </SoftButton>
                          ) : new Date(getData?.endTime).getTime() <= Date.now() ? null : (
                            <SoftButton
                              variant="gradient"
                              color="dark"
                              onClick={() => setOpenConfirmJoin(true)}
                            >
                              Tham gia
                            </SoftButton>
                          )}

                          {!id && (
                            <SoftButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              Tạo
                            </SoftButton>
                          )}
                          {id && containsEdit && (
                            <SoftButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              Cập nhật
                            </SoftButton>
                          )}
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>
      <Dialog open={openConfirmJoin} onClose={() => setOpenConfirmJoin(false)}>
        <DialogTitle id="alert-dialog-title">Xác nhận tham gia</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Bạn có chắc chắn muốn tham gia?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmJoin(false)}>Hủy</Button>
          <Button onClick={handleJoin} autoFocus>
            Đồng ý
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default ChiTietHoatDong;
