const form = {
  formId: "new-user-form",
  formField: {
    quyThuongNam: {
      name: "quyThuongNam",
      label: "Quỹ thưởng năm",
      type: "text",
      placeholder: "",
      errorMsg: "Quỹ thưởng năm không được bỏ trống.",
    },
    nam: {
      name: "nam",
      label: "Năm",
      type: "number",
      placeholder: "Bộ Môn A",
      errorMsg: "Năm không được bỏ trống.",
    },
  },
};

export default form;
