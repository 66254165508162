import AxiosInstance from "./api";

export const DashboardServices = {
  getChartPie: async (params) => {
    return await AxiosInstance.get("/admin/statistical/activities", { params: params });
  },

  getChartHorizontal: async (params) => {
    return await AxiosInstance.get("/admin/statistical/activities/percentage", { params: params });
  },

  getDataTableCBVC: async (params) => {
    return await AxiosInstance.get("/admin/statistical/officials", { params: params });
  },

  getChartVertical: async (params) => {
    return await AxiosInstance.get("/admin/statistical/scores", { params: params });
  },

  getDataTableRankings: async (params) => {
    return await AxiosInstance.get("/admin/statistical/rankings", { params: params });
  },

  getChartVertical2: async (params) => {
    return await AxiosInstance.get("/admin/statistical/detail/scores", { params: params });
  },
};
