/* eslint-disable react/prop-types */
// @mui material components
import Card from "@mui/material/Card";

import { Box } from "@mui/material";
import avatar from "assets/images/team-2.jpg";
import SoftBox from "components/SoftBox";
import FirstColumn from "examples/FirstColumn";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { UserRewardService } from "services/userRewardService";
import { formatCurrency } from "utils";
import ActionCell from "./components/ActionCell";
import DataTable from "./data/index";

function TongThuongCuoiNam() {
  const [dataTable, setDataTable] = useState({
    columns: [
      {
        Header: "Họ và Tên",
        accessor: "name",
        width: "10%",
        sorted: false,
        Cell: ({ value: [name, data] }) => (
          <Box display="flex" gap={1} alignItems="center">
            <Box width={40} height={40}>
              <img
                style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: 12 }}
                src={data.image}
                alt={name}
              />
            </Box>
            <FirstColumn value={name} />
          </Box>
        ),
      },
      {
        Header: "Mã cbvc",
        accessor: "ma-cbvc",
        width: "10%",
        align: "center",
      },
      {
        Header: "bộ môn",
        accessor: "bo-mon",
        align: "center",
      },
      {
        Header: "Thưởng cơ bản",
        accessor: "thuong-co-ban",
        align: "right",
      },
      {
        Header: "Thưởng xuất sắc",
        accessor: "thuong-xuat-sac",
        align: "right",
      },
      {
        Header: "Thưởng đặc biệt cá nhân",
        accessor: "tien-thuong-db-ca-nhan",
        sorted: false,
        align: "right",
      },
      {
        Header: "Thưởng đặc biệt nhóm",
        accessor: "tien-thuong-db-nhom",
        sorted: false,
        align: "right",
      },
      {
        Header: "tổng thưởng cuối năm",
        accessor: "tong-thuong-cuoi-nam",
        sorted: false,
        align: "right",
      },
      { Header: "Năm", accessor: "nam", sorted: false, align: "center" },
      { Header: "thao tác", accessor: "action", sorted: false, align: "center" },
    ],
    rows: [],
  });

  const [query, setQuery] = useState({ sortBy: "createdAt", sortType: "desc" });
  const [paginationData, setPaginationData] = useState({ page: 1 });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const {
        data: { items, paginate },
      } = await UserRewardService.getAllYearEndBonus({
        ...query,
        limit: paginationData.size || 10,
        page: paginationData.page,
      });
      setPaginationData((prev) => ({ ...prev, count: paginate.count, size: paginate.size }));
      setDataTable((prev) => ({
        ...prev,
        rows: items.map((item) => {
          return {
            id: item?._id,
            name: [
              item?.user?.personalInformation?.fullName || "--",
              { image: item?.user?.personalInformation?.avatar || "/logo-dhbk.png", checked: true },
            ],
            "ma-cbvc": [item?.user?.personalInformation?.officerId || "--"],
            "bo-mon": [item?.user?.department?.name || "--"],
            "thuong-co-ban": formatCurrency.format(item?.basicBonus) || "--",
            "thuong-xuat-sac": formatCurrency.format(item?.rewardBonus) || "--",
            "tien-thuong-db-ca-nhan": formatCurrency.format(item?.specicalPersonalBonus) || "--",
            "tien-thuong-db-nhom": formatCurrency.format(item?.specicalGroupBonus) || "--",
            "tong-thuong-cuoi-nam": formatCurrency.format(item?.total) || "--",
            nam: item?.year,
            action: <ActionCell item={item} setDataTable={setDataTable} />,
          };
        }),
      }));
      setLoading(false);
    })();
  }, [query, paginationData.page]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card style={{ overflow: "visible" }}>
          <DataTable
            loading={loading}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            query={query}
            setQuery={setQuery}
            table={dataTable}
            canSearch
          />
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

export default TongThuongCuoiNam;
