/* eslint-disable react/prop-types */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// NewUser page components
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { ErrorMessage, Field } from "formik";
import FormField from "../FormField";
import SoftDatePicker from "components/SoftDatePicker";
import { formatDate } from "utils";
import avatar from "assets/images/avatar.jpg";
import FileAttachment from "components/AttachmentFile";
import { DiscipLineService } from "services/disciplineServices";
import { OfficialsService } from "services/officialsService";
import moment from "moment";

function ChiTietViPham({ formData }) {
  const { formField, values, errors, touched, getData, onChangeValue, setFieldValue } = formData;
  const { tenCBVC, mucViPham, soLanViPham, ngayViPham, ngayKetThuc, ghiChu } = formField;
  const {
    tenCBVC: tenCBVCV,
    soLanViPham: soLanViPhamV,
    ngayViPham: ngayViPhamV,
    ngayKetThuc: ngayKetThucV,
    ghiChu: ghiChuV,
    mucViPham: mucViPhamV,
  } = values;
  const { id } = useParams();
  const [files, setFiles] = useState({
    hinh: {},
    preview: avatar,
  });
  const [dob, setDob] = useState();
  const [isPayload, setIsPayload] = useState({});

  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");
  const containsCreate = currentPath.includes("create");

  useEffect(() => {
    if (Object.keys(getData).length > 0) {
      setIsPayload({
        ...isPayload,
        cbvc: {
          value: getData?.user._id,
          label:
            `${getData?.user?.personalInformation?.fullName} - ${getData?.user?.personalInformation?.officerId}` ||
            "--",
        },
        mucViPham: {
          value: getData?.disciplineLevel._id,
          label: getData?.disciplineLevel?.name || "--",
        },
      });

      setFieldValue(tenCBVC.name, getData?.user?._id || "--");
      setFieldValue(mucViPham.name, getData?.disciplineLevel?._id || "--");
      setFieldValue(ngayViPham.name, getData?.time);
      setFieldValue("file", getData?.files || "--");
      setFieldValue(ghiChu.name, getData?.note || "--");

      setDob(new Date(Number(getData?.time)));
    }
  }, [getData]);

  useEffect(() => {
    setFieldValue("file", files || []);
  }, [files]);

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          {id && containsEdit ? "Cập nhật" : id ? "Chi tiết" : "Tạo mới"}
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={tenCBVC.type}
              label={tenCBVC.label}
              name={tenCBVC.name}
              value={isPayload?.cbvc?.label}
              error={errors.tenCBVC && touched.tenCBVC}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={mucViPham.type}
              label={mucViPham.label}
              name={mucViPham.name}
              value={isPayload?.mucViPham?.label}
              error={errors.mucViPham && touched.mucViPham}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={"text"}
              label={ngayViPham.label}
              name={"dob"}
              value={moment(ngayViPhamV).format("DD/MM/YYYY")}
              disabled={id && !containsEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={ghiChu.type}
              label={ghiChu.label}
              name={ghiChu.name}
              value={ghiChuV}
              error={errors.ghiChu && touched.ghiChu}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FileAttachment
              id={id}
              containsEdit={containsEdit}
              containsCreate={containsCreate}
              file={getData.files || []}
              setFile={setFiles}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
ChiTietViPham.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChiTietViPham;
