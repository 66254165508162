import AxiosInstance from "./api";

export const BonusServices = {
  getAllBasicBonus: async (params) => {
    return await AxiosInstance.get("/private/basic-bonus", { params: params });
  },
  getByIdBasicBonus: async (id) => {
    return await AxiosInstance.get(`/private/basic-bonus/${id}`);
  },
  getDataExportBasicBonus: async (params) => {
    return await AxiosInstance.get(`/admin/basic-bonus/export`, { params: params });
  },
  createBasicBonus: async (payload) => {
    return await AxiosInstance.post(`/admin/basic-bonus`, payload);
  },
  updateBasicBonus: async (id, payload) => {
    return await AxiosInstance.patch(`/admin/basic-bonus/${id}`, payload);
  },
  deleteBasicBonus: async (payload) => {
    return await AxiosInstance.delete(`/admin/basic-bonus/${payload}`);
  },
  getAllRewardRanking: async (params) => {
    return await AxiosInstance.get("/private/reward-ranking", { params: params });
  },
  getByIdRewardRanking: async (id) => {
    return await AxiosInstance.get(`/private/reward-ranking/${id}`);
  },
  getDataExportRewardRanking: async (params) => {
    return await AxiosInstance.get(`/admin/reward-ranking/export`, { params: params });
  },
  createRewardRanking: async (payload) => {
    return await AxiosInstance.post(`/admin/reward-ranking`, payload);
  },
  updateRewardRanking: async (id, payload) => {
    return await AxiosInstance.patch(`/admin/reward-ranking/${id}`, payload);
  },
  deleteRewardRanking: async (payload) => {
    return await AxiosInstance.delete(`/admin/reward-ranking/${payload}`);
  },
};
