import AxiosInstance from "./api";

export const UploadService = {
  upload: async (files) => {
    const promises = files.map(async (file) => {
      if (!file?.preview) return file;
      const formData = new FormData();
      formData.append("file", file);
      const response = await AxiosInstance.post(`/public/upload`, formData);
      if (response.status === 201) {
        return response.data.url;
      } else {
        console.error("Image upload failed.");
      }
    });

    const urls = await Promise.all(promises);
    return urls;
  },
  uploadNoPreview: async (files) => {
    const promises = Array.from(files).map(async (file) => {
      if (typeof file === "string" || !file?.name) return file;
      const formData = new FormData();
      formData.append("file", file);
      const response = await AxiosInstance.post(`/public/upload`, formData);
      if (response.status === 201) {
        return response.data.url;
      } else {
        console.error("Image upload failed.");
      }
    });

    const urls = await Promise.all(promises);
    return urls;
  },
};
