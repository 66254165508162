/* eslint-disable react/prop-types */
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function ActionCell({ item, setDataTable }) {
  return (
    <SoftBox display="flex" alignItems="center">
      <Link to={`/user-quan-ly-nhiem-vu/nhiem-vu/${item._id}`}>
        <SoftTypography variant="body1" color="success" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Xem chi tiết" placement="top">
            <Icon>visibility</Icon>
          </Tooltip>
        </SoftTypography>
      </Link>
    </SoftBox>
  );
}

export default ActionCell;
