import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    diemThuong,
    gioToiDa,
    nhomHoatDong,
    ngayBatDau,
    ngayKetThuc,
    soLuongThamGia,
    tenHoatDong,
    chuTri,
    diaDiem,
    chiTieu,
    hanGuiMinhChung,
    loaiHD,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    // [gioToiDa.name]: Yup.number().required(gioToiDa.errorMsg),
    [diemThuong.name]: Yup.string().required(diemThuong.errorMsg),
    [loaiHD.name]: Yup.string().required(loaiHD.errorMsg),
    [nhomHoatDong.name]: Yup.string().required(nhomHoatDong.errorMsg),
    [diaDiem.name]: Yup.string().required(diaDiem.errorMsg),
    [chiTieu.name]: Yup.string().required(chiTieu.errorMsg),
    [soLuongThamGia.name]: Yup.string().required(soLuongThamGia.errorMsg),
    [tenHoatDong.name]: Yup.string().required(tenHoatDong.errorMsg),
    [chuTri.name]: Yup.array()
      .of(Yup.string())
      .min(1, "Chủ trì ít nhất phải 1 cán bộ")
      .required(chuTri.errorMsg),
    [ngayBatDau.name]: Yup.string().required(ngayBatDau.errorMsg),
    [ngayKetThuc.name]: Yup.string().required(ngayKetThuc.errorMsg),
    [hanGuiMinhChung.name]: Yup.string().required(hanGuiMinhChung.errorMsg),
  }),
];

export default validations;
