import AxiosInstance from "./api";

export const SysService = {
  getAll: async (params) => {
    return await AxiosInstance.get(`/public/sys-config`, { params: params });
  },
  update: async (id, payload) => {
    return await AxiosInstance.patch(`/admin/sys-config/${id}`, payload);
  },
};
