import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { maNhomHoatDong, tenNhomHoatDong, diemThuong, loaiNhiemVu },
} = checkout;

const validations = [
  Yup.object().shape({
    [tenNhomHoatDong.name]: Yup.string().required(tenNhomHoatDong.errorMsg),
    [diemThuong.name]: Yup.string().required(diemThuong.errorMsg),
    [loaiNhiemVu.name]: Yup.string().required(loaiNhiemVu.errorMsg),
  }),
];

export default validations;
