import checkout from "./form";

const {
  formField: { dinhMucNV1, dinhMucNV2, dinhMucNV3 },
} = checkout;

const initialValues = {
  [dinhMucNV1.name]: "",
  [dinhMucNV2.name]: "",
  [dinhMucNV3.name]: "",
};

export default initialValues;
