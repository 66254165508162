import checkout from "./form";

const {
  formField: {
    canBoVC,
    // diemTongNhom,
    // heSoKyLuat,
    // heSoNhom,
    // heSoTapSu,
    // heSoThangLamViec,
    // tienThuongCoban,
    nam,
    ghiChu,
  },
} = checkout;

const initialValues = {
  [canBoVC.name]: "",
  // [diemTongNhom.name]: "",
  // [heSoKyLuat.name]: "",
  // [heSoNhom.name]: "",
  // [heSoTapSu.name]: "",
  // [heSoThangLamViec.name]: "",
  // [tienThuongCoban.name]: "",
  [nam.name]: "",
  [ghiChu.name]: "",
};

export default initialValues;
