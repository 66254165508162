import checkout from "./form";

const {
  formField: { kichThuocFile },
} = checkout;

const initialValues = {
  [kichThuocFile.name]: "",
};

export default initialValues;
