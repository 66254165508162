import AxiosInstance from "./api";

export const BasicsCoreService = {
  getAll: async (payload) => {
    return await AxiosInstance.get("/private/basic-score", {
      params: payload,
    });
  },
  getById: async (id) => {
    return await AxiosInstance.get(`/private/basic-score/${id}`);
  },
  getDataExport: async (params) => {
    return await AxiosInstance.get(`/admin/basic-score/export`, { params: params });
  },
  create: async (payload) => {
    return await AxiosInstance.post("/admin/basic-score", payload);
  },
  update: async (id, payload) => {
    return await AxiosInstance.patch(`/admin/basic-score/${id}`, payload);
  },
  delete: async (id) => {
    return await AxiosInstance.delete(`/admin/basic-score/${id}`);
  },
};
