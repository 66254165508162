const form = {
  formId: "new-user-form",
  formField: {
    canBoVC: {
      name: "canBoVC",
      label: "Cán bộ viên chức",
      type: "text",
      placeholder: "Vd: BM-A",
      errorMsg: "Cán bộ viên chức không được bỏ trống.",
    },
    // heSoNhom: {
    //   name: "heSoNhom",
    //   label: "Hệ số nhóm",
    //   type: "number",
    //   min: 0,
    //   placeholder: "Vd: BM-A",
    //   errorMsg: "Hệ số nhóm không được bỏ trống.",
    // },
    // diemTongNhom: {
    //   name: "diemTongNhom",
    //   label: "Điểm tổng của nhóm",
    //   type: "number",
    //   placeholder: "Bộ Môn A",
    //   errorMsg: "Điểm tổng của nhóm không được bỏ trống.",
    // },
    // heSoThangLamViec: {
    //   name: "heSoNhom",
    //   label: "Hệ số tháng làm việc",
    //   type: "number",
    //   placeholder: "Vd: BM-A",
    //   errorMsg: "Hệ số tháng làm việc không được bỏ trống.",
    // },
    // heSoTapSu: {
    //   name: "heSoTapSu",
    //   label: "Hệ tập sự",
    //   type: "number",
    //   placeholder: "Vd: BM-A",
    //   errorMsg: "Hệ tập sự không được bỏ trống.",
    // },
    // heSoKyLuat: {
    //   name: "heSoKyLuat",
    //   label: "Hệ số kỷ luật",
    //   type: "number",
    //   placeholder: "Vd: BM-A",
    //   errorMsg: "Hệ số kỷ luật không được bỏ trống.",
    // },
    // tienThuongCoban: {
    //   name: "tienThuongCoban",
    //   label: "Tiền thưởng cơ bản cuối năm",
    //   type: "number",
    //   placeholder: "Vd: BM-A",
    //   errorMsg: "Tiền thưởng cơ bản cuối năm không được bỏ trống.",
    // },
    ghiChu: {
      name: "ghiChu",
      label: "Ghi chú",
      type: "text",
      placeholder: "Vd: BM-A",
      errorMsg: "Năm không được bỏ trống.",
    },
    nam: {
      name: "nam",
      label: "Năm",
      type: "number",
      placeholder: "Vd: BM-A",
      errorMsg: "Năm không được bỏ trống.",
    },
  },
};

export default form;
