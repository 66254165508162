import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { canBoVC, ghiChu, heSoNhom, nam, tienThuongXuatXac },
} = checkout;

const validations = [
  Yup.object().shape({
    [canBoVC.name]: Yup.array().required(canBoVC.errorMsg),
    // [heSoNhom.name]: Yup.string().required(heSoNhom.errorMsg),
    [nam.name]: Yup.string().required(nam.errorMsg),
    // [tienThuongXuatXac.name]: Yup.string().required(tienThuongXuatXac.errorMsg),
  }),
];

export default validations;
