const form = {
  formId: "new-user-form",
  formField: {
    maBoMon: {
      name: "maBoMon",
      label: "Mã Bộ Môn",
      type: "text",
      placeholder: "Vd: BM-A",
      errorMsg: "Mã bộ môn không được bỏ trống.",
    },
    tenBoMon: {
      name: "tenBoMon",
      label: "Tên Bộ Môn",
      type: "text",
      placeholder: "Bộ Môn A",
      errorMsg: "Tên bộ môn không được bỏ trống.",
    },
  },
};

export default form;
