/* eslint-disable react/prop-types */
import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { Grid } from "@mui/material";
import FileAttachment from "components/AttachmentFile";
import { useParams } from "react-router-dom";
import FormField from "../FormField";

function ChiTietTienThuongCoBan({ formData }) {
  const { getData } = formData;
  const { id } = useParams();

  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");
  const containsCreate = currentPath.includes("create");

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          Chi tiết
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Họ và tên"
              name="name"
              value={getData?.user?.personalInformation?.fullName || ""}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Năm"
              name="year"
              value={getData?.year || ""}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Hệ số nhóm"
              name="groupCoefficient"
              value={
                new Intl.NumberFormat().format(Number(getData?.groupCoefficient).toFixed(2)) || "0"
              }
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Hệ số thực tập"
              name="internshipCoefficient"
              value={getData?.internshipCoefficient || "0"}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type="text"
              label="Hệ số tháng làm việc"
              name="workingMonthCoefficient"
              value={
                new Intl.NumberFormat().format(
                  Number(getData?.workingMonthCoefficient).toFixed(2)
                ) || "0"
              }
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type="text"
              label="Hệ số kỷ luật"
              name="disciplineCoefficient"
              value={
                new Intl.NumberFormat().format(Number(getData?.disciplineCoefficient).toFixed(2)) ||
                "0"
              }
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type="text"
              label="Tổng điểm"
              name="totalScore"
              value={new Intl.NumberFormat().format(Number(getData?.totalScore).toFixed(2)) || "0"}
              disabled={true}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
ChiTietTienThuongCoBan.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChiTietTienThuongCoBan;
