/* eslint-disable react/prop-types */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// NewUser page components
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { ErrorMessage, Field } from "formik";
import FormField from "../FormField";
import SoftDatePicker from "components/SoftDatePicker";
import { formatDate } from "utils";
import avatar from "assets/images/avatar.jpg";

function ChiTietBoMon({ formData }) {
  const { formField, values, errors, touched, getData, onChangeValue, setFieldValue } = formData;
  const { maBoMon, tenBoMon } = formField;
  const { maBoMon: maBoMonV, tenBoMon: tenBoMonV } = values;
  const { id } = useParams();
  const [files, setFiles] = useState({
    hinh: {},
    preview: avatar,
  });
  const [isPayload, setIsPayload] = useState({});

  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");

  useEffect(() => {}, [isPayload]);


  useEffect(() => {
    if (Object.keys(getData).length > 0) {
      setFieldValue(tenBoMon.name, getData?.name || "--");
    }
  }, [getData]);

  if (id && containsEdit && (!getData || Object.keys(getData).length === 0)) {
    return <>Loading...</>;
  }

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          {id && containsEdit ? "Cập nhật" : id ? "Chi tiết" : "Tạo mới"}
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={6}>
            <FormField
              type={maBoMon.type}
              label={maBoMon.label}
              name={maBoMon.name}
              value={maBoMonV}
              error={errors.maBoMon && touched.maBoMon}
              disabled={id && !containsEdit}
            />
          </Grid> */}
          <Grid item xs={12} sm={12}>
            <FormField
              type={tenBoMon.type}
              label={tenBoMon.label}
              name={tenBoMon.name}
              value={tenBoMonV}
              error={errors.tenBoMon && touched.tenBoMon}
              disabled={id && !containsEdit}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
ChiTietBoMon.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChiTietBoMon;
