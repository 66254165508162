import AxiosInstance from "./api";
export const ActivityServices = {
  create: async (payload) => {
    return await AxiosInstance.post("/admin/activity", payload);
  },
  update: async (id, payload) => {
    return await AxiosInstance.patch(`/admin/activity/${id}`, payload);
  },
  delete: async (id) => {
    return await AxiosInstance.delete(`/admin/activity/${id}`);
  },
  sendNotification: async (payload) => {
    return await AxiosInstance.post(`/admin/activity-notification`, payload);
  },
  getAll: async (params) => {
    return await AxiosInstance.get("/private/activity/", { params: params });
  },
  getAllForUsser: async (params) => {
    return await AxiosInstance.get("/private/activity-participants-list", { params: params });
  },
  getById: async (id) => {
    return await AxiosInstance.get(`/private/activity/${id}`);
  },
  getByIdForUsser: async (id) => {
    return await AxiosInstance.get(`/private/activity-participants-list/${id}`);
  },
  exportCSVByHost: async (params) => {
    return await AxiosInstance.get(`/host/activity/export`, { params: params });
  },
};
