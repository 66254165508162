const form = {
  formId: "new-user-form",
  formField: {
    mucViPham: {
      name: "mucViPham",
      label: "Mức vi phạm",
      type: "text",
      placeholder: "Vd: Nhắc nhở",
      errorMsg: "Mức vi phạm không được bỏ trống.",
    },
    tenCBVC: {
      name: "tenCBVC",
      label: "Cán bộ viên chức",
      type: "text",
      placeholder: "Vd: Nhắc nhở",
      errorMsg: "CBVC không được bỏ trống.",
    },
    ngayViPham: {
      name: "ngayViPham",
      label: "Ngày Vi Phạm",
      type: "text",
      placeholder: "Ngày vi phạm",
      errorMsg: "Ngày vi phạm không được bỏ trống.",
    },
    // ngayKetThuc: {
    //   name: "ngayKetThuc",
    //   label: "Ngày Kết Thúc",
    //   type: "text",
    //   placeholder: "",
    //   errorMsg: "Ngày kết thúc không được bỏ trống.",
    // },
    soLanViPham: {
      name: "soLanViPham",
      label: "Số Lần Vi Phạm",
      type: "number",
      placeholder: "0.1",
      errorMsg: "Số lần vi phạm không được bỏ trống.",
    },
    ghiChu: {
      name: "ghiChu",
      label: "Ghi Chú",
      type: "text",
      placeholder: "0.1",
      errorMsg: "Hệ số lần 1 không được bỏ trống.",
    },
  },
};

export default form;
