import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { ghiChu, lan1, lan2, lan3, lan4, mucViPham },
} = checkout;

const validations = [
  Yup.object().shape({
    [mucViPham.name]: Yup.string().required(mucViPham.errorMsg),
    [lan1.name]: Yup.string().required(lan1.errorMsg),
    [lan2.name]: Yup.string().required(lan2.errorMsg),
    [lan3.name]: Yup.string().required(lan3.errorMsg),
    [lan4.name]: Yup.string().required(lan4.errorMsg),
  }),
];

export default validations;
