// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { ErrorMessage, Field } from "formik";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import { useEffect, useRef, useState } from "react";
import { formatCurrency } from "utils";

function isNumber(value) {
  return /^\d+$/.test(value);
}

function FormFieldCurrency({ label, name, value, ...rest }) {
  const [val, setVal] = useState(value);
  const firstRef = useRef(true);

  useEffect(() => {
    // if (rest.autoFormat) {
    //   setVal((prev) => formatCurrency.format(value));
    // } else {
    const formattedValue = typeof value === "number" ? formatCurrency.format(value) : value;
    if (firstRef.current) setVal(formattedValue);
    else setVal(formattedValue.charAt(0) === "0" ? formattedValue.slice(1) : formattedValue);
    firstRef.current = false;
    // }
  }, [value]);

  const handleBlur = () => {
    if (!isNumber(value)) return;
    if (val === formatCurrency.format(value)) return;
    setVal((prev) => formatCurrency.format(prev));
  };

  const handleFocus = () => {
    setVal(value);
  };

  return (
    <SoftBox mb={1.5}>
      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SoftTypography>
      </SoftBox>
      <Field
        {...rest}
        name={name}
        as={SoftInput}
        type="text"
        value={val}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      <SoftBox mt={0.75}>
        <SoftTypography component="div" variant="caption" color="error">
          <ErrorMessage name={name} />
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for FormField
FormFieldCurrency.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default FormFieldCurrency;
