/* eslint-disable react/prop-types */
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import avatar from "assets/images/team-2.jpg";
// Data
import DataTable from "./data/index";
import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import ActionCell from "./components/ActionCell";
import { formatDate } from "utils";
// import SoftBadge from "components/SoftBadge";
// import dataTableData from "./data/dataTableData";
import { OfficialsService } from "services/officialsService";

function QuanLyCanBo() {
  const [dataTable, setDataTable] = useState({
    columns: [
      {
        Header: "Họ và Tên",
        accessor: "name",
        width: "10%",
        // sorted: true,
        Cell: ({ value: [name, data] }) => (
          <Box display="flex" gap={1} alignItems="center">
            <Box width={40} height={40}>
              <img
                style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: 12 }}
                src={data.image}
                alt={name}
              />
            </Box>
            <Box>{name}</Box>
          </Box>
        ),
      },
      { Header: "Mã cbvc", accessor: "ma-cbvc", sorted: false, align: "center" },
      { Header: "Bộ môn", accessor: "bo-mon", sorted: false },
      { Header: "Năm sinh", accessor: "nam-sinh", sorted: false, align: "center" },
      { Header: "Quê quán", accessor: "que-quan", sorted: false },
      { Header: "Số điện thoại", accessor: "sdt", sorted: false, align: "center" },
      { Header: "thao tác", accessor: "action", sorted: false, align: "center" },
      // {
      //   Header: "Mã cbvc",
      //   accessor: "ma-cbvc",
      //   width: "10%",
      //   Cell: ({ value: [text, time, gioDon] }) => (
      //     <Box display="flex" flexDirection="column" overflow="hidden">
      //       <Box
      //         style={{
      //           maxWidth: 200,
      //           textOverflow: "ellipsis",
      //           whiteSpace: "nowrap",
      //           overflow: "hidden",
      //         }}
      //       >
      //         {text}
      //       </Box>
      //       <Box>
      //         {time} | <strong>Giờ đón</strong>: {gioDon}
      //       </Box>
      //     </Box>
      //   ),
      // },
    ],
    rows: [],
  });

  const [query, setQuery] = useState({ sortBy: "createdAt", sortType: "desc" });
  const [paginationData, setPaginationData] = useState({ page: 1 });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const {
        data: { items, paginate },
      } = await OfficialsService.getPaginate({
        ...query,
        limit: paginationData.pageSize || 10,
        page: paginationData.page,
      });
      setPaginationData((prev) => ({ ...prev, count: paginate.count, size: paginate.size }));
      setDataTable((prev) => ({
        ...prev,
        rows: items.map((item) => ({
          id: item._id,
          name: [
            item.personalInformation.fullName || "--",
            { image: item?.personalInformation?.avatar || "/logo-dhbk.png", checked: true },
          ],
          "ma-cbvc": [item?.personalInformation?.officerId || "--"],
          "bo-mon": [item?.department?.name || "--"],
          "nam-sinh": [
            item?.personalInformation?.birthday
              ? formatDate(item?.personalInformation?.birthday)
              : "--",
          ],
          "que-quan": item?.personalInformation?.homeTown || "--",
          sdt: item?.personalInformation?.phoneNumber || "--",
          // "trang-thai": (
          //   <SoftBadge
          //     variant="contained"
          //     color={getColorStatus(item.status)}
          //     size="xs"
          //     badgeContent={item.status}
          //     container
          //   />
          // ),
          // "ghi-chu": item?.note || "--",
          action: <ActionCell item={item} setDataTable={setDataTable} />,
        })),
      }));
      setLoading(false);
    })();
  }, [query, paginationData.page]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <DataTable
            loading={loading}
            query={query}
            setQuery={setQuery}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            table={dataTable}
            canSearch
          />
          {Object.keys(dataTable.rows)?.length === 0 && !loading && (
            <SoftBox my={7}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                Không có dữ liệu
              </Grid>
            </SoftBox>
          )}
          {Object.keys(dataTable.rows)?.length <= 2 && <SoftBox my={5}></SoftBox>}
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

export default QuanLyCanBo;
