import checkout from "./form";

const {
  formField: {
    diemThuong,
    diemTru,
    ghiChu,
    gioToiDa,
    nhomHoatDong,
    loaiNhiemVu,
    maNhiemVu,
    ngayBatDau,
    ngayKetThuc,
    soLuongThamGia,
    tenNhiemVu,
    thoiGian,
  },
} = checkout;

const initialValues = {
  // [diemThuong.name]: "",
  // [diemTru.name]: "",
  // [ghiChu.name]: "",
  // [gioToiDa.name]: "",
  // [nhomHoatDong.name]: "",
  // [loaiNhiemVu.name]: "",
  // [maNhiemVu.name]: "",
  // [ngayBatDau.name]: "",
  // [ngayKetThuc.name]: "",
  // [nhomHoatDong.name]: "",
  // [soLuongThamGia.name]: "",
  [tenNhiemVu.name]: "",
  // [thoiGian.name]: "",
};

export default initialValues;
