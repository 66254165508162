/* eslint-disable react/prop-types */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// NewUser page components
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { ErrorMessage, Field } from "formik";
import FormField from "../FormField";
import SoftDatePicker from "components/SoftDatePicker";
import { formatDate } from "utils";
import avatar from "assets/images/cloud.png";

// Api service
import { DepartmentService } from "services/departmentService";

function TongTinCanhanVienChuc({ formData }) {
  const { formField, values, errors, touched, getData, onChangeValue, setFieldValue } = formData;
  const {
    hoTen,
    cccd,
    namSinh,
    queQuan,
    diaChiLienHe,
    email,
    sdt,
    boMon,
    hocVi,
    ngayBatDauLamViec,
    ngachVienChuc,
    viTri,
    gioiTinh,
    maCanBo,
  } = formField;
  const {
    hoTen: hoTenV,
    cccd: cccdV,
    namSinh: namSinhV,
    queQuan: queQuanV,
    diaChiLienHe: diaChiLienHeV,
    email: emailV,
    sdt: sdtV,
    boMon: boMonV,
    hocVi: hocViV,
    ngayBatDauLamViec: ngayBatDauLamViecV,
    ngachVienChuc: ngachVienChucV,
    viTri: viTriV,
    gioiTinh: gioiTinhV,
    maCanBo: maCanBoV,
  } = values;
  const { id } = useParams();
  const [files, setFiles] = useState({
    hinh: {},
    preview: avatar,
  });

  const [dob, setDob] = useState();
  const [jobStart, setJobStart] = useState();
  const [officialRankValue, setOfficialRankValue] = useState({});
  const [sexValue, setSexValue] = useState({});
  const [departmentValue, setDepartmentValue] = useState({});
  const [optionDepartmentValue, setOptionDepartmentValue] = useState([]);

  const handleSetDob = (newDate) => {
    const time = new Date(newDate[0]).getTime();
    setDob(newDate);
    setFieldValue(namSinh.name, time);
  };

  const handleSetJobStart = (newDate) => {
    const time = new Date(newDate[0]).getTime();
    setJobStart(newDate);
    setFieldValue(ngayBatDauLamViec.name, time);
  };

  const handleSetOfficialRankValue = (value) => {
    setOfficialRankValue({ value: value, label: value });
    setFieldValue(ngachVienChuc.name, value);
  };

  const handleSetSexValue = (value) => {
    setSexValue({ value: value, label: value });
    setFieldValue(gioiTinh.name, value);
  };

  const handleSetDepartmentValue = (value) => {
    setDepartmentValue({ value: value.value, label: value.label });
    setFieldValue(boMon.name, value.value);
  };

  const getAllDepartment = async () => {
    try {
      // const { data } = await DepartmentService.getData();
      // setOptionDepartmentValue(data);
    } catch (error) {
      console.log({ error });
    }
  };

  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");

  useEffect(() => {
    getAllDepartment();
  }, []);

  useEffect(() => {
    if (Object.keys(getData).length > 0) {
      setDob(new Date(Number(getData?.personalInformation?.birthday)));
      setJobStart(new Date(Number(getData?.personalInformation?.startTime)));
      setFieldValue(hoTen.name, getData?.personalInformation?.fullName || "--");
      setFieldValue(cccd.name, getData?.personalInformation?.citizenId || "--");
      setFieldValue(queQuan.name, getData?.personalInformation?.homeTown || "--");
      setFieldValue(sdt.name, getData?.personalInformation?.phoneNumber || "--");
      setFieldValue(email.name, getData?.personalInformation?.email || "--");
      setFieldValue(gioiTinh.name, getData?.personalInformation?.gender || "--");
      setFieldValue(viTri.name, getData?.personalInformation?.jobPosition || "--");
      setFieldValue(hocVi.name, getData?.personalInformation?.academicDegree || "--");
      setFieldValue(ngachVienChuc.name, getData?.personalInformation?.officialRank || "--");
      setFieldValue(diaChiLienHe.name, getData?.personalInformation?.address || "--");
      setFieldValue(maCanBo.name, getData?.personalInformation?.officerId || "--");
      setFieldValue(ngayBatDauLamViec.name, getData?.personalInformation?.startTime || "");
      setFieldValue(namSinh.name, getData?.personalInformation?.birthday || "");
      setFieldValue(boMon.name, getData?.department?.name || "--");
      setOfficialRankValue({
        value: getData?.personalInformation?.officialRank,
        label: getData?.personalInformation?.officialRank,
      });
      setSexValue({
        value: getData?.personalInformation?.gender,
        label: getData?.personalInformation?.gender,
      });
      setDepartmentValue({
        value: getData?.department?._id,
        label: getData?.department?.name,
      });
      getData?.personalInformation?.avatar && onChangeValue("hinh", getData?.avatar);
      getAllDepartment();
    }
  }, [getData]);

  const handleChangeFile = (event) => {
    const { name } = event.target;
    const file = event.target.files[0];
    Object.assign(file, { preview: URL.createObjectURL(file) });
    if (file && Object.keys(file).length > 0) {
      setFiles((prev) => ({ ...prev, [name]: file }));
      onChangeValue(name, file);
    }
  };

  // if (id && containsEdit && (!getData || Object.keys(getData).length === 0)) {
  //   return <>Loading...</>;
  // }

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          Chi tiết
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={hoTen.type}
              label={hoTen.label}
              name={hoTen.name}
              value={hoTenV}
              error={errors.hoTen && touched.hoTen}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={maCanBo.type}
              label={maCanBo.label}
              name={maCanBo.name}
              value={maCanBoV}
              error={errors.maCanBo && touched.maCanBo}
              disabled={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={cccd.type}
              label={cccd.label}
              name={cccd.name}
              value={cccdV}
              error={errors.cccd && touched.cccd}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {!containsEdit && !id ? (
              <FormField
                type={"text"}
                label={"Năm sinh"}
                name={"dob"}
                value={
                  getData?.personalInformation?.birthday
                    ? formatDate(Number(getData.personalInformation.birthday))
                    : "--"
                }
                disabled={true}
              />
            ) : (
              <SoftBox display="flex" flexDirection="column">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  mb={0.5}
                  mt={2.2}
                >
                  Năm Sinh
                </SoftTypography>
                <SoftDatePicker value={dob} onChange={(e) => handleSetDob(e)} />
                <SoftBox mt={0.75}>
                  <SoftTypography component="div" variant="caption" color="error">
                    <ErrorMessage name={namSinh.name} />
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={queQuan.type}
              label={queQuan.label}
              name={queQuan.name}
              value={queQuanV}
              error={errors.queQuan && touched.queQuan}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={sdt.type}
              label={sdt.label}
              name={sdt.name}
              value={sdtV}
              error={errors.sdt && touched.sdt}
              disabled={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              error={errors.email && touched.email}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox display="flex" flexDirection="column">
              {!id && !containsEdit ? (
                <FormField
                  type={gioiTinh.type}
                  label={gioiTinh.label}
                  name={gioiTinh.name}
                  value={gioiTinhV}
                  error={errors.gioiTinh && touched.gioiTinh}
                  disabled={true}
                />
              ) : (
                <SoftBox paddingTop={"3px"} display="block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    mb={0.5}
                    pl={0.8}
                  >
                    {gioiTinh.label}
                  </SoftTypography>
                  <SoftSelect
                    value={sexValue}
                    onChange={(e) => handleSetSexValue(e.value)}
                    options={[
                      { value: "Nam", label: "Nam" },
                      { value: "Nữ", label: "Nữ" },
                    ]}
                  />
                </SoftBox>
              )}
            </SoftBox>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {!containsEdit && !id ? (
              <FormField
                type={"text"}
                label={"Ngày Bắt Đầu Làm Việc"}
                name={"dob"}
                value={
                  getData?.personalInformation?.startTime
                    ? formatDate(Number(getData.personalInformation.startTime))
                    : "--"
                }
                disabled={true}
              />
            ) : (
              <SoftBox display="flex" flexDirection="column">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  mb={0.5}
                  mt={2.2}
                >
                  Ngày Bắt Đầu Làm Việc
                </SoftTypography>
                <SoftDatePicker value={jobStart} onChange={(e) => handleSetJobStart(e)} />
                <SoftBox mt={0.75}>
                  <SoftTypography component="div" variant="caption" color="error">
                    <ErrorMessage name={ngayBatDauLamViec.name} />
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox display="flex" flexDirection="column">
              {!id && !containsEdit ? (
                <FormField
                  type={boMon.type}
                  label={boMon.label}
                  name={boMon.name}
                  value={boMonV}
                  error={errors.boMon && touched.boMon}
                  disabled={true}
                />
              ) : (
                <SoftBox paddingTop={"3px"} display="block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    mb={0.5}
                    pl={0.8}
                  >
                    {boMon.label}
                  </SoftTypography>
                  <SoftSelect
                    value={departmentValue}
                    onChange={(e) => {
                      handleSetDepartmentValue(e);
                    }}
                    options={optionDepartmentValue?.map((e) => ({
                      value: e._id,
                      label: e.name,
                    }))}
                  />
                </SoftBox>
              )}
            </SoftBox>
            <SoftBox mt={0.75}>
              <SoftTypography component="div" variant="caption" color="error">
                <ErrorMessage name={boMon.name} />
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={viTri.type}
              label={viTri.label}
              name={viTri.name}
              value={viTriV}
              error={errors.viTri && touched.viTri}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={hocVi.type}
              label={hocVi.label}
              name={hocVi.name}
              value={hocViV}
              error={errors.hocVi && touched.hocVi}
              disabled={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SoftBox display="flex" flexDirection="column">
              {!id && !containsEdit ? (
                <FormField
                  type={ngachVienChuc.type}
                  label={ngachVienChuc.label}
                  name={ngachVienChuc.name}
                  value={ngachVienChucV}
                  error={errors.ngachVienChuc && touched.ngachVienChuc}
                  disabled={true}
                />
              ) : (
                <SoftBox paddingTop={"3px"} display="block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    mb={0.5}
                    pl={0.8}
                  >
                    {ngachVienChuc.label}
                  </SoftTypography>
                  <SoftSelect
                    onChange={(e) => handleSetOfficialRankValue(e.value)}
                    value={officialRankValue}
                    options={[
                      {
                        value: "Ngạch giảng viên",
                        label: "Ngạch giảng viên",
                      },
                      {
                        value: "Ngạch nghiên cứu viên, kỹ sư",
                        label: "Ngạch nghiên cứu viên, kỹ sư",
                      },
                      {
                        value: "Ngạch viên chức",
                        label: "Ngạch viên chức",
                      },
                      {
                        value: "Viên chức quản lý",
                        label: "Viên chức quản lý",
                      },
                    ]}
                  />
                </SoftBox>
              )}
              <SoftBox mt={0.75}>
                <SoftTypography component="div" variant="caption" color="error">
                  <ErrorMessage name={ngachVienChuc.name} />
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={diaChiLienHe.type}
              label={diaChiLienHe.label}
              name={diaChiLienHe.name}
              value={diaChiLienHeV}
              error={errors.diaChiLienHe && touched.diaChiLienHe}
              disabled={true}
            />
          </Grid>
        </Grid>

        <Grid spacing={3} container mt={0.5}>
          {/* {id && !containsEdit && !getData?.image ? null : ( */}
          <Grid item xs={12} sm={6} lg={3}>
            <Box>
              <h6 style={{ marginBottom: 6 }}>Hình</h6>
              <label htmlFor="hinh" className="file__upload">
                <input
                  type="file"
                  accept="image/*"
                  multiple={false}
                  style={{ display: "none" }}
                  name="hinh"
                  id="hinh"
                  // onChange={handleChangeFile}
                  disabled={true}
                />
                {files.hinh?.preview || getData?.personalInformation?.avatar || files?.preview ? (
                  <div>
                    <img
                      src={
                        files.hinh?.preview ||
                        getData?.personalInformation?.avatar ||
                        files?.preview
                      }
                      alt="preview"
                      className={`file__preview ${id && !containsEdit && "disabled"}`}
                      style={{ minHeight: 230 }}
                    />
                  </div>
                ) : (
                  <div className="file__inner">Chọn hình</div>
                )}
              </label>
            </Box>
          </Grid>
          {/* )} */}
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
TongTinCanhanVienChuc.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default TongTinCanhanVienChuc;
