import checkout from "./form";

const {
  formField: {
    canBoVC,
    diemChuyenMon,
    diemNV1,
    diemNV2,
    diemNV3,
    diemThuong,
    diemTru,
    diemQuanLy,
    ghiChu,
    ngayBatDau,
    ngayKetThuc,
    nam,
  },
} = checkout;

const initialValues = {
  [canBoVC.name]: "",
  [diemChuyenMon.name]: "",
  [diemNV1.name]: "",
  [diemNV2.name]: "",
  [diemNV3.name]: "",
  [diemThuong.name]: "",
  [diemTru.name]: "",
  [diemQuanLy.name]: "",
  [ngayBatDau.name]: "",
  [ngayKetThuc.name]: "",
  [nam.name]: "",
  [ghiChu.name]: "",
};

export default initialValues;
