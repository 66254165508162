const form = {
  formId: "new-user-form",
  formField: {
    maNhomHoatDong: {
      name: "maNhomHoatDong",
      label: "Mã Bộ Môn",
      type: "text",
      placeholder: "Vd: BM-A",
      errorMsg: "Mã Nhóm hoạt động không được bỏ trống.",
    },
    tenNhomHoatDong: {
      name: "tenNhomHoatDong",
      label: "Tên Nhóm Hoạt động",
      type: "text",
      placeholder: "Bộ Môn A",
      errorMsg: "Tên Nhóm hoạt động không được bỏ trống.",
    },
    loaiNhiemVu: {
      name: "loaiNhiemVu",
      label: "Loại Nhiệm vụ",
      type: "text",
      placeholder: "Vd: BM-A",
      errorMsg: "Loại nhiệm vụ không được bỏ trống.",
    },
    diemThuong: {
      name: "diemThuong",
      label: "Điểm thưởng xuất sắc tối đa",
      type: "number",
      placeholder: "Vd: BM-A",
      errorMsg: "Điểm thưởng xuất sắc tối đa không được bỏ trống.",
    },
    ghiChu: {
      name: "ghiChu",
      label: "Ghi Chú",
      type: "text",
      placeholder: "Nhiệm vụ dành cho...",
      errorMsg: "Ghi chú không được bỏ trống.",
    },
  },
};

export default form;
