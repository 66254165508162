import checkout from "./form";

const {
  formField: { maBoMon, tenBoMon },
} = checkout;

const initialValues = {
  [maBoMon.name]: "",
  [tenBoMon.name]: "",
};

export default initialValues;
