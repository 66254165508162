import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { tenCBVC, mucViPham, soLanViPham, ngayViPham, ngayKetThuc, ghiChu },
} = checkout;

const validations = [
  Yup.object().shape({
    [tenCBVC.name]: Yup.string().required(tenCBVC.errorMsg),
    [mucViPham.name]: Yup.string().required(mucViPham.errorMsg),
    // [soLanViPham.name]: Yup.string().required(soLanViPham.errorMsg),
    [ngayViPham.name]: Yup.string().required(ngayViPham.errorMsg),
    // [ngayKetThuc.name]: Yup.string().required(ngayKetThuc.errorMsg),
  }),
];

export default validations;
