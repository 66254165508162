import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    hoTen,
    cccd,
    namSinh,
    queQuan,
    diaChiLienHe,
    email,
    sdt,
    boMon,
    hocVi,
    ngayBatDauLamViec,
    ngachVienChuc,
    viTri,
    gioiTinh,
    maCanBo,
    avatar,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    // [hoTen.name]: Yup.string().required(hoTen.errorMsg),
    // [cccd.name]: Yup.string().required(cccd.errorMsg),
    // [namSinh.name]: Yup.string().required(namSinh.errorMsg),
    // [queQuan.name]: Yup.string().required(queQuan.errorMsg),
    // [diaChiLienHe.name]: Yup.string().required(diaChiLienHe.errorMsg),
    // [email.name]: Yup.string().email("sai định dạng Email").required(email.errorMsg),
    // [sdt.name]: Yup.string().required(sdt.errorMsg),
    // [boMon.name]: Yup.string().required(boMon.errorMsg),
    // [hocVi.name]: Yup.string().required(hocVi.errorMsg),
    // [ngayBatDauLamViec.name]: Yup.string().required(ngayBatDauLamViec.errorMsg),
    // [ngachVienChuc.name]: Yup.string().required(ngachVienChuc.errorMsg),
    // [viTri.name]: Yup.string().required(viTri.errorMsg),
    // [gioiTinh.name]: Yup.string().required(gioiTinh.errorMsg),
    // [maCanBo.name]: Yup.string().required(maCanBo.errorMsg),
    // [avatar.name]: Yup.string().notRequired(avatar.errorMsg),
  }),
];

export default validations;
